import React from 'react';
import TextField from '../../components/textField';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import {
  setMinQuantity,
  setMinPrice,
  setMaxPrice,
} from '../../store/Search/searchReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
const PriceQuantitySection  = (props: any) => {
  //const { navigate } = props;
  console.log(props);
  const { t } = useTranslation();
  const textFieldInputProps = {
    style: {
      height: '30px',
      backgroundColor: '#fff',
      padding: '4px 0px 4px 8px',
      // minWidth: '250px',
    },
  };
  const { filters } = useAppSelector(e => e.search);
  const { minQuantity, minPrice, maxPrice } = filters;
  const dispatch = useAppDispatch();
  const handleChange = (action: any) =>(event: any) => {
    dispatch(action({ values: event.target.value /*, navigate*/ }));
  };
  const piecesTranslation: any = (t('UnitsSingular', { returnObjects: true }));
  const name = 'parts';
  return <div>
            <TextField 
              size="small"
              id="minOrder"
              label={t('minimalQuantity')}
              type="number"
              variant="outlined"
              onChange={handleChange(setMinQuantity)}
              value={minQuantity}
              style={{ width:'100%', marginTop:'25px', backgroundColor: '#fff' }}
              inputProps={textFieldInputProps}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <div style={{ color:'#c122fa' }}>{piecesTranslation[name]}</div></InputAdornment>,
              }}
            />
            <TextField 
              size="small"
              id="minOrder"
              label={t('MinPrice')}
              type="number"
              variant="outlined"
              onChange={handleChange(setMinPrice)}
              value={minPrice}
              style={{ width:'100%', marginTop:'25px', backgroundColor: '#fff' }}
              inputProps={textFieldInputProps}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <div style={{ color:'#c122fa' }}>€</div></InputAdornment>,
              }}
            />
            <TextField 
              size="small"
              id="minOrder"
              label={t('MaxPrice')}
              type="number"
              variant="outlined"
              name="minOrder"
              onChange={handleChange(setMaxPrice)}
              value={maxPrice}
              style={{ width:'100%', marginTop:'25px', backgroundColor: '#fff' }}
              inputProps={textFieldInputProps}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <div style={{ color:'#c122fa' }}>€</div></InputAdornment>,
              }}
            />
    </div>;
};

export default PriceQuantitySection;