import React from 'react';
import InvoiceLine from './invoiceLine';
import { getInvoices } from '../../api/invoices';
import { useTranslation } from 'react-i18next';


const Invoices = () => {
  const [invoices, setInvoices] = React.useState<any>([]);
  const { t } = useTranslation();

  const fetchInvoices = async () => {
    const data:any = await getInvoices();
    const invoice = data && data.length > 0 && data.filter((invoicess: any) => invoicess.status === 'paid');
    setInvoices(invoice);
  };
  React.useEffect(() => {
    fetchInvoices();
  }, []);
  return <div style={{ padding: '0 6.6% 0 6.6%', marginTop: '30px' }}>
    <div style={{ marginBottom: '20px', textAlign: 'center', fontSize: '26px', color: '#21062E' }}>
      {t('Invoices')}
      </div>
    {
      !!invoices && invoices.length > 0 &&
      invoices.map((invoice: any) => <InvoiceLine data={invoice}/>)
    }
  </div>;
};
export default Invoices;