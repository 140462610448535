import React from 'react';
// import { Elements } from '@stripe/react-stripe-js';
// import CheckoutForm from './checkoutForm';
import {
//   CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';



function Payment(props: any) {
  const { setIsPaid } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  // const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const language = localStorage.getItem('i18nextLng');
  const lg: any = language ? language.substring(0, 2) : 'fr';
  elements?.update({ locale : lg });
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (stripe && elements) {
    //   const cardElement: any = elements.getElement(PaymentElement);
      stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: document.location.origin,
        },
        redirect: 'if_required',
      }).then(( { error }: any) => {
        setIsLoading(false);
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        } else {
          enqueueSnackbar(t('paimentsucceded'), { variant: 'success' });
          setIsPaid(true);
        }
      }).catch(() => {
        setIsLoading(false);
        enqueueSnackbar(t('PaymentErr'), { variant: 'error' });
      });
    }
  };
  return (
      <div style={{ marginTop: '50px' }}>
    <form onSubmit={onSubmit}>
      <PaymentElement />
      {/* <CardElement /> */}
      <Button
        disabled={isLoading}
        type="submit"
        style={{ marginTop: '20px' }} variant='contained'
        >
          {isLoading && <CircularProgress style={{ width: '20px',
            height: '20px',
            marginRight: '8px',
            color: '#fff',
          }}/>}
          Confirmer</Button>
    </form>
    </div>
  );
}
export default Payment;