import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.css';

const Pagination = (props: any) => {
  const { t } = useTranslation();
  const { pages, selectedPage, action } = props;
  const renderCell = (n: any) => <div
    className='pagination-cell'
    style={{ backgroundColor: n === selectedPage ?  '#CDE9E6' : '#fff' }}
    onClick={()=> action(n)}
    >
      {n}
      </div>;
  return <div className='pagination-container'>
        { selectedPage !== 1 && <div
          className='pagination-text'
          onClick={() => action(selectedPage - 1)}
        >
            {t('previous')}
            </div>
        }
    {Array
      .from({ length: pages }, (_, i) => i + 1)
      .map(renderCell)
    }
        { selectedPage !== pages && <div
          onClick={() => action(selectedPage + 1)}
          className='pagination-text'>
            {t('next')}
        </div>
        }
    </div>;
};

export default Pagination;