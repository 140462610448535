import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { TextField } from '@mui/material';
import VioletRectangle from '../../assets/Rectangleviolet.png';
import { useSnackbar } from 'notistack';
import { sendMessage } from '../../api/messaging';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SimpleDialog(props: any) {
  const { t } = useTranslation();
  const { onClose,
    open,
    offer,
    getOffer,
  } = props;
  const [messageValue, setMessageValue] = React.useState('');
  const handleClose = () => {
    onClose();
  };
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [conversationExist, setConversationExist] = React.useState(false);
  const rate = async () => {
    try {
      if (messageValue !== '') {
        const data:any = await sendMessage(messageValue, offer, null);
        console.log('data', data);
        if (data?.error === 'Conflict') {
          setConversationExist(data.conversationId);
        } else {
          enqueueSnackbar(t('MessageSent'), {
            variant: 'success',
          });
          handleClose();
          getOffer();
        }
      }

    } catch (err: any) {
      console.log('err', err);
      enqueueSnackbar(t('error'), {
        variant: 'error',
      });
      handleClose();
    }
  };

  return (
    <Dialog 
    sx={{ '& .MuiDialog-paper	':{ minWidth: '413px' } }} 
    onClose={handleClose} open={open}>
      {!conversationExist && <React.Fragment>
      <DialogTitle style={{ margin: 'auto',
        font:'normal normal medium 24px/24px Jost',
        fontWeight: 'normal',
        fontSize: '24px',
        marginBottom: '14px',
      }}>
          Envoyer un message au vendeur</DialogTitle>
      <div style={{ margin: 'auto', marginTop: '21px', marginBottom: '24px' }}>
      <TextField
          id="outlined-multiline-static"
          label="tapez votre message ici"
          multiline
          rows={4}
          style={{ width: '356px' }}
          value={messageValue}
          onChange={(e: any) => setMessageValue(e.target.value)}
        />
          </div>
          <div style={{ margin: 'auto', marginBottom: '22px' }}>
          <div onClick={rate} style={{ position: 'relative', top:'4px', cursor:'pointer' }}>
                  <img id="image" width="90px" height="30px" alt="Login"
                      src={VioletRectangle}/>
                <span style={{ fontSize:'14px', color: '#fff', position: 'absolute', width: '100%', top:'5px', left: '20px', whiteSpace: 'nowrap' }}>Envoyer</span>
                  </div>
              </div>
       </React.Fragment>
    }
    {conversationExist && <React.Fragment>
      <DialogTitle style={{ margin: 'auto',
        font:'normal normal medium 24px/24px Jost',
        fontWeight: 'normal',
        fontSize: '24px',
        marginBottom: '14px',
        textAlign: 'center',
      }}>
          Vous avez déjà une discussion au sujet de cette annonce</DialogTitle>
          <div style={{ margin: 'auto', marginBottom: '22px' }}>
          <div onClick={() => navigate('/messages/' + conversationExist)} style={{ position: 'relative', top:'4px', cursor:'pointer' }}>
                  <img id="image" width="237px" height="34px" alt="Login"
                      src={VioletRectangle}/>
                <span style={{ fontSize:'14px', color: '#fff', position: 'absolute', width: '100%', top:'5px', left: '41px', whiteSpace: 'nowrap' }}>Accéder à la conversation</span>
                  </div>
              </div>
       </React.Fragment>
    }

    </Dialog>
  );
}

export default SimpleDialog;