import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
// import StepContent from '@mui/material/StepContent';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
// import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    marginLeft: '4px',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
          '#c122fa',
      width:'10px',
      height: '30px',
      marginLeft:'18px',
      transform: 'scaley(1.1)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
          '#c122fa',
      width:'10px',
      height: '30px',
      marginLeft:'18px',
      transform: 'scaley(1.1)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    border: 0,
    width:'10px',
    height: '30px',
    marginLeft:'18px',
    transform: 'scaley(1.04)',
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#C0DFE1',
    borderRadius: 1,
  },
}));


export default function VerticalLinearStepper(props: any) {
  const { steps, activeStep } = props;

  return (
    <Box sx={{ maxWidth: 400, marginTop: '93px' }}>
      <Stepper connector={<ColorlibConnector />} activeStep={activeStep} orientation="vertical">
        {steps.map((step: any, index: number) => (
          <Step sx={{ '& .MuiStepIcon-root': {
            width: '70px',
            height:'70px',
            color: index > activeStep ? '#C0DFE1' : '#c122fa',
          } }} key={index}>
            <StepLabel
              style={{
                padding: '0px 8px 0px 0px',
              }}
            >
              <span style={{
                color: index <= activeStep ? '#c122fa' : '#C0DFE1',
                fontSize: '14px',
                fontWeight: 'bold',
              }}>{step}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
