import React from 'react';
import DropZone from 'react-dropzone';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import DraggableImage from './draggableImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
export default function MyDropzone(pros: any) {
  const { enqueueSnackbar } = useSnackbar();
  const { setImagesList, imagesList, error } = pros;
  const { t } = useTranslation();
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [imgSrcs, setImgSrcs] = React.useState<Array<any>>([]);
  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0 || imgSrcs.length === 7) {
      return;
    }
    setFiles([...files, ...acceptedFiles]);
    setImagesList([...imagesList, ...acceptedFiles]);
    acceptedFiles.map( (file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        setImgSrcs([...imgSrcs, reader.result]);
      };
    });
  };
  const onDropRejected = (e: any) => {
    console.log('e', e);
    let isDifType = false;
    e.forEach((element: any) => {
      element.errors.forEach((err: any) => {
        if (err.code === 'file-invalid-type') {
          isDifType = true;
        }
      });
    });
    if (!isDifType) {
      enqueueSnackbar(t('maxPicSizeExceeded'), { variant: 'error' });
    }
  };
  const onDelete = () => {
    setFiles([]);
    setImagesList([]);
    setImgSrcs([]);
  };
  const deletePic = (index: number) => {
    setFiles(files.filter( (e, i) => i !== index ));
    setImagesList(imagesList.filter( (e : any, i: any) => i !== index ));
    setImgSrcs(imgSrcs.filter( (e, i) => i !== index ));
  };
  const moveCard = (dragIndex: any, hoverIndex: any) => {
    const dragCard = imgSrcs[dragIndex];
    let newSrcs = [...imgSrcs];
    newSrcs.splice(dragIndex, 1);
    newSrcs.splice(hoverIndex, 0, dragCard);
    const dragFile = imagesList[dragIndex];
    let newImagesList = [...imagesList];
    newImagesList.splice(dragIndex, 1);
    newImagesList.splice(hoverIndex, 0, dragFile);
    setImagesList(newImagesList);
    setImgSrcs(newSrcs);
  };
  return (
<DropZone
  onDrop={onDrop}
  onDropRejected={onDropRejected}
  accept={['image/gif', 'image/jpeg', 'image/png']}
  maxFiles={7}
  maxSize={3145728}
  >
  {(props: any) => {
    const { getRootProps, getInputProps } = props;
    return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box sx={{
          height: '70px',
          border: error ? '1px solid #d32f2f' : '1px solid #C6C6C6',
          cursor: 'pointer',
          backgroundColor: '#BF28FA',
          color: '#fff',
        }}>
          <div style={{ padding: '0px 80px 0px 80px',
            color: '#fff',
            textAlign: 'center',
            fontSize: '14px',
            paddingTop: '8px',
          }}
          dangerouslySetInnerHTML={{ __html: t('AddPhotoText') }}
          >
            </div>
        </Box>
      </div>
      <div style={{ color: '#BF28FA', fontSize: '12px', paddingTop:'15px', display:'flex' }}>
        <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', top: '4px', marginRight:'8px'  }} color='#BF28FA' icon={faInfoCircle} />
       {`${t('AcceptedImageFormats')} ${t('firstImage')}`}
        </div>
      <div style={{ display: 'flex', marginTop:'20px' }}>
      {imgSrcs.length > 0 && imgSrcs.map((e: any, index: any ) => 
      <div style={{
        position: 'relative',
        textAlign: 'center',
        color: 'white',
        cursor: 'move',
      }}
      >
        <div style={{
          position: 'absolute',
          top: '45%',
          left: '55%',
          transform: 'translate(-50%, -50%)',
        }}>
          {index + 1}
          </div>
      <DraggableImage
        src={e}
        id={`dragimg-index${index}`}
        index={index}
        moveCard={moveCard}
        deleteImage={deletePic}
        />
      </div>,
      )}
        {
          imgSrcs.length > 0 &&
          <div 
          onClick={onDelete}
          style={{ 
            marginLeft: '9px',
            width: '40px',
            height: '40px',
            backgroundColor: '#fff',
            border: 'rgba(198, 198, 198, 1) 1px solid',
          }}>
          <FontAwesomeIcon
          style={{
            margin: 'auto',
            top: '10px',
            position: 'relative',
            paddingLeft: '13px',
          }}
          icon={faTrash}
          color='rgba(248, 36, 120, 1)'
          />
            </div>
        }
      </div>
    </section>
    );
  }
  
}
</DropZone>
  );
}
