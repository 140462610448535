import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// interface PicturesProps {
//   pictures: string[];
// }

export default function (props: any) {
  const ref = React.useRef<HTMLDivElement>(null);
  const scrollRight = () => {
    if (ref && ref.current) {
      ref.current.scrollLeft += 810;
    }
  };
  const scrollLeft = () => {
    if (ref && ref.current) {
      ref.current.scrollLeft -= 810;
    }
  };

  const { pictures } = props;
  const [selectedPhoto, setSelectedPhoto] = React.useState(0);
  const handleImageClick = (index: number) => {
    setSelectedPhoto(index);
  };
  return <div>
    <div className='selected-image-container'>
    <img className='selected-image' src={pictures[selectedPhoto]} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <FontAwesomeIcon onClick={scrollLeft} style={{ position: 'relative', marginRight:'8px', cursor: 'pointer', top: '12px'  }} color='#000' icon={faChevronLeft} />
    <div ref={ref} className='miniatures-container'>
      { pictures.map((image:string, index: number) => {
        return (
        <img src={image} height='40px' width='40px' className='miniature' onClick={() => handleImageClick(index)}/>
        );
      })}
      </div>
    <FontAwesomeIcon onClick={scrollRight} style={{ position: 'relative', marginRight:'8px', cursor: 'pointer', top: '12px'   }} color='#000' icon={faChevronRight} />

      </div>
      </div>;
}

