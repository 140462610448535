import React from 'react';
import './index.css';
import { useAppSelector } from '../../../store/hooks';
import { fetchSellerOtherOffers } from '../../../api/product';
import ProductCard from '../../../components/productCard';
import { CircularProgress } from '@mui/material';

const ShopCustomization = (props: any) => {
  const { setFieldValue, values } = props;
  const { _id: userId } = useAppSelector(state => state.account);
  const [offers, setOffers] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    const fetchOffers = async (id: any) => {
      setIsLoading(true);
      const { data } = await fetchSellerOtherOffers(id);
      setOffers(data);
      setIsLoading(false);
    };
    if (userId) {
      fetchOffers(userId);
    }
  }, [userId]);
  const onToggle = (offerId: string) => {
    const indx = values.offers.indexOf(offerId);
    if (indx > -1) {
      setFieldValue('offers',
        values.offers.filter((e: any, i: number) => i !== indx),
      );
    } else {
      setFieldValue('offers', [...values.offers, offerId]);
    }
  };
  console.log('valuesss', values.offers);
  return <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {!isLoading && offers && offers.length > 0 && offers.map( (e: any) => <ProductCard
      favorite={true}
      data={e}
      style={{
        marginRight: '20px',
      }}
      hasEye
      isEyeCrossed={values.offers.indexOf(e._id) < 0}
      canEdit
      noBorder
      onToggle={onToggle}
      />)}
      {
        isLoading && <CircularProgress />
      }
      </div>;
};

export default ShopCustomization;