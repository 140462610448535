import React from 'react';
import AccordionElement from './AccordionElement';
import { useTranslation } from 'react-i18next';
import './index.css';
import { Grid } from '@mui/material';
import OffersList from './OffersList';
import MyName from './MyName';
import MyIdentity from './MyIdentity';
import MyBanner from './MyBanner';
import MyContact from './MyContact';
import Reviews from './Reviews';
import MyCatalogs from './MyCatalogs';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { faEye, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createShop } from '../../../api/shop';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';

const ShopCustomization = (props: any) => {
  const { setIsPosting } = props;
  const navigate = useNavigate();
  const { businessName, firstName, lastName, mobile, phone, country } = useAppSelector(state => state.account);
  const { enqueueSnackbar } = useSnackbar();
  const [isCreated, setIsCreated] = React.useState(false);
  const [isBannerChecked, setIsBannerChecked] = React.useState(false);
  const [isCatalogChecked, setIsCatalogChecked] = React.useState(false);
  const [isContactChecked, setIsContactChecked] = React.useState(false);
  const [isEvaluationsChecked, setIsEvaluationsChecked] = React.useState(false);
  const [color, setColor] = React.useState('#C122FA26');
  const { t } = useTranslation();
  const myRef:any = React.useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView({
    behavior: 'smooth', block: 'center', inline: 'nearest',
  });

  return <React.Fragment>{firstName && <Formik
  enableReinitialize
  initialValues={{
    imagesList: [],
    offers: [],
    mobile,
    phone,
    companyName: businessName,
    refContact: `${firstName || ''} ${lastName || ''}`,
    languages: [country],
    domain: '',
  }}
  validationSchema={Yup.object().shape({
    // imagesList: Yup.array().min(1),
    domain: Yup
      .string()
      .required(t('mandatoryRow'))
      .matches(/^[a-z0-9]+$/, t('onlyCharsAndNumbers')),
  })}
  onSubmit={async (values) => {
    const v = {
      ...values,
      catalogMandatory: isCatalogChecked,
      companyInformation: isContactChecked,
      evaluations: isEvaluationsChecked,
      color: color,
      contact: true,
      recommendedProdcuts: true,
      promotionalProducts: true,

    //   hasBanner: isBannerChecked,
    };
    try {
      setIsPosting(true);
      await createShop(v);
      enqueueSnackbar(t('shopCreated'), { variant: 'success' });
      setIsCreated(true);
      setIsPosting(false);
    } catch (e: any) {
      setIsPosting(false);
      enqueueSnackbar(e?.response?.data?.message, { variant: 'error' });
    }
  }}
>
  {({
    handleSubmit,
    // setFieldValue,
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
  }) => {
    const sectionProps = {
      color: color,
      handleChange: handleChange,
      setFieldValue: setFieldValue,
      values: values,
    };
    return (
        <form onSubmit={handleSubmit}>
  <Grid container style={{ marginTop: '25px' }}>
      <Grid item md={2} xs={1} />
  <Grid item md={8} xs={10}>
    <AccordionElement color={color} active={true} canBeExpanded={true} title={t('Annonce')}>
        <OffersList isDefaultClosed {...sectionProps} />
    </AccordionElement>
    <AccordionElement color={color} active={true} canBeExpanded={true} title={t('MyName')}>
        <MyName
        myRef={myRef}
        errors={errors}
        touched={touched}
        {...sectionProps} />
    </AccordionElement>
    <AccordionElement  color={color} active={true} canBeExpanded={true} title={t('MyIdentity')}>
        <MyIdentity {...sectionProps} setColorAcc={setColor}/>
    </AccordionElement>
    <AccordionElement  color={color} checked={isBannerChecked} setChecked={setIsBannerChecked} canBeHidden active={true} canBeExpanded={true} title={t('MyBanner')}>
        <MyBanner {...sectionProps}  />
    </AccordionElement>
    <AccordionElement  color={color} checked={isContactChecked} setChecked={setIsContactChecked} active={true} canBeExpanded={true} title={t('MyContact')}>
        <MyContact {...sectionProps}  />
    </AccordionElement>
    <AccordionElement  color={color} checked={isCatalogChecked} setChecked={setIsCatalogChecked} canBeHidden active={true} canBeExpanded={true} title={t('MyCatalogs')}>
        <MyCatalogs {...sectionProps} />
    </AccordionElement>
    <AccordionElement  color={color} checked={isEvaluationsChecked} setChecked={setIsEvaluationsChecked} canBeHidden active={true} canBeExpanded={true} title={t('Evaluations')}>
        <Reviews color={color} />
    </AccordionElement>
    </Grid>
    <Grid item md={2} xs={1} />
    </Grid>;
    <div style={{
      position: 'fixed',
      right: '60px',
      top: '60%',
    }}>
      <div className='validation-button-shop' style={{
        backgroundColor: isCreated ? 'rgba(94, 213, 200, 1.37)' : '#5ED5C85E',
        cursor: 'pointer',
        marginBottom: '30px',
      }}
      onClick={() => { 
        if (isCreated) {
          navigate(`/store/${values.domain}`);
        }
      }
    }
      >
          <FontAwesomeIcon
          color='#fff'
          icon={faEye}
          style={{
            width: '30px',
            height: '20px',
            marginRight: '8px',
          }}
          />
          {t('Visualize')}
          </div>
          <div className='validation-button-shop'
          onClick={() => {
            if (errors && errors.domain) {
              executeScroll();
            }
            handleSubmit();
          }
        }
          style={{
            backgroundColor: '#C122FA',
            cursor: 'pointer',
          }}>
          <FontAwesomeIcon
            color='#fff'
            icon={faCheck}
            style={{
              width: '30px',
              height: '20px',
              marginRight: '8px',
            }}
            />
          {t('Submit')}
          </div>
    </div>
    </form>
    );
  }}
    </Formik>
  }
    </React.Fragment>
  ;
};

export default ShopCustomization;