import React, { useRef } from 'react';
import './index.css';
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faBoxOpen, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { TextField } from '@mui/material';
import MessageTitle from './MessageTitle';
import MessageHeader from './MessageHeader';
import ConversationContainer from './ConversationContainer';
import { useLocation } from 'react-router';
import { getAllConversations, getOneConversations } from '../../api/messaging';
// import { fetchProduct } from '../../api/product';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import { updateConversationStatus } from '../../api/messaging';
import LoadingDialog from '../../components/loadingDialog';

const Inbox = () => {
  const { t } = useTranslation();
  const [selectedMessage, setSelectedMessage] = React.useState<any>(null);
  const [ selected, setSelected] = React.useState<any>([]);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [conversationsList, setConversationsList] = React.useState<any>([]);
  // const [offerdata, setOffer] = React.useState<any>({});
  const fdata = async (status: any, answered: any) => {
    setConversationsList([]);
    const data = await getAllConversations(status, answered);
    setConversationsList(data);
  };
  React.useEffect(() => {
    fdata('active', null);
  }, []);
  const [conversationData, setConversation] = React.useState<any>({});
  const navigate = useNavigate();

  React.useEffect(() => {
    if (pathname && conversationsList && conversationsList.length > 0) {
      const pathsArray = pathname.split('/');
      console.log('pathsArray', pathsArray);
      const convId = pathsArray[pathsArray.length - 1];
      if (convId !== 'messages') {
        setSelectedMessage(convId);
        const fetchConv = async () => {
          setIsLoading(true);
          const data = await getOneConversations(convId);
          setIsLoading(false);
          setConversation(data);
        };
        fetchConv();
      }
      if ((convId === 'messages' || convId === 'messages/') && conversationsList && conversationsList.length > 0) {
        navigate('/messages/' + conversationsList[0]._id);
      }
    }
  }, [pathname, conversationsList]);

  React.useEffect(() => {
    if (selectedMessage) {
      navigate(`/messages/${selectedMessage}`);
    }
    return () => setSelected([]);
  }, [selectedMessage]);
  const accountData = useAppSelector((state) => state.account);

  const headerRef: any = useRef();
  const [ headerHeight, setHeaderHeight ] = React.useState('100px');
  const [leftSectionHeight, setLeftSectionHeight] = React.useState('100px');
  React.useEffect(() => {
    if (headerRef && headerRef.current) {
      const { bottom } = headerRef.current.getBoundingClientRect();
      setHeaderHeight(bottom);
    }
  }, [headerRef]);
  React.useEffect(() => {
    const windowHeight = window.innerHeight;
    const lsHeight: any = windowHeight - parseInt(headerHeight) - 100;
    setLeftSectionHeight(lsHeight);
  }, [headerHeight]);
  console.log('leftSectionHeight', leftSectionHeight);
  React.useEffect(() => {
    const body = window.document.getElementsByTagName('body');
    body[0].style.overflow = 'hidden';
    return () => { 
      const b:any = window.document.getElementsByTagName('body');
      b[0].style.overflow = 'visible';
    };                                                                                                                                                                                                                      
  }, []);
  const [selectedStatus, setSelectedStatus] = React.useState('All');
  const selectedConversation = selectedMessage !== '' && conversationsList.find((e: any) => e._id === selectedMessage);
  const selectAll = () => {
    if (conversationsList && conversationsList.length > 0 && conversationsList.length === selected.length) {
      setSelected([]);
    } else if (conversationsList && conversationsList.length > 0) {
      setSelected(conversationsList.map((cnv: any) => cnv._id));
    }
  };
  const [isLoadingGlobal, setIsLoadingGlobal] = React.useState(false);
  const updateStatusMultiple = async (status: string) => {
    if (selected.length > 0) {
      try {
        setIsLoadingGlobal(true);
        await updateConversationStatus(selected, status);
        const fnPerStatus: any = {
          All: async () => {await fdata('active', null);},
          AwaitingReply: async () => {await fdata(null, false);},
          Replied: async () => {await fdata(null, true);},
          Archived: async () => {await fdata(null, 'archived');},
          Trash: async () => {await fdata(null, 'deleted');},
        };
        await fnPerStatus[selectedStatus]();
        setIsLoadingGlobal(false);
      } catch (error: any) {
        setIsLoadingGlobal(false);
      }
    }
  };
  return <div id='messages-container'>
          <div style={{ position: 'absolute', top:'40px', left: '25%', color: '#C122FA', fontSize: '14px', fontStyle: 'bold', cursor: 'pointer' }}
          onClick={() => {
            if (accountData && accountData.role === 'seller') {
              navigate('/dashboard/dashboard');
            } else {
              navigate('/dashboard/mydata');
            }
          }
      }>
            <FontAwesomeIcon
              icon={faArrowLeft}
              style={{
                height: '16px',
                width: '20px',
                transform: 'translatey(1px)',
                marginRight: '4px',
              }}
              color='#C122FA'
              />
              <span>
            {t('BackToDashboard')}
            </span>
            </div>
        <Header
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          fetchData={fdata}
          setSelectedMessage={setSelectedMessage}/>
        <div id='messages-list'>
        <div id='messages-left-section'>
            <div id='messages-left-section-header'>
                <div
                onClick={selectAll}
                className='messages-left-section-header-button'>
                {t('SelectAll')}

                </div>
                <div
                onClick={() => updateStatusMultiple('archived')}
                className='messages-left-section-header-button'>
                <FontAwesomeIcon
                    icon={faBoxOpen}
                    color='#545C84'
                    style={{
                      height: '16px',
                      width: '20px',
                    }}
                    />
                </div>
                <div
                onClick={() => updateStatusMultiple('deleted')}
                className='messages-left-section-header-button'>
                <FontAwesomeIcon
                    icon={faTrash}
                    color='#545C84'
                    style={{
                      height: '16px',
                      width: '20px',
                    }}
                  />
                </div>
                </div>
                <div 
                style={{ 
                  maxHeight: leftSectionHeight,
                  height: leftSectionHeight,
                  marginTop: '10px',
                  paddingRight: '14px',
                  paddingLeft: '10px',
                }}
                className='left-section-mss'>
                    {conversationsList.length > 0 && conversationsList.map((conversation: any, index: number) => <MessageTitle
                    selected={selected.indexOf(conversation._id) > -1}
                    isSelected={selectedMessage === conversation._id}
                    {...conversation}
                    index={index}
                    setSelected={setSelected}
                    id={conversation._id}
                    selectedList={selected}
                    setSelectedMessage={() => {
                      setSelectedMessage(conversation._id);
                      navigate(`/messages/${conversation._id}`);
                    }}
                    />)}
                    </div>
        </div>
        <div style={{ width: '3%' }}>
        </div>
        { selectedMessage && <div id='messages-right-section'>
            <div ref={headerRef}>
            {!!selectedConversation && <MessageHeader selectedStatus={selectedStatus} fetchData={fdata} {...selectedConversation} />}
            </div>
            {!!selectedConversation && conversationData && conversationData.length > 0 &&
            <ConversationContainer
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            selectedConversation={selectedMessage}
            conversationData={conversationData}
            selectedStatus={selectedStatus}
            headerHeight={headerHeight}/>
            }
            </div>
        }
            </div>
            {isLoadingGlobal && <LoadingDialog open={isLoadingGlobal} />}
        </div>;
};

export default Inbox;