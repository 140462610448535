import React from 'react';
import './index.css';
import MessageLine from './MessageLine';
import { useTranslation } from 'react-i18next';

const MessagesContainer = (props: any) => {
  const { data } = props;
  const { t } = useTranslation();
  return <div className='messages-table'>
      <div className='messages-table-title'>
          Messages
          </div>
      <div className='messages-table-head'>
          <div style={{ width: '23%', paddingTop:'8px', color:'#fff', fontSize: '16px', paddingLeft: '20px' }}>
              {t('Sender')}
            </div>
          <div style={{ width: '52%', paddingTop:'8px', color:'#fff', fontSize: '16px', paddingLeft: '20px' }}>
              {t('Content')}
            </div>
          <div style={{ width: '12%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center' }}>
          {t('Date')}
            </div>
          <div style={{ width: '12%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('Actions')}
            </div>

          </div>
          {data.map((e: any) => <MessageLine {...e} />)}
    </div>;
};

export default MessagesContainer;