import axios from 'axios';

export const postNote = async (note: number, comment: string, seller: string, offer:string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/notes`,
    {
      note,
      seller,
      offer,
      comment,
    },
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return res;
};

export const getUserNote = async (userId: string, offerId:string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/notes?userId=${userId}&offerId=${offerId}`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return res;
};

export const updateNote = async (note: number, comment: string, seller: string, offer:string, noteId: string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/notes?id=${noteId}`,
    {
      note,
      seller,
      offer,
      comment,
    },
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return res;
};

