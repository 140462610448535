import React from 'react';

const IconBuy = (props: any) => {
  const { color } = props;
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="41.707" height="50.396" viewBox="0 0 41.707 50.396">
  <g id="icon_new_announce" transform="translate(4.853 9.698)">
    <path id="Tracé_64" data-name="Tracé 64" d="M19.476-1.009V.729H17.738A1.738,1.738,0,0,1,16-1.009V-4.484h1.738v3.475Z" fill={color}/>
    <path id="Tracé_65" data-name="Tracé 65" d="M17.738-1.009V.729h1.738a1.738,1.738,0,0,0,1.737-1.738V-4.484H19.476v3.475Z" fill={color}/>
    <path id="Tracé_66" data-name="Tracé 66" d="M19.476-7.96V-9.7H17.738A1.738,1.738,0,0,0,16-7.96v1.738h1.738V-7.96Z" fill={color}/>
    <path id="Tracé_67" data-name="Tracé 67" d="M17.738-7.96V-9.7h1.738A1.738,1.738,0,0,1,21.213-7.96v3.476H19.476V-7.96Z" fill={color}/>
    <rect id="Rectangle_4" data-name="Rectangle 4" width="33.018" height="1.738" transform="translate(-4.853 33.747)" fill={color}/>
    <rect id="Rectangle_5" data-name="Rectangle 5" width="31.28" height="1.738" transform="translate(0.36 38.96)" fill={color}/>
    <rect id="Rectangle_6" data-name="Rectangle 6" width="1.738" height="36.493" transform="translate(35.116 -4.484)" fill={color}/>
    <rect id="Rectangle_7" data-name="Rectangle 7" width="1.738" height="38.231" transform="translate(0.36 -4.484)" fill={color}/>
    <path id="Tracé_68" data-name="Tracé 68" d="M36.853,32.009H35.116v3.476A3.475,3.475,0,0,1,31.64,38.96h0V40.7h0a5.213,5.213,0,0,0,5.213-5.213Z" fill={color}/>
    <path id="Tracé_69" data-name="Tracé 69" d="M26.427,33.747h1.737v1.738A3.476,3.476,0,0,0,31.64,38.96h0V40.7h0a5.213,5.213,0,0,1-5.213-5.213Z" fill={color}/>
    <path id="Tracé_70" data-name="Tracé 70" d="M-4.853,33.747h1.737v1.738A3.475,3.475,0,0,0,.36,38.96H2.1V40.7H.36a5.213,5.213,0,0,1-5.213-5.213Z" fill={color}/>
    <rect id="Rectangle_8" data-name="Rectangle 8" width="29.542" height="1.738" transform="translate(3.836 -7.96)" fill={color}/>
    <path id="Tracé_71" data-name="Tracé 71" d="M31.64-7.96v1.738h1.738a1.738,1.738,0,0,1,1.738,1.738v1.737h1.737V-4.484A3.475,3.475,0,0,0,33.378-7.96Z" fill={color}/>
    <path id="Tracé_72" data-name="Tracé 72" d="M5.573-7.96v1.738H3.836A1.738,1.738,0,0,0,2.1-4.484v1.737H.36V-4.484A3.476,3.476,0,0,1,3.836-7.96Z" fill={color}/>
  </g>
</svg>
  );
};
IconBuy.defaultProps = {
  color: '#fff',
};
export default IconBuy;