import axios from 'axios';

export const createShop = async (data: any) => {
  const accessToken = localStorage.getItem('access_token');

  const formData = new FormData();
  Object.entries(data).map((e: any) => {
    const key = e[0];
    const value = e[1];
    if (!Array.isArray(value)) {
      formData.append(key, value);
    } else {
      value.map( (pic:any) => {
        formData.append(`${key}`, pic);
      });
    }
  });
  const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stores`, formData, { headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } },
  );
  return res;
};
export const updateShop = async (data: any, id: any) => {
  const accessToken = localStorage.getItem('access_token');
  console.log('data', data);
  const formData = new FormData();
  Object.entries(data).map((e: any) => {
    const key = e[0];
    const value = e[1];
    if (!Array.isArray(value)) {
      formData.append(key, value);
    } else {
      value.map( (pic:any) => {
        formData.append(`${key}`, pic);
      });
    }
  });
  const res = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/stores/${id}`, formData, { headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } },
  );
  return res;
};


export const getStore = async (name: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stores/${name}`);

  return data;
};

export const getStoreByToken = async () => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stores`, { headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } });

  return data;
};

export const getStoreNotes = async (sellerId: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sellers/${sellerId}/notes/analytics`);
  return data;
};
export const getStoreReviews = async (sellerId: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/public-notes?seller=${sellerId}`);
  return data;
};