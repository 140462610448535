import * as React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import CreateAccountImg from '../../../assets/createAccount.svg';
import BuyerIcon from '../../../assets/icons/icon-buyer.png';
import SellerIcon from '../../../assets/icons/icon_seller.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
// import { updateAccount } from '../../../api/account';
// import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export default function AccountForm(props: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const { accountId, close } = props;
  const update = async (role: string) => {
    try {
      console.log('role', role, accountId);
      // await updateAccount(role, accountId);
      navigate('/subscription');
      // enqueueSnackbar('Profile updated', {
      //   variant: 'success',
      // });
      close();
    } catch (err) {
      console.error(err);
      // enqueueSnackbar('error', {
      //   variant: 'error',
      // });
      close();
    }
  };
  return (
      <div style={{ backgroundColor: '#F5F9F8', border:'1px #e9efef solid', textAlign: 'center' }}>
        <div >
          <Typography style={{ padding:'16px 0 16px 0', fontSize:'26px', color: '#22062E' }} variant='h6'>
            {t('Congrats')}
            </Typography>
          </div>
          <div >
          <Typography style={{
            padding: '16px 0px',
            fontSize: '18px',
            color: 'rgb(145, 153, 193)',
            width: '45%',
            textAlign: 'center',
            margin: 'auto',
          }} variant='h6'>
            {t('CloserToOpportunities')}
            </Typography>
          </div>
          <div style={{ width:'90%', display: 'flex', justifyContent: 'space-between', margin: 'auto' }}>
            <Box
              onClick={() => update('buyer')}
              style={{
                width: '47%',
                height:'100px',
                backgroundColor: '#5ED5C8',
                display: 'flex',
                cursor: 'pointer',
              }}>
            <div style={{ width: '30%' }}>
                <img src={BuyerIcon}
                style={{
                  top: '15%',
                  position: 'relative',
                  height: '71%',
                }}
                />
            </div>
            <div style={{ width: '67%' }}>
                <div>
              <Typography style={{  fontSize:'22px', color: '#fff' }} variant='h6'>
                {t('buyer')}
              </Typography>
              </div>
              <div>
              <Typography style={{  fontSize:'16px', color: '#fff' }} variant='h6'>
                {t('UnlockContactDetails')}
              </Typography>
              </div>
            </div>
            <div style={{ width: '10%' }}>
            <FontAwesomeIcon style={{ position: 'relative', height: '20px', width: '20px', top: '38%', marginRight:'8px'  }} color='#fff' icon={faChevronRight} />
            </div>
            </Box>
            <Box
              onClick={() => update('seller')}
              style={{
                width: '47%',
                height:'100px',
                backgroundColor: '#F92077',
                display: 'flex',
                cursor: 'pointer',
              }}>
            <div style={{ width: '30%' }}>
                <img src={SellerIcon}
                style={{
                  top: '15%',
                  position: 'relative',
                  height: '71%',
                }}
                />
                </div>
            <div style={{ width: '67%' }}>
                <div>
              <Typography style={{ color: '#fff', fontSize:'22px' }} variant='h6'>
                {t('seller')}
              </Typography>
              </div>
              <div>
              <Typography style={{ color: '#fff', fontSize:'16px', paddingLeft: '16px' }} variant='h6'>
                {t('PublishAds')}
              </Typography>
              </div>
            </div>
            <div style={{ width: '10%' }}>
               <FontAwesomeIcon style={{ position: 'relative', height: '20px', width: '20px', top: '38%', marginRight:'8px'  }} color='#fff' icon={faChevronRight} />
            </div>
            </Box>
              </div>
              <div style={{
                position: 'relative',
                margin: '40px auto 40px auto',
              }}>
                  <img style={{ cursor: 'pointer' }} id="image" height="50px" alt="Login"
                      src={CreateAccountImg}
                      onClick={() => close()}
                      />
                <span
                  onClick={() => close()}
                  style={{ zIndex: 15, fontSize:'16px', color: 'black', position: 'absolute', width: '100%', top:'12px', left: '0%', whiteSpace: 'nowrap', cursor: 'pointer' }}>{t('BackToHomePage')}</span>
                  </div>
          </div>
  );
}
