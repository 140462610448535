import React from 'react';
import { Dialog, DialogContent, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './index.css';

const Popup = (props: any) => {
  const { t } = useTranslation();
  const { open, onSubscribe, promoCode, setPromoCode, chosenPlan, onClose } = props;
  console.log('promoCode', promoCode);
  return <Dialog
    style={{
      maxWidth: '100%',
      paddingTop: '30px',
      paddingBottom: '30px',
      boxSizing: 'border-box',
      textAlign: 'center',
    }}
    sx={{ '& .MuiDialog-paper	':{ maxWidth: '800px' } }}
    open={open} onClose={onClose}>
        <DialogContent
            style={{
              width: '613px',
              height: '491px',
              minWidth: '613px',
              maxWidth: '613px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
        >
            <div className='coupon-question'>
                {t('couponQuestion')}
                </div>
                <div className='enter-coupon'>
                {t('EnterCoupon')}
                </div>
                <TextField
                placeholder='IZIPROMO'
                inputProps={{
                  style:{
                    textAlign: 'center',
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: '0px 0 0 0px',
                      textAlign: 'center',
                    },
                  },                  
                }}
                style={{ width: '303px',
                  margin: 'auto',
                  borderRadius: '0px' }}
                value={promoCode}
                onChange={(e: any) => setPromoCode(e.target.value)}
                />
                <div
                onClick={() => {
                  if (promoCode && promoCode.length > 0) {
                    onSubscribe(promoCode, chosenPlan);
                  }
                }}
                className='verify-code'
                style={{ opacity : promoCode ? 1 : 0.5 }}
                >
                {t('VerifyCode')}
                </div>
                <div
                onClick={() => onSubscribe(null, chosenPlan)}
                className='no-coupon'>
                {t('noCoupon')}
                </div>
                <div className='did-u-know'>
                {t('DiduKnow')}
                </div>
                <div className='manually-verified'>
                {t('ManuallyVerified')}
                </div>
            </DialogContent>
        </Dialog>;
};

export default Popup;