import axios from 'axios';

export const postProduct = async (data: any) => {
  const accessToken = localStorage.getItem('access_token');

  const formData = new FormData();
  Object.entries(data).map((e: any) => {
    const key = e[0];
    const value = e[1];
    if (!Array.isArray(value)) {
      formData.append(key, value);
    } else {
      value.map( (pic:any) => {
        formData.append(`${key}[]`, pic);
      });
    }
  });
  const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/offers`, formData, { headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } },
  );
  return res;
};

export const deleteProduct = async (id: any) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/offers/${id}`, { headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } },
  );
  return res;
};


export const putProduct = async (data: any, id: any) => {
  const accessToken = localStorage.getItem('access_token');

  const formData = new FormData();
  Object.entries(data).map((e: any) => {
    const key = e[0];
    const value = e[1];
    if (!Array.isArray(value)) {
      formData.append(key, value);
    } else {
      value.map( (pic:any) => {
        formData.append(`${key}`, pic);
      });
    }
  });
  const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/offers/${id}`, formData, { headers: {
    Authorization: `Bearer ${accessToken}`,
    'Content-Type': 'multipart/form-data',
  } },
  );
  return res;
};


export const fetchProduct = async (productId: any, isAuthenticated: any) => {
  let res;
  if (isAuthenticated) {
    const accessToken = localStorage.getItem('access_token');
    res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/offers/${productId}`, { headers: {
      Authorization: `Bearer ${accessToken}`,
    } },
    );  
  } else {
    res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/public-offers/${productId}`);  
  }
  return res;
};

export const fetchSellerOtherOffers = async (sellerId: any, page: number = 1) => {
  console.log('page', page);
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/offers?sellerId=${sellerId}`, { headers: {
    Authorization: `Bearer ${accessToken}`,
  } },
  );
  return res;
};