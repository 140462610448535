import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import ProductCard from '../../../../components/productCard';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '../../../../components/Pagination';
import { setPage } from '../../../../store/Search/searchReducer';
import { useAppDispatch } from '../../../../store/hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { fetchOffers } from '../../../../api/searchOffers';
import {
  setResults,
  setIsLoading,
} from '../../../../store/Search/searchReducer';
  

const OffersContainer = () => {
  const { t } = useTranslation();
  const { results, isLoading, lastPage } = useAppSelector(state => state.search);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { filters } = useAppSelector(state => state.search);
  let { page } = filters;
  const goToPage = (value: any) => {
    dispatch(setPage({ value, navigate }));
    window.localStorage.setItem('page', JSON.stringify(value));
  };
  React.useEffect(() => {
    if (window.localStorage.getItem('page')) {
      page = JSON.parse(window.localStorage.getItem('page') || '{}');
      goToPage(page);
      window.localStorage.removeItem('page');
    }
    const getOffers = async () => {
      dispatch(setIsLoading(true));
      const data = await fetchOffers(filters, 18);
      dispatch(setResults(data));
      dispatch(setIsLoading(false));
      const firstTime = window.localStorage.getItem('firstTime');
      if (firstTime === 'true') {
        window.localStorage.removeItem('firstTime');
      } else {
        const list = document.getElementsByClassName('offers-container');
        list[0].scrollIntoView();
      }
    };
    getOffers();
  }, [filters]);

  return <div style={{ padding: '0 6.6% 0 6.6%' }}>
    <div style={{ marginTop: '20px', marginBottom: '20px', color: '#21062E', fontSize: '26px' }}>
      {t('lastOffers')}
      </div>
  <div className='offers-container' style={{ justifyContent: 'center',
  }}>
      {isLoading && <div style={{
        width: '100%', 
        marginTop: '200px',
      }}>
        <CircularProgress style={{ height: '100px', width: '100px' }}/>
        </div>
      }
      {!isLoading && <React.Fragment>

        { results.map( (e: any) => <ProductCard
      style={{ marginRight: '0px', marginLeft: '30px' }}
      data={e}/>)
        }
      </React.Fragment>
        }
  </div>
  {!isLoading &&  lastPage > 1 && <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    }}
    >
    <Pagination
        pages={lastPage}
        selectedPage={page}
        action={goToPage}
      />
      </div>
  }
  </div>;
};
export default OffersContainer;