import React from 'react';

const IconHand = (props: any) => {
  const { color } = props;
  return (
<svg id="icon_hand" xmlns="http://www.w3.org/2000/svg" width="49.936" height="46.936" viewBox="0 0 49.936 46.936">
  <path id="Tracé_29" data-name="Tracé 29" d="M279.242,229.848h-7.86a.975.975,0,1,0,0,1.951h7.86a.975.975,0,0,0,0-1.951Z" transform="translate(-244.033 -208.832)" fill={color}/>
  <path id="Tracé_30" data-name="Tracé 30" d="M262.514,269.848h-9.668a.975.975,0,1,0,0,1.951h9.668a.975.975,0,0,0,0-1.951Z" transform="translate(-227.306 -245.175)" fill={color}/>
  <path id="Tracé_31" data-name="Tracé 31" d="M212.846,269.848a.974.974,0,1,0,.69.285A.985.985,0,0,0,212.846,269.848Z" transform="translate(-191.207 -245.175)" fill={color}/>
  <path id="Tracé_32" data-name="Tracé 32" d="M48.586,106.223a4.293,4.293,0,0,0-5.338-.039l-2.87,2.3V91.018A3.147,3.147,0,0,0,37.124,88H12.812a3.147,3.147,0,0,0-3.254,3.018v20.275c-.114.078-.225.157-.336.239L.368,118.077a.861.861,0,0,0-.155,1.271,1,1,0,0,0,.763.341,1.02,1.02,0,0,0,.607-.2l8.854-6.545a11.223,11.223,0,0,1,6.635-2.153H27.34a2,2,0,1,1,0,3.98H20.758a.907.907,0,1,0,0,1.809H27.34c.067,0,.133,0,.2,0,.033,0,.066,0,.1,0h1.7a11.059,11.059,0,0,0,6.909-2.388l8.281-6.645a2.222,2.222,0,0,1,2.763.021,1.848,1.848,0,0,1,0,2.892l-7.878,6.551a17.232,17.232,0,0,1-11.017,3.91H15.057a1.017,1.017,0,0,0-.692.267l-3.877,3.618a.859.859,0,0,0,0,1.279,1.03,1.03,0,0,0,1.38,0l3.591-3.351H28.395a19.269,19.269,0,0,0,12.319-4.372l7.878-6.551a3.572,3.572,0,0,0-.006-5.588ZM28.551,89.809v4.239l-.615-.57a1.03,1.03,0,0,0-1.379,0l-1.589,1.474-1.589-1.474a1.03,1.03,0,0,0-1.379,0l-.614.57V89.809Zm6.416,23.02a8.908,8.908,0,0,1-4.053,1.81,3.564,3.564,0,0,0,.522-1.854,3.96,3.96,0,0,0-4.1-3.8H17.073a13.468,13.468,0,0,0-5.565,1.2V91.018a1.26,1.26,0,0,1,1.3-1.209h6.622v6.422a.906.906,0,0,0,.6.836,1.031,1.031,0,0,0,1.063-.2l1.59-1.474,1.589,1.474a1.029,1.029,0,0,0,1.379,0L27.247,95.4l1.59,1.474a1.014,1.014,0,0,0,.69.265,1.04,1.04,0,0,0,.373-.069.906.906,0,0,0,.6-.836V89.809h6.622a1.26,1.26,0,0,1,1.3,1.209v19.034Z" transform="translate(0 -79.417)" fill={color}/>
  <path id="Tracé_33" data-name="Tracé 33" d="M246.975,6.339a.975.975,0,0,0,.975-.975V.975a.975.975,0,0,0-1.951,0V5.364A.975.975,0,0,0,246.975,6.339Z" transform="translate(-222.007)" fill={color}/>
  <path id="Tracé_34" data-name="Tracé 34" d="M437,236.975a.975.975,0,0,0,.975.975h4.389a.975.975,0,1,0,0-1.951h-4.389A.975.975,0,0,0,437,236.975Z" transform="translate(-394.379 -214.422)" fill={color}/>
  <path id="Tracé_35" data-name="Tracé 35" d="M10.975,237.951h4.389a.975.975,0,1,0,0-1.951H10.975a.975.975,0,1,0,0,1.951Z" transform="translate(-9.025 -214.422)" fill={color}/>
  <path id="Tracé_36" data-name="Tracé 36" d="M44.046,35.426a.976.976,0,0,0,1.38-1.38l-3.832-3.832a.976.976,0,0,0-1.38,1.38Z" transform="translate(-36.034 -27.01)" fill={color}/>
  <path id="Tracé_37" data-name="Tracé 37" d="M413.755,35.712a.974.974,0,0,0,.69-.286l3.832-3.832a.975.975,0,0,0-1.38-1.38l-3.832,3.832a.976.976,0,0,0,.69,1.665Z" transform="translate(-372.522 -27.01)" fill={color}/>
  <path id="Tracé_38" data-name="Tracé 38" d="M164.5,385.665a.974.974,0,1,0-.689.286A.976.976,0,0,0,164.5,385.665Z" transform="translate(-146.951 -348.89)" fill={color}/>
</svg>
  );
};
IconHand.defaultProps = {
  color: '#fff',
};
export default IconHand;