import React from 'react';

const IconBuilding = (props: any) => {
  const { color } = props;
  return (
<svg id="icon_building" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <path id="Tracé_23" data-name="Tracé 23" d="M50.719,11.427,29.068.485a4.605,4.605,0,0,0-4.115,0L3.3,11.427a2.214,2.214,0,0,0-1.273,2.25,2.357,2.357,0,0,0,1.828,1.916,3.061,3.061,0,0,0,.688.083,2.645,2.645,0,0,0,1.226-.3L27.01,4.652l21.24,10.73a2.768,2.768,0,0,0,1.915.212,2.356,2.356,0,0,0,1.827-1.916A2.213,2.213,0,0,0,50.719,11.427Zm-.5,2.352a.891.891,0,0,1-1.142.288L27.85,3.328a1.841,1.841,0,0,0-1.68,0L4.948,14.066a.9.9,0,0,1-1.155-.284.723.723,0,0,1,.315-1.04L25.776,1.8a2.764,2.764,0,0,1,2.469,0L49.9,12.743a.773.773,0,0,1,.4.458A.69.69,0,0,1,50.215,13.779Z" transform="translate(-2.01 0)" fill={color}/>
  <path id="Tracé_24" data-name="Tracé 24" d="M40.905,179.765a.756.756,0,0,0-.756.756v26.868a.756.756,0,0,0,1.512,0V180.521A.756.756,0,0,0,40.905,179.765Z" transform="translate(-36.544 -158.145)" fill={color}/>
  <path id="Tracé_25" data-name="Tracé 25" d="M424.9,179.765a.756.756,0,0,0-.756.756v26.868a.756.756,0,0,0,1.512,0V180.521A.756.756,0,0,0,424.9,179.765Z" transform="translate(-379.258 -158.145)" fill={color}/>
  <path id="Tracé_26" data-name="Tracé 26" d="M108.014,168.008H73.023a.822.822,0,0,0-.875.756v27.979a.884.884,0,0,0,1.75,0v-24.2h33.241v24.2a.884.884,0,0,0,1.75,0V168.764A.822.822,0,0,0,108.014,168.008ZM73.9,171.033V169.52h33.241v1.512Z" transform="translate(-65.518 -147.499)" fill={color}/>
  <path id="Tracé_27" data-name="Tracé 27" d="M181,105.521a.756.756,0,0,0,0-1.512H168.9a.756.756,0,0,0-.756.756v3.025a.756.756,0,0,0,.756.756H181a.756.756,0,1,0,0-1.512H169.658v-1.512Z" transform="translate(-149.952 -93.063)" fill={color}/>
  <path id="Tracé_28" data-name="Tracé 28" d="M133.936,258.155h-.9v-8.318a.839.839,0,0,0-.9-.756h-2.708v-8.318a.839.839,0,0,0-.9-.756H115.883a.839.839,0,0,0-.9.756v3.781h-4.513a.839.839,0,0,0-.9.756v3.781h-2.708a.839.839,0,0,0-.9.756v8.318h-.9a.839.839,0,0,0-.9.756v3.025a.917.917,0,0,0,1.805,0v-2.269h2.708v2.269a.917.917,0,0,0,1.805,0v-2.269h1.805v2.269a.917.917,0,0,0,1.805,0v-2.269h2.708v2.269a.917.917,0,0,0,1.805,0v-2.269H120.4v2.269a.917.917,0,0,0,1.805,0v-2.269h2.708v2.269a.917.917,0,0,0,1.805,0v-2.269h1.805v2.269a.917.917,0,0,0,1.805,0v-2.269h2.708v2.269a.917.917,0,0,0,1.805,0v-3.025A.839.839,0,0,0,133.936,258.155Zm-9.027-7.562h1.805V252.1h-1.805Zm-1.805-9.074v1.512H121.3v-1.512Zm-6.319,0h2.708v2.269a.839.839,0,0,0,.9.756h3.611a.839.839,0,0,0,.9-.756v-2.269h2.708v7.562H116.785Zm-4.513,9.074h1.805V252.1h-1.805Zm-.9-4.537h3.611v3.025h-3.611Zm-3.611,12.1v-7.562h2.708v2.269a.839.839,0,0,0,.9.756h3.611a.839.839,0,0,0,.9-.756v-2.269h2.708v7.562Zm12.637,0v-7.562H123.1v2.269a.839.839,0,0,0,.9.756h3.611a.839.839,0,0,0,.9-.756v-2.269h2.708v7.562Z" transform="translate(-94.493 -212.692)" fill={color}/>
</svg>
  );
};
IconBuilding.defaultProps = {
  color: '#fff',
};
export default IconBuilding;