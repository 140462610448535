import axios from 'axios';

export const getAnalytics = async () => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analytics/offers`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};

export const getOffersCount = async (sellerId: any, countAll: any) => {
  const accessToken = localStorage.getItem('access_token');
  let d = [];
  if (countAll) {
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analytics/offers?count=${countAll}`, { headers: {
      Authorization: `Bearer ${accessToken}`,
    } },
    );
    d = data;  
  } else {
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analytics/offers?sellerId=${sellerId}&count=${false}&action=view`, { headers: {
      Authorization: `Bearer ${accessToken}`,
    } },
    );
    const arr = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analytics/offers?sellerId=${sellerId}&count=${countAll}&action=click`, { headers: {
      Authorization: `Bearer ${accessToken}`,
    } },
    );
    const dd = arr.data;
    const p = data.map((e: any) => {
      let ds: any = {};
      if (dd && dd.length > 0) {
        ds = dd.find((s: any) => s._id == e._id);
        return { ...e, clicks: ds && ds.count, received: ds ? ds.received : 0, pending: ds ? ds.pending : 0 };
      } else { return { ...e }; }
    });
    const o = dd.filter( (s:any) => !data.find((el: any) => el._id === s._id));
    d = [...p, ...o];
  }
  return d;
};

export const fetchNotesAnalytics = async (type: string) => {
  const accessToken = localStorage.getItem('access_token');
  let query = '';
  if (type === 'received') {
    query = '?received=true';
  } else if (type === 'left') {
    query = '?left=true';
  }
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/analytics/notes${query}`, { headers: {
    Authorization: `Bearer ${accessToken}`,
  } },
  );
  return data;
};