import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: 16,
    paddingTop: '8px',
    padding: '7px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    borderRadius: 0,
  },
}));

const ex = (props: any) => { 
  const { labelColor } = props;
  return (
<FormControl variant="filled">
<InputLabel style={{ color: labelColor, transform: 'translate(15px, 8px)' }} htmlFor={props.id}>{props.label}</InputLabel>
<BootstrapInput id={props.id} {...props} />
</FormControl>
  );
};
ex.defaultProps = {
  labelColor: '#c122fa',
};
export default ex;