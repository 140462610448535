import { Grid } from '@mui/material';
import TextField from '../../../components/textField';
// import SwitchButton from '../../../components/FormikSwitchButton';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Button from '@mui/material/Button';

export default function SimpleDialog( props: any) {
  
  const { collectedFormsValue, setCollectedFormsValues, update, userId } = props;
  const { t } = useTranslation();
  const textFieldStyle = {
    marginBottom:'30px',
    width:'96%',
  };
  const textFieldInputProps = {
    style: {
      height: '30px',
      backgroundColor: '#fff',
      padding: '4px 0px 4px 8px',
      minWidth: '250px',
    },
  };
  console.log('collectedFormsValue', collectedFormsValue);
  return (
    <Formik
    enableReinitialize
    initialValues={{  ...collectedFormsValue }}
    validationSchema={Yup.object().shape({
      lastName: Yup.string().required(t('mandatoryRow')),
      firstName: Yup.string().required(t('mandatoryRow')),
      mobile: Yup.string().max(255),
    })}
    onSubmit={(values) => {
      const accountType = values.isProfessional === 'on' ? 'Professionnel' : 'Particulier';
      const {
        lastName,
        firstName,
        mobile,
      } = values;
      const data = {
        lastName,
        firstName,
        mobile,
        accountType,
      };
      setCollectedFormsValues({ ...collectedFormsValue, ...data });
      update( { ...collectedFormsValue, ...data }, userId);
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
        <div style={{ }}>
            <div style={{ justifyContent: 'center', marginTop: '30px', backgroundColor: '#F5FBFB', width: '100%' }}>
            <Grid style={{ justifyContent: 'center' }} container>

            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="LastName"
              label={t('OwnerLastName')}
              variant="outlined"
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="FirstName"
              variant="outlined"
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              label={t('OwnerFirstName')}
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
        </Grid>
        <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="PhoneNumber2"
              label={t('PhoneNumber2')}
              variant="outlined"
              style={textFieldStyle}
              error={Boolean(touched.mobile && errors.mobile)}
              helperText={touched.mobile && errors.mobile}
              name="mobile"
              value={values.mobile}
              onChange={handleChange}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }} />
        </Grid>
        </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px', marginBottom: '15px' }}>

        <Button variant="contained" type="submit">
        {t('Submit')}
          </Button>
          </div>
          </form>
      );
    }}
        </Formik>
  );
}
