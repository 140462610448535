import React from 'react';
import './index.css';
import { useAppSelector } from '../../store/hooks';
import ProductCard from '../../components/productCard';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '../../components/Pagination';
import { setPage } from '../../store/Search/searchReducer';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Sort from '../../components/Sort';

const OffersContainer = () => {
  const { t } = useTranslation();
  const { results, isLoading, lastPage, totalOffers } = useAppSelector(state => state.search);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { filters } = useAppSelector(state => state.search);
  const { page } = filters;
  const goToPage = (value: any) => {
    dispatch(setPage({ value, navigate }));
    const list = document.getElementsByClassName('offers-container');
    list[0].scrollIntoView();
  };

  return <div style={{ width: '75.8%' }}>
    <div style={{ color: '#21062E',
      font: 'normal normal normal 26px/28px Jost',
      opacity: '100%',
      letterSpacing: '0px',
      marginBottom: '20px',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    }}>
        {filters.searchBar && <span>"{filters.searchBar}" &nbsp;-&nbsp; </span>}
      <span>{`${totalOffers} ${t('Offers')}`}</span>
      <span
      style={{ position: 'absolute', right: '6.6%', fontSize: '14px', paddingRight:'30px', paddingTop: '3px' }}
      >
      {t('SortBy')}
      </span>
      <span
      style={{ position: 'absolute', right: '6.6%' }}
      >
      <Sort />
      </span>
      </div>
  <div className='offers-container'>
      {isLoading && <div style={{
        width: '100%', 
        marginTop: '200px',
      }}>
        <CircularProgress style={{ height: '100px', width: '100px' }}/>
        </div>
      }
      {!isLoading && <React.Fragment>

        { results.map( (e: any) => <ProductCard
      style={{ marginRight: '0px', marginLeft: '30px' }}
      data={e}/>)
        }
      </React.Fragment>
        }
  </div>
  {!isLoading &&  lastPage > 1 && <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    }}
    >
    <Pagination
        pages={lastPage}
        selectedPage={page}
        action={goToPage}
      />
      </div>
  }
  </div>;
};
export default OffersContainer;