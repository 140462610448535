import axios from 'axios';

export const signup = async (data: any) => {
  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
  }
  const newData = removeEmpty(data);
  // const { email, password } = newData;
  //   const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/register`, newData);
  const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/register`, newData );  
  console.log('res', res);
};

export const login = async (data: any) => {
  const { email, password } = data;
  const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login`, { email, password });
  return res;
};

export const resetPassword = async (data: any) => {
  const { email } = data;
  const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/reset-password/${email}`);
  console.log('res', res);
};

export const refreshMyToken = async () => {
  try {
    const accessToken = localStorage.getItem('refresh_token');
    const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/token/refresh`, {
      refreshToken: accessToken,
    },
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
    localStorage.setItem('access_token', data.access_token);
    return data;
  } catch (e) {
    return e;
  }
};
