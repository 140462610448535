import React, { Suspense } from 'react';
import './App.css';
import { store } from './store';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import theme from './muiTheme';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import AppRouter from './AppRouter';
import { SnackbarProvider } from 'notistack';
import Footer from './components/Footer';

function App() {
  return (
    <Suspense fallback={<div></div>}>
  		<DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={theme}>
      <Provider store={store}>
      <SnackbarProvider
            dense
            maxSnack={3}
          >
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }} className="App">
            <AppRouter></AppRouter>
            <div style={{ marginTop: 'auto' }} >
            <Footer/>
            </div>
        </div>
      </SnackbarProvider>
      </Provider>
      </ThemeProvider>
      </DndProvider>
    </Suspense>
  );  
}

export default App;
