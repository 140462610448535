import React from 'react';
import DropZone from 'react-dropzone';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export default function MyDropzone(pros: any) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const onDropRejected = () => {
    enqueueSnackbar(t('maxAttachSizeExceeded'), { variant: 'error' });
  };


  const { setDocument, attachmentUrls,
    setDidChange, didChange,
    setFormData,
    formData,
  } = pros;
  const [files, setFiles] = React.useState<Array<File>>([]);
  const onDrop = (acceptedFiles: any) => {
    setDidChange(true);
    setDocument(acceptedFiles[0]);
    setFiles([...files, ...acceptedFiles]);
    setFormData({ ...formData,
      deletedAttachments: attachmentUrls[0],
      attachment: [acceptedFiles[0]] });
  };
  return (
<DropZone
  onDrop={onDrop}
  onDropRejected={onDropRejected}
  accept={['image/gif', 'image/jpeg', 'image/png']}
  disabled={ files.length > 0 }
  maxSize={5242880}
  >
  {(props: any) => {
    let { getRootProps, getInputProps } = props;
    return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box sx={{
          height: '70px',
          border: '1px solid #C6C6C6',
          cursor: 'pointer',
          backgroundColor: '#BF28FA',
          color: '#fff',
        }}>
          <div style={{ padding: '0px 80px 0px 80px',
            color: '#fff',
            textAlign: 'center',
            fontSize: '14px',
            paddingTop: '8px',
          }}
          dangerouslySetInnerHTML={{ __html: t('AddAttachementText') }}
          >
          </div>
        </Box>
      </div>
      <div style={{ color: '#BF28FA', fontSize: '12px', paddingTop:'15px', display:'flex' }}>
        <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', top: '4px', marginRight:'8px'  }} color='#BF28FA' icon={faInfoCircle} />
       {`${t('AcceptedFiles')}`}
        </div>
        <div style={{ color: '#BF28FA', fontSize: '12px' }}>
          {t('maxAttachments')}
          </div>
      <div style={{ color: '#BF28FA', fontSize: '12px', padding:'12px' }}>
      </div>
      {didChange && files.length > 0 && <Box sx={{
        height: '40px',
        border: '1px solid #C6C6C6',
        display:'flex',
        justifyContent: 'space-between',
        backgroundColor: '#fff' }}>
          <div style={{ padding: '8px',
            color: '#545C84',
            textAlign: 'center',
            fontSize: '14px',
          }}>
              {files[0].name}
            </div>
        <div style={{ borderLeft:'#C6C6C6 solid 1px', width:'40px', height:'40px' }} onClick={() => {
          setFormData({ ...formData, attachment: undefined });
          setFiles([]);
        } }>
        <FontAwesomeIcon
          icon={faTrash}
          color='rgba(248, 36, 120, 1)'
          style={{
            margin: 'auto',
            top: '10px',
            position: 'relative',
            paddingLeft: '13px',
          }}
          />
            </div>
        </Box>
      }
            {!didChange && attachmentUrls.length > 0 && (
              !formData || !formData.deletedAttachments
            ) && <Box sx={{
              height: '40px',
              border: '1px solid #C6C6C6',
              display:'flex',
              justifyContent: 'space-between',
              backgroundColor: '#fff' }}>
          <div style={{ padding: '8px',
            color: '#545C84',
            textAlign: 'center',
            fontSize: '14px',
          }}>
              {attachmentUrls[0]}
            </div>
        <div style={{ borderLeft:'#C6C6C6 solid 1px', width:'40px', height:'40px' }} onClick={() => {
          setFormData({ ...formData,
            deletedAttachments: attachmentUrls[0],
          });
        } }>
        <FontAwesomeIcon
          icon={faTrash}
          color='rgba(248, 36, 120, 1)'
          style={{
            margin: 'auto',
            top: '10px',
            position: 'relative',
            paddingLeft: '13px',
          }}
          />
            </div>
        </Box>
      }
    </section>
    );
  }
  
}
</DropZone>
  );
}