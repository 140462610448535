import React, { useEffect, useState } from 'react';
import { getStore, getStoreNotes, getStoreReviews } from '../../api/shop';
import { useParams } from 'react-router-dom';
import StoreHeader from './StoreHeader';
import ContactSection from './ContactSection';
import SellerOffers from './SellerOffers';
import Reviews from './Reviews';
import Catalogs from './Catalogs';

const Store = () => {
  const [storeData, setStoreData] = useState<any>(null);
  const [color, setColor] = useState('#C221FA');
  const [isLoading, setIsLoading] = useState(false);
  const { domain } = useParams();
  const [storeNotes, setStoreNotes] = useState<any>(null);
  const [storeReviews, setStoreReviews] = useState<any>(null);
  useEffect(() => {
    const fetchStore = async (name: string) => {
      setIsLoading(true);
      const sdata = await getStore(name);
      setStoreData(sdata);
      if (sdata && sdata.color) {
        setColor(sdata.color);
      }
      if (sdata && sdata.seller && sdata.seller._id) {
        const snotes = await getStoreNotes(sdata.seller._id);
        setStoreNotes(snotes);
      }
      if (sdata && sdata.seller && sdata.seller._id && sdata.evaluations) {
        const sreviews = await getStoreReviews(sdata.seller._id);
        setStoreReviews(sreviews);
      }
    };
    if (domain) {
      fetchStore(domain);
    }
  }, [domain]);
  console.log(storeData, isLoading);
  return <div className='store-container'>
      <StoreHeader
        logo={storeData?.logo}
        analytics={storeNotes}
        color={color}
        ></StoreHeader>
        {
            storeData?.banner && storeData?.hasBanner && <img
            src={storeData.banner}
            className='store-banner'
            />
        }
        {
            storeData?.contact && <ContactSection
            companyName={storeData.companyName}
            mobile={storeData.mobile}
            phone={storeData.phone}
            refContact={storeData.refContact}
            slogan={storeData.slogan}
            color={color}
            languages={storeData.languages}
            sellerData={storeData.seller}
            />
        }
        {storeData?.recommendedProdcuts && storeData.recommendedProdcuts.length > 0 &&
            <SellerOffers
            recommendedProdcuts={storeData.recommendedProdcuts}
            color={color}
            />
        }
        {
            storeData && storeData.catalog && <Catalogs catalog={storeData.catalog} />
        }
        {
            storeNotes && storeReviews && <Reviews
            data={storeNotes}
            reviews={storeReviews}
            color={color}
            />
        }
  </div>;
};

export default Store;