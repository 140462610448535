import React from 'react';
import { PRICE_DESC, PRICE_ASC, DATE } from '../../constants';
import { Select, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { setSort } from '../../store/Search/searchReducer';

const Sort = () => {
  const navigate = useNavigate();
  const { filters } = useAppSelector(state => state.search);
  const { sort } = filters;
  const { t } = useTranslation();
  const data = [PRICE_DESC, PRICE_ASC, DATE];
  const dispatch = useAppDispatch();
  return <Select
      style={{
        width: '90px',
        height: '30px',
        borderRadius: '0px',
        fontSize: '14px',
        border: '1px solid #CDE9E6',
        zIndex: 4,
        boxShadow: 'none',
      }}
      value=''
      inputProps={{
        MenuProps: { disableScrollLock: true },
        appearance: 'none',
      }}
      >
          {
            data.map(e => <MenuItem
                style={{
                  fontSize: '14px',
                  backgroundColor: sort === e ? 'rgb(193, 34, 250)' : '#fff', 
                }}
                value={e}
                onClick={() => dispatch(setSort({ value: e, navigate }))}
                >{t(e)}</MenuItem>)
          }
          </Select>
  ;
};

export default Sort;