import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import Wheel from '@uiw/react-color-wheel';
import './index.css';
import PhotosDropZone from './PhotoDropZone';

const MyName = (props: any) => {
  const {
    setColorAcc,
    handleChange,
    values,
    setFieldValue,
  } = props;
  const { t } = useTranslation();
  const [image, setImage] = React.useState([]);
  const [hsva, setHsva] = React.useState({ h: 217, s: 0, v: 100, a: 1 });
  const [color, setColor] = React.useState<any>('#C122FA26');
  return <Grid container style={{ minHeight: '380px' }}>
      <Grid item xs={12} md={6}>
          <div>
              <div className='my-identity-field-label'>
                  {t('Name')}
                  </div>
              <div>
                  <TextField
                   placeholder={t('bydefaultsubsc')}
                   variant='standard'
                   onChange={handleChange}
                   value={values.companyName}
                   name='companyName'
                   style={{ width: '84%', marginBottom: '11px' }}
                  ></TextField>
                  </div>
              </div>
              <div>
              <div className='my-identity-field-label'>
                  {t('Logo')}
                  </div>
              <div>
              <PhotosDropZone
                imagesList={image}
                values={values}
                setFieldValue={setFieldValue}
                name='logo'
                setImagesList={(value: any) => setImage(value)}/>
                  </div>
              </div>
              <div style={{ marginTop: '10px' }}>
              <div className='my-identity-field-label'>
                  {t('Slogan')}
                  </div>
              <div>
                  <TextField
                   variant='standard'
                   style={{ width: '84%' }}
                   onChange={handleChange}
                   value={values.slogan}
                   name='slogan'
                  ></TextField>
                  </div>
              </div>
          </Grid>
      <Grid item xs={12} md={6}
      style={{
        borderLeft: '2px solid #F5F5F5',
      }}
      >
          <div style={{ margin: 'auto' }}>
        <Wheel
            color={hsva}
            onChange={(colorr) => {
              console.log('color', colorr);
              setHsva({ ...hsva, ...colorr.hsva });
              setColor(colorr.hex);
              setColorAcc(colorr.hex);
            }}
            style={{ margin: 'auto' }}
            />
            </div>
            <div style={{ margin: 'auto',
              width: '90%',
              borderRadius: '21px',
              backgroundColor: color,
              height: '42px',
              marginTop: '25px',
            }}>
            </div>
            <input
            contentEditable="true"
            value={color}
            onChange={(e: any) => {
              setColor(e.target.value);
              setColorAcc(e.target.value);
            }}
            style={{ margin: 'auto',
              fontSize: '24px',
              color: color,
              fontWeight: 'bold',
              marginTop: '20px',
              textTransform: 'uppercase',
              border:'0px',
              textAlign: 'center',
            }}/>
            <div>
                </div>
        </Grid>
        </Grid>;
};

export default MyName;