import React, { useLayoutEffect, useState } from 'react';
import PromotedCategory from '../../../components/promotedCategory';

import HomeBanner from '../../../assets/homeBanner.png';
import BuyIcon from '../../../assets/icons/buy_icon';
import BuildingIcon from '../../../assets/icons/icon_building';
import HandIcon from '../../../assets/icons/icon_hand';
import HomePageButton from '../../../components/HomePageButton';
import i18n from '../../../i18n';
import { useNavigate } from 'react-router-dom';
import { setIsSignupDialogOpen } from '../../../store/Common/CommonReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { resetFilters } from '../../../store/Search/searchReducer';
import Offers from './Offers';
import './index.css';

// const promotedCategoriesRaw = '[{"_id":"61e1a37895e83c6098c06745","icons":{"black":"https://iili.io/lHo042.png"},"updateDate":1642177400916,"creationDate":1642177400916,"parentCategory":"","description":"","displayed":true,"promotedCategory":false,"name":{"fr":"Vêtements & textiles","en":"Clothing"},"__v":0},{"_id":"61e19f7395e83c6098c0673c","icons":{"black":"https://iili.io/lHo042.png"},"updateDate":1642176371076,"creationDate":1642176371076,"parentCategory":"","description":"","displayed":true,"promotedCategory":false,"name":{"fr":"Meubles & mobilier","en":"Furniture"},"__v":0},{"_id":"61e19cd137c301cd65ac291a","icons":{"black":"https://iili.io/lHo042.png"},"updateDate":1642175697039,"creationDate":1642175697039,"parentCategory":"","description":"","displayed":true,"promotedCategory":false,"name":{"fr":"Construction & industrie","en":"Building & industry"},"__v":0}]';
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


const HomePage = () => {
  window.localStorage.setItem('firstTime', 'true');
  const { t } = useTranslation();
  // const promotedCategories = JSON.parse(promotedCategoriesRaw);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const goToFrenchOffers = () => {
    navigate('annonces?countries[]=FR&limit=12&page=1');
  };
  const { isAuthenticated } = useAppSelector(state => state.user);
  const { role } = useAppSelector(state => state.account);
  const openOffersList = () => {
    navigate({ pathname: '/annonces' });
  };
  const sellAction = () => {
    if (isAuthenticated) {
      if (role === 'seller') {
        return navigate('/dashboard/annonce/form');
      }
    }
    dispatch(setIsSignupDialogOpen(true));
  };

  const homPageButtonsData = [{
    label: t('AllOffers'),
    color: '#F6256D',
    Icon: BuyIcon,
    action: openOffersList,
  },
  {
    label:  t('FrenchOffers'),
    color: '#BF23F9',
    Icon: BuildingIcon,
    action: goToFrenchOffers,
  },
  {
    label:  t('SellOnIziplace'),
    color: '#5ED5C8',
    Icon: HandIcon,
    action: sellAction,
    disabled: isAuthenticated && role !== 'seller',
    helperText: t('noSubToSell'),
  },
  ];
  const { language } = i18n;
  const [languageCode, setLanguageCode] = React.useState('fr');
  React.useEffect(() => {
    if (language) {
      const lc = language.substr(0, 2);
      setLanguageCode(lc);
    }
  }, [language]);
  React.useEffect(() => {
    dispatch(resetFilters());
  }, []);
  const { categories } = useAppSelector(state => state.common);
  let promotedCategories = [];
  if (categories && categories.length > 0) {
    promotedCategories = categories.filter(c => c.promotedCategory);
  }
  const [width, height] = useWindowSize();
  console.log('height', height);
  return <div>
      <div className='home-first-section-container'>
      <div className='home-first-section'>
      <div className={width && width > 1300 ? 'home-promoted-filler' : 'home-promoted-filler-none'}>
        </div>
        <div className='home-promoted-container'>
            <div style={{ width: '100%', marginBottom: '55px' }}>
                <img src={HomeBanner} width='100%' />
                </div>
            <div className='promoted-categories-container'>
            {promotedCategories.length > 0 && promotedCategories.map((cat: any) => <PromotedCategory
              data={cat}
              language={languageCode}
              />) }
            </div>
            <div className='homepage-buttons-container'>
            {
                homPageButtonsData.map((e: any) => <HomePageButton
                style={{
                  fontSize: '16px',
                }}
                responsiveIcons={width < 1300}
                {...e} />)
            }
          </div>
        </div>
        </div>
        
  </div>
  <Offers />
  </div>
  ;
};

export default HomePage;