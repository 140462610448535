import * as React from 'react';
import {
  Dialog,
} from '@mui/material';
import Contents from './contents';
import { useAppSelector } from '../../../store/hooks';
import { useAppDispatch } from '../../../store/hooks';
import { resetIsVisitor } from '../../../store/User/userReducer';
export default function SimpleDialog() {
  const dispatch = useAppDispatch();
  const { isVisitor }  = useAppSelector((state) => state.user);
  const accountId = useAppSelector((state) => state.account._id);
  const handleClose = () => {
    dispatch(resetIsVisitor());
  };

  return (
    <React.Fragment>
          <Dialog sx={{ '& .MuiDialog-paper	':{ maxWidth: '800px', width: '50%' } }} onClose={handleClose} open={isVisitor}>
        <Contents close={handleClose} accountId={accountId} />
        </Dialog>
        </React.Fragment>
  );
}
