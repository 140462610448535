import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function SimpleAccordion(props: any) {
  const { children, title, active, canBeExpanded, noPadding } = props;
  const [openedFromInside, setOpenedFromInside] = React.useState(false);
  const handleChange = () => {
    if (canBeExpanded ) {
      setOpenedFromInside(!openedFromInside);
    }
  };
  return (
      <Accordion expanded={active || openedFromInside} onChange={handleChange} style={{ marginBottom: '30px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: '#E6F4F5', margin: 'auto' }}
        >
          <Typography style={{ margin:'auto', fontSize: '18px' }}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#F5FBFB', padding:noPadding ? '0px' : '30px', border:'1px solid #CDE9E6' }}>
          {children}
        </AccordionDetails>
      </Accordion>
  );
}
