import React from 'react';
import LeftSection from './LeftSection';
import RatingsSection from './RatingsSection';
import './index.css';
import { useTranslation } from 'react-i18next';

const Evaluations = (props: any) => {
  const { t } = useTranslation();
  const { data, reviews, color } = props;
  return <div>
    <div style={{ fontSize: '24px', color: '#21062E', fontWeight: 'bold' }}>
    {t('EvaluationsSeller')}
      </div>
    <div className='evaluation-container'>
    { data &&
      <LeftSection analytics={data} color={color} />
    }
    {
     <RatingsSection data={reviews} />
   }
   </div>
  </div>;
};
export default Evaluations;