import React from 'react';
import Filters from './Filters';
import './index.css';
import Divider from '@mui/material/Divider';
import OffersContainer from './OffersContainer';

const OffersList = () => {
  return <div className='offers-list-container'>
  <Divider sx={{ borderColor: '#CDE9E6', marginTop: '10px' }}></Divider>
  <div className='filters-results-container'>
      <Filters />
      <OffersContainer />
  </div>
  </div>;
};
export default OffersList;