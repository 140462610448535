import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import './index.css';

const MyName = (props: any) => {
  const { t } = useTranslation();
  const { values, handleChange, errors, touched, myRef } = props;
  return <div ref={myRef}>
        <div className="my-name-helper">
            {t('ChoseYourName')}
            </div>
        <div>
            <TextField
            value={values.domain}
            onChange={handleChange}
            variant="standard"
            name='domain'
            style={{
              width: '500px',
              textAlign: 'left',
            }}
            error={Boolean(touched.domain && errors.domain)}
            helperText={touched.domain && errors.domain}
            inputProps={{
              style: {
                textAlign: 'center',
              },
            }}
            />
            </div>
        </div>;
};

export default MyName;