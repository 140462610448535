import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import './index.css';
import FormikSelect from '../../../components/FormikSelect';
import flags from '../../Home/ProductForm/Flags';

const MyContact = (props: any) => {
  const { t } = useTranslation();
  const { handleChange, values, setFieldValue } = props;
  const CountriesTranslations: any = t('CountriesList', { returnObjects: true });
  let countriesobjs = Object.entries(CountriesTranslations);
  countriesobjs.sort((cata: any, catb: any) => {
    const textA = cata[1].toUpperCase();
    const textB = catb[1].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
  });

  const countries = countriesobjs.map(([key, value]) => {
    const flag = flags.find(e => e.code === key);
    return ({
      label:(<div style={{ display: 'flex' }}>
        <img height='24' width='30' style={{ marginRight: '10px' }} src={flag ? flag.flag : '' }/><span>{String(value)}</span></div>
      ),
      fieldLabel: <img height='24' width='30' style={{ marginRight: '10px' }} src={flag ? flag.flag : '' }/>,
      value: key,
    }
    );
  });
  const textFieldStyle = {
    marginBottom:'30px',
    width:'84%',
  };
  return <Grid container>
      <Grid item xs={12} md={6}>
          <div>
              <div className='my-contact-field-label'>
                  {t('FirstLastNameRef')}
                  </div>
              <div>
                  <TextField
                   placeholder={t('bydefaultsubsc')}
                   variant='standard'
                   style={{ width: '84%', marginBottom: '11px' }}
                   value={values.refContact}
                   onChange={handleChange}
                   name='refContact'
                  ></TextField>
                  </div>
              </div>
              <div style={{ marginTop: '10px' }}>
              <div className='my-contact-field-label'>
                  {t('PhoneNumber1')}
                  </div>
              <div>
                  <TextField
                   placeholder={t('bydefaultsubsc')}
                   variant='standard'
                   style={{ width: '84%' }}
                   value={values.phone}
                   onChange={handleChange}
                   name='phone'
                  ></TextField>
                  </div>
              </div>
          </Grid>
      <Grid item xs={12} md={6} >
            <div>
              <div className='my-contact-field-label'>
                  {t('PhoneNumber2')}
                  </div>
              <div>
                  <TextField
                   placeholder={t('bydefaultsubsc')}
                   variant='standard'
                   style={{ width: '84%', marginBottom: '11px' }}
                   value={values.mobile}
                   name='mobile'
                   onChange={handleChange}
                  ></TextField>
                  </div>
              </div>
              <div style={{ marginTop: '10px' }}>
              <div className='my-contact-field-label'>
                  {t('Languages')}
                  </div>
                <div>
                    <FormikSelect
                        name="languages"
                        multiple
                        maximum={3}
                        value={values.languages}
                        placeholder={t('Languages')}
                        style={textFieldStyle}
                        setFieldValue={setFieldValue}
                        options={countries}
                    />
                    <div style={{ display: 'flex', justifyContent: 'start', marginLeft: '50px' }}>
                        {values.languages && values.languages.length > 0 && values.languages.map((e: any) => {
                          const fl = flags.find(f => f.code === e);
                          return <img height='24' width='30' style={{ marginRight: '10px' }} src={fl ? fl.flag : '' }/>;
                        })}
                        </div>
                    </div>
              <div>
                  </div>
              </div>
        </Grid>
        </Grid>;
};

export default MyContact;