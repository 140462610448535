import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const Header = (props: any) => {
  const { t } = useTranslation();
  const { fetchData, setSelectedMessage, selectedStatus, setSelectedStatus } = props;
  const navigate = useNavigate();
  const buttonsData = [
    {
      label: 'All',
      action: () => {
        setSelectedStatus('All');
        setSelectedMessage(null);
        navigate('/messages');
        fetchData('active', null);
      },
      bgColor: '#2C2C2C',
    },
    {
      label: 'AwaitingReply',
      action: () => {
        setSelectedStatus('AwaitingReply');
        setSelectedMessage(null);
        navigate('/messages');
        fetchData(null, false);
      },
      bgColor: '#5C5762',
    },
    {
      label: 'Replied',
      action: () => {
        setSelectedStatus('Replied');
        setSelectedMessage(null);
        navigate('/messages');
        fetchData(null, true);
      },
      bgColor: '#5C5762',
    },
    {
      label: 'Archived',
      action: () => {
        setSelectedStatus('Archived');
        setSelectedMessage(null);
        navigate('/messages');
        fetchData('archived');
      },
      bgColor: '#5C5762',
    },
    {
      label: 'Trash',
      action: () => {
        setSelectedStatus('Trash');
        setSelectedMessage(null);
        navigate('/messages');
        fetchData('deleted');
      },
      bgColor: '#5C5762',
    },
  ];
  return <div id='messages-header'>
        {buttonsData.map(el => <div
        onClick={el.action}
        className='button-header' style={{
          backgroundColor: selectedStatus === el.label ? '#2C2C2C' : '#5C5762' }}>
            <div style={{ transform: 'translate(0, 5px)' }}>
            {t(el.label)}
            </div>
            </div>)}
        </div>;
};

export default Header;