import * as React from 'react';
import './tabs.css';
import { useNavigate } from 'react-router-dom';


const Tabs = (props: any) => {
  let navigate = useNavigate();
  const { sections, selectedTab, setSelectedTab } = props;
  const handleChange = (index: number) => {
    if (index !== selectedTab) {
      setSelectedTab(index);
      navigate(sections[index].link);

    }
  };
  return (
    <div className="tabContainerd">
      { sections.map( (e: any, index: number) => <div
        className={selectedTab === index ? 'selected-s' : 'notSelected-s'}
        onClick={() => handleChange(index)}
       >
         <span className="tabText">
        {e.label}
        </span>
        </div>,
      )}
      <span style={{ width:'66%', borderBottom: '1px solid #CDE9E6' }}>
        </span>
      </div>
  );
};

export default Tabs;