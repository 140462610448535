/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import './index.css';
import MostVisiedLine from './MostVisitedLine';
import { useTranslation } from 'react-i18next';
import { getOffersCount } from '../../api/analytics';
import { useAppSelector } from '../../store/hooks';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MostVisited = (props: any) => {
  const { fetchData } = props;
  const { t } = useTranslation();
  const { _id: sellerId } = useAppSelector(state => state.account);
  const [offersData, setOffersData] = React.useState([]);
  const { offersAnalytics } = useAppSelector(state => state.analytics);

  return <div className='messages-table'>
      <div className='messages-table-title'>
      {t('MostPopularOffers')}
          </div>
      <div className='messages-table-head'>
          <div style={{ width: '30%', paddingTop:'8px', color:'#fff', fontSize: '16px', paddingLeft: '20px' }}>
              {t('FormTitle')}
            </div>
          <div style={{ width: '14%', paddingTop:'8px', color:'#fff', fontSize: '16px' }}>
              {t('LastUpdate')}
            </div>
          <div style={{ width: '7.5%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center' }}>
              {t('Views')}
            </div>
          <div style={{ width: '7.5%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('Clicks')}
            </div>
            <div style={{ width: '7.5%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
            <FontAwesomeIcon
                icon={faEnvelope}
                color='#fff'
                style={{ marginRight: '5px' }}
                />
              {t('received')}
            </div>
            <div style={{ width: '11%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('pending')}
            </div>
            <div style={{ width: '21%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('Actions')}
            </div>


          </div>
          {offersAnalytics.length > 0 && offersAnalytics.map((e: any, index: number) => <MostVisiedLine
          {...e}
          index={index}
          fetchData={fetchData}
          offersData={offersData}
          setOffersData={setOffersData}
          />)}
    </div>;
};

export default MostVisited;