import australia from '../../assets/icons/flags/australia-min.png';
import austria from '../../assets/icons/flags/austria-min.png';
import bangladesh from '../../assets/icons/flags/bangladesh-min.png';
import belgium from '../../assets/icons/flags/belgium-min.png';
import bosniaAndHerzegovina from '../../assets/icons/flags/bosnia-and-herzegovina-min.png';
import bulgaria from '../../assets/icons/flags/bulgaria-min.png';
import canada from '../../assets/icons/flags/canada-min.png';
import china from '../../assets/icons/flags/china-min.png';
import croatia from '../../assets/icons/flags/croatia-min.png';
import cyprus from '../../assets/icons/flags/cyprus-min.png';
import czechRepublic from '../../assets/icons/flags/czech-republic-min.png';
import denmark from '../../assets/icons/flags/denmark-min.png';
import estonia from '../../assets/icons/flags/estonia-min.png';
import france from '../../assets/icons/flags/france-min.png';
import germany from '../../assets/icons/flags/germany-min.png';
import greece from '../../assets/icons/flags/greece-min.png';
import hungary from '../../assets/icons/flags/hungary-min.png';
import india from '../../assets/icons/flags/india-min.png';
import ireland from '../../assets/icons/flags/ireland-min.png';
import israel from '../../assets/icons/flags/israel-min.png';
import italy from '../../assets/icons/flags/italy-min.png';
import jordan from '../../assets/icons/flags/jordan-min.png';
import latvia_lettonie from '../../assets/icons/flags/latvia_lettonie-min.png';
import lithuania from '../../assets/icons/flags/lithuania-min.png';
import luxembourg from '../../assets/icons/flags/luxembourg-min.png';
import malta from '../../assets/icons/flags/malta-min.png';
import monaco from '../../assets/icons/flags/monaco-min.png';
import netherlands from '../../assets/icons/flags/netherlands-min.png';
import norway from '../../assets/icons/flags/norway-min.png';
import pakistan from '../../assets/icons/flags/pakistan-min.png';
import poland from '../../assets/icons/flags/poland-min.png';
import portugal from '../../assets/icons/flags/portugal-min.png';
import romania from '../../assets/icons/flags/romania-min.png';
import serbia from '../../assets/icons/flags/serbia-min.png';
import slovakia from '../../assets/icons/flags/slovakia-min.png';
import slovenia from '../../assets/icons/flags/slovenia-min.png';
import spain from '../../assets/icons/flags/spain-min.png';
import sweden from '../../assets/icons/flags/sweden-min.png';
import switzerland from '../../assets/icons/flags/switzerland-min.png';
import turkey from '../../assets/icons/flags/turkey-min.png';
import ukraine from '../../assets/icons/flags/ukraine-min.png';
import unitedArabEmirates from '../../assets/icons/flags/united-arab-emirates-min.png';
import unitedKingdom from '../../assets/icons/flags/united-kingdom-min.png';
import unitedStates from '../../assets/icons/flags/united-states-min.png';

// const flags = [
//   australia,
//   austria,
//   bangladesh,
//   belgium,
//   bosniaAndHerzegovina,
//   bulgaria,
//   canada,
//   china,
//   croatia,
//   cyprus,
//   czechRepublic,
//   denmark,
//   estonia,
//   france,
//   germany,
//   greece,
//   hungary,
//   india,
//   ireland,
//   israel,
//   italy,
//   jordan,
//   latvia_lettonie,
//   lithuania,
//   luxembourg,
//   malta,
//   monaco,
//   netherlands,
//   norway,
//   pakistan,
//   poland,
//   portugal,
//   romania,
//   serbia,
//   slovakia,
//   slovenia,
//   spain,
//   sweden,
//   switzerland,
//   turkey,
//   ukraine,
//   unitedArabEmirates,
//   unitedKingdom,
//   unitedStates,
// ]; 
const flags = [
  { code: 'AU', flag: australia },
  { code: 'AT', flag: austria },
  { code: 'BD', flag: bangladesh },
  { code: 'BE', flag: belgium },
  { code: 'BA', flag: bosniaAndHerzegovina },
  { code: 'BG', flag: bulgaria },
  { code: 'CA', flag: canada },
  { code: 'CN', flag: china },
  { code: 'HR', flag: croatia },
  { code: 'CY', flag: cyprus },
  { code: 'CZ', flag: czechRepublic },
  { code: 'DK', flag: denmark },
  { code: 'EE', flag: estonia },
  { code: 'FR', flag: france },
  { code: 'DE', flag: germany },
  { code: 'GR', flag: greece },
  { code: 'HU', flag: hungary },
  { code: 'IN', flag: india },
  { code: 'IE', flag: ireland },
  { code: 'IL', flag: israel },
  { code: 'IT', flag: italy },
  { code: 'JO', flag: jordan },
  { code: 'LV', flag: latvia_lettonie },
  { code: 'LT', flag: lithuania },
  { code: 'LU', flag: luxembourg },
  { code: 'MT', flag: malta },
  { code: 'MC', flag: monaco },
  { code: 'NL', flag: netherlands },
  { code: 'NO', flag: norway },
  { code: 'PK', flag: pakistan },
  { code: 'PL', flag: poland },
  { code: 'PT', flag: portugal },
  { code: 'RO', flag: romania },
  { code: 'RS', flag: serbia },
  { code: 'SK', flag: slovakia },
  { code: 'SI', flag: slovenia },
  { code: 'ES', flag: spain },
  { code: 'SE', flag: sweden },
  { code: 'CH', flag: switzerland },
  { code: 'TR', flag: turkey },
  { code: 'UA', flag: ukraine },
  { code: 'AE', flag: unitedArabEmirates },
  { code: 'GB', flag: unitedKingdom },
  { code: 'US', flag: unitedStates },
];

export default flags;