import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  marginTop:'2px',
  marginRight: '4px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    marginTop: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(22px)',
      color: 'linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%)',
      '& + .MuiSwitch-track': {
        backgroundColor: '#DE84FF',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        color: 'linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%)',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: 'linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%)',
      border: '6px solid linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%)',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%)',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 10,
    backgroundColor: '#DE84FF',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));


export default function CustomizedSwitches(props: any) {
  const { checked, setChecked } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={<IOSSwitch
          checked={checked}
          onChange={() => setChecked(!checked)}
          sx={{ m: 1 }} />}
        label=""
      />
    </FormGroup>
  );
}