import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import FranceFlag from '../assets/icons/flags/france-min.png';
import USFlag from '../assets/icons/flags/united-states-min.png';
import i18n from '../i18n';
import { setLanguage } from '../store/Common/CommonReducer';
import { useAppDispatch } from '../store/hooks';

const LanguageSelector = () => {
  const language = localStorage.getItem('i18nextLng');
  const [flag, setFlag] = React.useState(language);
  const dispatch = useAppDispatch();
  const changeLanguage = (e : any) => {
    i18n.changeLanguage(e.target.value);
    dispatch(setLanguage(e.target.value));
    setFlag(e.target.value);    
  };
  React.useEffect(()=> {
    const lg = localStorage.getItem('i18nextLng');
    if (lg) {
      dispatch(setLanguage(lg));
    }
  }, []);
  return (
            <FormControl variant="standard">
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={changeLanguage}
              style={{ fontSize: '14px', marginLeft: '8px' }}
              value={flag}
              defaultValue={language}
              disableUnderline
            >
            <MenuItem value='fr-FR' key={1}>
                <img width='20' height='14' src={FranceFlag} alt="eng" />
                </MenuItem>
                <MenuItem value='en-US' key={2}>
                <img width='20' height='14' src={USFlag} alt="usa" />
                </MenuItem>
            </Select>
          </FormControl>    
  );
};

export default LanguageSelector;
