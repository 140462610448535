import { createTheme } from '@mui/material/styles';
import './App.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Jost',
  },  
  palette: {
    primary: {
      main: '#c122fa',
    },
    info: { main: '#f92077' },
    success: {
      main: '#5ed5c8',
    },
    text: {
      primary: '#22062c',
      secondary: '#545c84',
      disabled: '#afb5d1',
    },
    warning: {
      main: '#F38E70',
    },
  },
});

export default theme;