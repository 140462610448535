import * as React from 'react';
import { Formik } from 'formik';
import { Grid } from '@mui/material';
import PhotosDropZone from './DropZone/DropZone';
import DocumentsDropZone from './DropZone/DocumentDropZone';
export default function SimpleDialog(props: any) {
  const { setActiveStep, initialValues, setAttachmentdta } = props;
  console.log('initialValues', initialValues);
  const { formData, setFormData } = props;
  const [document, setDocument] = React.useState();
  let imagesList = initialValues.pictureUrls;
  const [didChange, setDidChange] = React.useState(false);
  return (
      <Formik
    enableReinitialize
    initialValues={{ pictureUrls: [],
      deletedPictures: [],
    }}
    onSubmit={async ( values ) => {
      setFormData({ ...formData, 
        deletedPictures: values.deletedPictures,
        pictures: values.pictureUrls,
      });
      setActiveStep(3);
    }}
  >
    {({
      handleSubmit,
      setFieldValue,
      values,
    //   setFieldValue,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item style={{ textAlign: 'start', padding:'30px' }} xs={6} md={6} lg={6}>
            <PhotosDropZone
            imagesList={imagesList}
            addPhoto={(value: any) => {
              setFormData({ ...formData, pictures:[...(formData.pictures || []), value] });
            }}
            setFormData={setFormData}
            formData={formData}
            setImagesList={(value: any) => setFieldValue('imagesList', value)}
            setFieldValue={setFieldValue}
            pictreUrls={values.pictureUrls}
            deleteOldPic={(value: any) => {
              setFormData({ ...formData, deletedPictures:[...values.deletedPictures, value] });
              setFieldValue('deletedPictures', [...values.deletedPictures, value]);
              setAttachmentdta({
                ...initialValues,
                pictureUrls: initialValues.
                  pictureUrls.filter((e: any) => e !== value) });
            }}
            />
            </Grid>
            <Grid item style={{ textAlign: 'start', padding:'30px' }} xs={6} md={6} lg={6}>
            < DocumentsDropZone
            didChange={didChange}
            setDidChange={setDidChange}
            document={document}
            setDocument={setDocument}
            formData={formData}
            setFormData={setFormData}
            attachmentUrls={initialValues.attachmentUrls}
            ></DocumentsDropZone>
            </Grid>
              </Grid>
          </form>
      );
    }
}
</Formik>
  );
}
