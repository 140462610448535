import { createSlice } from '@reduxjs/toolkit';
interface Favorites {
  list: any,
}

// Define the initial state using that type
const initialState: Favorites = {
  list: [],
};

export const favoritesSlice = createSlice({
  name: 'favorites',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFavorites: (state, payload: any) => {
      return {
        ...state,
        list: payload.payload,
      };
    },
  },
});

export const { setFavorites } = favoritesSlice.actions;

export default favoritesSlice.reducer;
