import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomSwitch from './Switch';

export default function SimpleAccordion(props: any) {
  const { children, title, noPadding, canBeHidden, checked, setChecked, color, isDefaultOpened } = props;
  const [opened, setOpened] = React.useState(false);
  React.useEffect(() => {
    if (isDefaultOpened === true) setOpened(true);
  }, [isDefaultOpened]);
  return (
      <Accordion
        expanded={opened}
        style={{ marginBottom: '30px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon
            onClick={() => setOpened(!opened)}
          />}
          onClick={() => setOpened(!opened)}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ backgroundColor: color,
            margin: 'auto',
            height: '50px',
            minHeight: '50px',
          }}
        >
          <Typography style={{ margin:'auto', fontSize: '18px', textTransform: 'uppercase', transform: !canBeHidden ? 'translateX(0px)' : 'translateX(30px)' }}>{title}</Typography>
          {canBeHidden && <CustomSwitch checked={checked} setChecked={setChecked} />}
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: '#FFF', padding:noPadding ? '0px' : '30px', border:'1px solid #CDE9E6' }}>
          {children}
        </AccordionDetails>
      </Accordion>
  );
}
