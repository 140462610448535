import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  InputAdornment,
} from '@mui/material';
import TextField from '../../../components/textField';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { resetPassword } from '../../../api/auth';
import SuccessDialog from '../../../components/SuccessDialog';
export default function SimpleDialog(props: any) {
  const { setOpen, open } = props;
  const [ openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle style={{ backgroundColor: '#F5F9F8' }}>
        <div style={{ textAlign: 'center', marginBottom:'30px' }}>{t('ForgottenPassword')}</div>
        {t('')}
        <div>
      </div>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#F5F9F8' }}>
      <Formik
    enableReinitialize
    initialValues={{
      email: '',
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string().email(t('emailValidation')).max(255).required(t('mandatoryRow')),
    })}
    onSubmit={async ( { email } ) => {
      await resetPassword({ email });
      setOpenSuccessDialog(true);
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
            <div style={{ padding:'16px' }}>
              <div>
            <TextField 
              size="small"
              id="email"
              label="Email"
              type="email "
              variant="outlined"
              error={Boolean(touched.email && errors.email)}
              autoFocus
              name="email"
              onChange={handleChange}
              value={values.email}  
              style={{ marginBottom:'8px' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                  minWidth: '250px',
                },
              }}
              InputProps={{
                style:{ backgroundColor: '#fff' },
                startAdornment: <InputAdornment position="start"><AccountCircleOutlinedIcon /></InputAdornment>,
              }}
            />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px' }}>
          <Button variant="contained" type="submit" style={{ fontWeight: 'bold' }}>
              {t('ResetPassword')}
              </Button>
              </div>
          </form>
      );
    }
}
</Formik>
          </DialogContent>
          <SuccessDialog
              title="email Sent"
              sentence={t('CheckInboxFP')}
              open={openSuccessDialog}
              setOpen={setOpenSuccessDialog}
              >
            </SuccessDialog>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
