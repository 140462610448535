import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import BootstrapInputBase from './borderedInputBase';

const FormikSelect = (props: any) => {
  const {
    name,
    setFieldValue,
    value,
    error,
    options,
    placeholder,
    style,
    multiple,
    maximum,
    id,
    labelColor,
  } = props;
  const [isFocused, setIsFocused] = React.useState(false);
  const onClick = (v: any) => {
    if (multiple) {
      if (value.indexOf(v) > -1) {
        setFieldValue(name, value.filter((e: any) => e !== v));
      } else if (value.length < maximum){
        setFieldValue(name, [...value, v]);
      } else {
        return;
      }
    } else {
      setFieldValue(name, v);
    }
  };
  return (
      <React.Fragment>
        <FormControl style={style}>
          <Select
          input={<BootstrapInputBase
            id={id}
            label={(isFocused || (!multiple && value) || (multiple && value.length !== 0 )) ? '' : placeholder}
            onFocus={ ()=> setIsFocused(true) }
            onBlur={ ()=> setIsFocused(false) }
            labelColor={labelColor}
            /> }
          value={name === 'languages' ? [] : value}
          multiple={multiple}
          label={placeholder}
          style={{ textAlign: 'initial', border: error ? '1px red solid' : 'none' }}
          >
            {options.map( ( e: any) => {
              const selected = multiple ? value.indexOf(e.value) > -1 : e.value === value;
              return <MenuItem
              disabled={e.disabled}
              style={selected ? { backgroundColor : 'rgba(193, 34, 250, 0.3)' } : {}}
              onClick={() => onClick(e.value)}
              key={e.value}
              value={e.value}
              >{e.label}</MenuItem>;
            })}
                </Select>
      </FormControl>
      </React.Fragment>
  );
};
FormikSelect.defaultProps = {
  multiple: false,
};
export default FormikSelect;