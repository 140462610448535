import * as React from 'react';
import ProductFormContainer from './ProductFormContainer';
// import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import './index.css';
export default function PoductAdd() {
  const account = useAppSelector(state => state.account);
  // const { t } = useTranslation();
  const [initialValues, setInitialValues] = React.useState<any>(null);
  React.useEffect(() => {
    if (account && account._id) {
      const {
        businessName,
        siret,
        tva,
        phone,
        website,
        streetNumber,
        city,
        zipCode,
        country,
        isBillingAddress,
        billingAddress,
        businessSector,
        firstName,
        lastName,
        mobile,
        role,
        accountType,
      } = account;
      setInitialValues({
        businessName,
        siret,
        tva,
        phone,
        website,
        streetNumber,
        city,
        zipCode,
        country,
        billingAddress,
        isBillingAddress,
        businessSector,
        firstName,
        lastName,
        mobile,
        role,
        isProfessional: accountType === 'Professionnel' ? 'on' : 'off',
      });
    }
  }, [account]);
  return <Grid container className="container-mydata">
      {/* <Grid container> */}
      <Grid item xs={12} sm={12} md={9} lg={9} >
        {initialValues &&
      <ProductFormContainer
      accountId={account._id}
      initialValues={initialValues}></ProductFormContainer>
      }
      </Grid>
    </Grid>;
}