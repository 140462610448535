import React from 'react';
import Check from '../../assets/check.png';
import './index.css';
import { useTranslation } from 'react-i18next';

const SellerPlan = (props: any) => {
  const { plans, checked, onModalOpen } = props;
  console.log('plans', plans);
  const planName = checked ? 'seller-yearly' : 'seller-monthly';
  const plan = plans.find((e: any) => e.name === planName);
  const amount = plan.amount;
  const { t } = useTranslation();
  const data = [1, 2, 3, 4, 5, 6, 7, 8];
  const period = checked ? 'year' : 'month';
  return <div>
    <div className='seller-header'>
    <div className='section-title'>
              {t('seller')}
            </div>
            <div className='section-price'>
              {amount}€
              <span className='price-postfix'>
                HT / {t(period)}
                  </span>
            </div>

          </div>
          <div className='subscription-section-subhead'>
              <div className='subscription-button-border'>
              <div
              onClick={() => onModalOpen(plan._id)}
              className='subscription-button'>
                  {t('Subscribe')}
                  </div>
                </div>
            </div>
            <div style={{ border: '1px solid #CDE9E6' }}>
            {data.map(() => <div className='row-buyer'>
                <img src={Check} height='19' width='26' />
            </div>)}
            </div>
            <div className='section-bottom'>
                <div style={{ height: '14px' }}>
                    </div>
            <div className='section-price' style={{ color: '#F82478', height: '55px' }}>
            {amount}€
              <span className='price-postfix' style={{ color: '#000' }}>
              HT / {t(period)}
                  </span>
            </div>
              <div
              onClick={() => onModalOpen(plan._id)}
              className='section-bottom-button' style={{
                backgroundColor: '#5ED5C8',
                borderBottom: 'none !important',
              }}>
                  {t('Subscribe')}
                </div>
            </div>
      </div>;
};

export default SellerPlan;