import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import DashboardLayout from './layouts/Dashboard';
import GuestLayout from './layouts/Guest';
import AuthGuard from './components/AuthGuard';
import InboxLayout from './layouts/Inbox';
import ScrollToTop from './hoc/ScrollToTop';

const AppRouter = () => (
  <Router>
    <Suspense fallback={<div>Loading...</div>}>
    <AuthGuard>
    <ScrollToTop >
      <Routes>
        <Route path="/dashboard/*"  element={<DashboardLayout />} />
        <Route path="/messages/*"  element={<InboxLayout />} />
        <Route path="/*"  element={<GuestLayout  />} />
      </Routes>
      </ScrollToTop>
      </AuthGuard>
    </Suspense>
  </Router>
);

export default AppRouter;
