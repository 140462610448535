import React from 'react';
import { useAppSelector } from '../../store/hooks';
// import { useTranslation } from 'react-i18next';

const MessageLine = (props: any) => {
  const account = useAppSelector(state => state.account);
  // const { t } = useTranslation();
  const accountId = account._id;
  const { message, author, attachment } = props;
  const getFileName = (fname:string) => {
    const splitted = fname.split('/');
    const name = splitted[splitted.length - 1];
    const splName = name.split('-');
    const extensionT = name.split('.');
    const extension = (extensionT && extensionT.length > 0 ) ? '.' + extensionT[extensionT.length - 1] : ''; 
    const sname = splName.slice(0, splName.length - 1);
    return `${sname.join('')}${extension}`;
  };
  return <div style={{
    width: '100%',
    display: 'flex',
    justifyContent: (author === accountId) ? 'end' : 'start',
  }}>
  <div className={(author === accountId) ? 'message-line-owner' : 'message-line-other' } >
      { message }
      {attachment &&
      <div>
        <a href={attachment} download={attachment} style={{ color: (author === accountId) ? '#fff' : '#c122fa' }}>
          {/* {t('attachment')} */}
          {getFileName(attachment)}
          </a>
        </div>
        }
      </div>
    </div>;
};

export default MessageLine;
