import * as React from 'react';
import { Typography } from '@mui/material';
import DescriptionForm from './DescripionForm';
import AccordionElement from '../../../components/AccordionElement';
import QuantityPriceForm from './QuantityPrceForm';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import AttachementForm from './AttachementForm';
// import PropmotionForm from './PromoteForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck,
  // faSave 
} from '@fortawesome/free-solid-svg-icons';
import { postProduct } from '../../../api/product';
import LoadingDialog from '../../../components/loadingDialog';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';


export default function PoductAdd(props: any) {
  const { setActiveStep, activeStep } = props;
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [formData, setFormData ] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation();
  const createProduct = async () => {
    try {
      setIsLoading(true);
      const { data } = await postProduct(formData);
      setIsLoading(false);
      navigate('/annonce/' + data._id);
      enqueueSnackbar(t('Offerpublished'), {
        variant: 'success',
      });
    } catch (err) {
      setIsLoading(false);
      enqueueSnackbar(t('error'), {
        variant: 'error',
      });
    }
  };
  return <div>
    <Typography variant="h4" color="primary" style={{ margin:'30px 0px 30px 0px' }}>{t('NewAdd')}</Typography>
    <AccordionElement active={activeStep === 0}  canBeExpanded={activeStep > 0} title={t('Description')}>
    <DescriptionForm formData={formData} setFormData={setFormData} setActiveStep={setActiveStep}></DescriptionForm>
    </AccordionElement>
    <AccordionElement active={activeStep === 1} canBeExpanded={activeStep > 1} noPadding whiteFooter title={t('QuantityPrice')}>
    <QuantityPriceForm formData={formData} setFormData={setFormData} setActiveStep={setActiveStep}></QuantityPriceForm>
    </AccordionElement>
    <AccordionElement  active={activeStep === 2} canBeExpanded={activeStep > 2} setActiveStep={setActiveStep} title={t('PicturesAttachment')}>
      <AttachementForm formData={formData} setFormData={setFormData} setActiveStep={setActiveStep} />
    </AccordionElement>
    {/* <AccordionElement active={activeStep === 3} canBeExpanded={activeStep > 3} title={t('Sponsor')}>
      <PropmotionForm formData={formData} setFormData={setFormData} />
    </AccordionElement> */}
    <div style={{ display: 'flex', width:'100%', flexBasis:'center', justifyContent: 'center', marginTop:'32px',  alignItems: 'baseline'  }}>
          <Button
           onClick={createProduct}
           disabled={ activeStep !== 3 } 
           variant="contained"
           type="submit"
           style={{ width:'27%', marginBottom:'100px', height:'60px', fontSize:'18px' }}>
          <FontAwesomeIcon icon={faCheck} style={{ paddingRight: '8px' }} />
              {t('Post')}
              </Button>
              {/* <Button variant="contained" type="submit" style={{ width:'20%', backgroundColor: '#5CD6CB', marginBottom:'100px', height:'40px', fontSize:'16px' }}>
              <FontAwesomeIcon icon={faSave} style={{ paddingRight: '8px' }} />
              {t('Draft')}
              </Button> */}
              </div>
              <LoadingDialog open={isLoading} />
  </div>;
}