import React from 'react';
import ProductCard from '../../components/productCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OtherOffers = (props: any) => {
  const { otherOffersData } = props;
  const { t } = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);
  const scrollRight = () => {
    if (ref && ref.current) {
      ref.current.scrollLeft += 810;
    }
  };
  const scrollLeft = () => {
    if (ref && ref.current) {
      ref.current.scrollLeft -= 810;
    }
  };

      
  return <div className='other-offers'>
      <div className = 'other-offers-title'>
      {t('MoreSellerOffers')}
      </div>
      <div style={{ display: 'flex', margin: 'auto' }}>

            <div onClick={() => scrollLeft()} style={{ width: '10%' }}>
            <FontAwesomeIcon
              style={{
                position: 'relative',
                height: '20px',
                width: '20px',
                top: '50%',
                cursor: 'pointer',
              }} color='#000' icon={faChevronLeft} />
            </div>
      <div ref={ref} id='cards-container'>
        {
          otherOffersData.map( (e:any) => <ProductCard data={e}/>)
        }
        </div>
        <div onClick={() => scrollRight()} style={{ width: '10%' }}>
            <FontAwesomeIcon
             style={{
               position: 'relative',
               height: '20px',
               width: '20px',
               top: '50%',
               cursor: 'pointer',
             }}
            color='#000' icon={faChevronRight} />
            </div>
        </div>
</div>;
};

export default OtherOffers;