import React from 'react';
import MostVisited from './MostVisited';
import NewAnnounceIcon from '../../assets/icons/icon_new_announce';
import HomePageButton from '../../components/HomePageButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const buttonData = {
    label: t('CreateNewOffer'),
    color: '#F6256D',
    Icon: NewAnnounceIcon,
    action: () => navigate('/dashboard/annonce/form'),
  };
  return <div className='dashboard-container'>
          <MostVisited />
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: '20px' }}>
          <HomePageButton {...buttonData} />
  </div>
  </div>;
};

export default Dashboard;