import axios from 'axios';

export const fetchAccount = async () => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/account`, { headers: {
    Authorization: `Bearer ${accessToken}`,
  } },
  );
  return res;
};

export const updateAccount = async (role: string, id: string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${id}`, {
    role,
  }, { headers: {
    Authorization: `Bearer ${accessToken}`,
  } },
  );
  return res;
};

export const updateUser = async (data: any, id: string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/users/${id}`, data,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    } },
  );
  return res;
};

export const verifyEmail = async (token: any) => {
  const res = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/verify-email?key=${token}`, {},
  );
  return res;
};
