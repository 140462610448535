import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)({
  borderRadius: '0px 0 0 0px',
  fontSize: '11px',
  '&.Mui-disabled': {
    backgroundColor: '#DBAAEE',
    color: '#fff',
  },
});
  
export default CustomButton;