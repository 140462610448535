import React from 'react';
import './index.css';
import ConversationLine from './ConversationLine';
// import { TextField } from '@mui/material';
import { faPaperPlane, faPaperclip, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { putMessage } from '../../api/messaging';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';

const ConversationContainer = (props: any) => {
  const { t } = useTranslation();
  const windowHeight = window.innerHeight;
  const { headerHeight, conversationData, noContent, selectedConversation, isLoading } = props;
  const [messageValue, setMessageValue] = React.useState('');
  const [messagesData, setMessagesData] = React.useState<any>([]);
  const [attachment, setAttachment] = React.useState<any>(null);
  React.useEffect(() => {
    setMessagesData(conversationData);
    return () => { setMessagesData([]); };
  }, conversationData);
  const sendMsg = async () => { 
    try {
      console.log('conversationData', conversationData);
      const msg = await putMessage(messageValue, selectedConversation, attachment);
      setMessageValue('');
      setAttachment(null);
      setMessagesData([...messagesData, msg]);
    } catch (e) {
      console.log('e', e);
    }
  };
  const hh = windowHeight - parseInt(headerHeight) - 250;
  console.log('hh', hh);
  return <div> 
  <div
    className='conversation-container'
    style={{
      maxHeight: hh,
      height: hh,
      overflowY: 'auto',
    }}
    >
    { !isLoading && !noContent && messagesData && messagesData.length > 0 && messagesData.map((e: any, index: number) => <ConversationLine message={e.content} index={index} {...e} />)}
    { isLoading && <div style={{ marginTop: '100px' }}><CircularProgress /></div>}
    </div>
    <div className='send-message'>
      {/* <TextField value={messageValue} onChange={(e:any) => setMessageValue(e.target.value)}/> */}
          <div className='send-message-bar'>
          <input
          placeholder={t('Writeyourmessage')}
          className='input-message' type="text"
          value={messageValue}
          onChange={(e:any) => setMessageValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendMsg();
            }
          } }
          />
          <label style={{
            borderRadius: '20px',
            height: '38px',
            width: '190px',
            backgroundColor: '#fff',
            border:'1px #CDE9E6 solid',
          }}>
            {!attachment &&
            <React.Fragment>
          <FontAwesomeIcon
            icon={faPaperclip}
            style={{ cursor: 'pointer',
              transform: 'translate(-8px, 6px)',
              height: '23px',
              marginTop: '4px',
              
            }}
            color='#5ED5C8'
            />
            <input
            onChange={(e: any) => { setAttachment(e.target.files[0]);} }
            type="file" id="upload" style={{ display: 'none' }}/>
            <span  style={{ fontSize: '16px', cursor: 'pointer', color: '#5ED5C8', marginTop: '4px', marginLeft: '2px' }}
            >{t('AttachFile')}</span>

            </React.Fragment>
            }
            {
              attachment && 
              <div style={{ fontSize: '16px', color: '#5ED5C8', marginTop: '2px', marginLeft: '2px' }}>
                <span>
                { attachment.name.length > 20 ? `${attachment.name.substring(0, 20)}...` : attachment.name }
                </span>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ cursor: 'pointer',
                    transform: 'translate(-8px, 6px)',
                    height: '23px',
                    marginLeft: '8px',
                  }}
                  color='#5ED5C8'
                  onClick={(e: any) => { setAttachment(null); e.preventDefault(); }}
                  />
                </div>
            }
            </label>
          </div>
      <div style={{
        height: '38px',
        width: '38px',
        marginTop: '22px',
        marginLeft: '16px',
        borderRadius: '50%',
        backgroundColor: '#C122FA',
      }}>
      <FontAwesomeIcon
      icon={faPaperPlane}
      style={{ cursor: 'pointer', transform: 'translate(-2px, 8px)' }}
      color='#fff'
      onClick={sendMsg}
      />
      </div>
        </div>
    </div>;
};

export default ConversationContainer;