import React, { Suspense } from 'react';
import { Box } from '@mui/material';
import MainLayout from '../MainLayout';
import { useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import Inbox from '../../views/Inbox';

const AppRouter = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.user);

  React.useEffect(() => {
    if ( !isAuthenticated ) {
      navigate('/');
    }
  }, [isAuthenticated]);
  return (
    <MainLayout noSearch>
      <Box sx={{ flexGrow: 1, display:{ xs: 'none', sm:'none', md:'none', lg:'inline' } }} >
      </Box>
      <Suspense fallback={<div>Loading...</div>}>
        { isAuthenticated && <Inbox />}
      </Suspense>
    </MainLayout>
  );
};

export default AppRouter;
