import React, { useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { reset } from '../../store/Error/errorReducer';

const ErrorBoundary = (props) => {
  const dispatch = useAppDispatch();
  const { uiError } = useAppSelector((state) => state.error);
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);
  if (uiError) {
    // You can render any custom fallback UI
    return <h1>Something went wrong.</h1>;
  }

  return props.children; 
};
export default ErrorBoundary;