import React from 'react';
import './index.css';
import Rating from '@mui/material/Rating';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { fetchNotesAnalytics } from '../../../api/analytics';
import { useAppSelector } from '../../../store/hooks';
import { Grid } from '@mui/material';




const StyledRating = styled(Rating)({
  '& .Mui-disabled': {
    opacity: 1,
  },
});
  
const Evaluations = (props: any) => {
  const { t } = useTranslation();
  const [analytics, setAnalytics] = React.useState<any>(null);
  const { _id: userId } = useAppSelector(state => state.account);
  const { color } = props;
  const fetchAnalytics = async (a: string) => {
    const data = await fetchNotesAnalytics(a);
    setAnalytics(data);
  };
  React.useEffect(() => {
    if (userId) {
      fetchAnalytics('received');
    }
  }, [userId]); 
  const pratings = [
    { label: t('Excellent'), value: 5 },
    { label: t('Good'), value: 4 },
    { label: t('Okay'), value: 3 },
    { label: t('Disappointing'), value: 2 },
    { label: t('VeryDisappointing'), value: 1 },
  ];
  return <Grid container>
    <Grid item xs={12} md={6}>
          {analytics && <React.Fragment><div style={{
            fontSize: '20px',
            textTransform: 'uppercase',
            color: '#21062E',
            marginBottom: '30px',
          }}>
              {t('GlobalRating')}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
                  {!!analytics && (analytics.globalNote || 0)} / 5
                  </div>
                  <div style={{ width: '30px' }}>
                      </div>
              <div style={{ marginTop: '6px' }}>
              <StyledRating
                    readOnly
                    name="customized-color"
                    value={analytics.globalNote || 0}
                    precision={0.1}
                    icon={<FontAwesomeIcon color={color} icon={faSolidStar} />}
                    emptyIcon={<FontAwesomeIcon color={color} icon={faStar}  />}
                />
                  </div>
              </div>
              <div style={{
                fontSize: '18px',
                textTransform: 'uppercase',
                color: '#21062E',
                marginTop: '25px',
              }}>{analytics.notesCount} {t('ratings')}</div>
              </React.Fragment>
              }
        </Grid>
        <Grid item xs={12} md={4}>
              <div>
                {
                  analytics && analytics.notes && analytics.notes.length > 0 &&
                  pratings.map((e: any) => {
                    const nt = analytics.notes.find((s: any) => s.note === e.value);
                    let val = nt ? nt.count : 0;
                    return  <Grid container xs={12} md={12}>
                  <Grid item xs={6} md={6} style={{ textAlign: 'start' }}>
                      {e.label}
                    </Grid>
                    <Grid item xs={6} md={6} style={{ textAlign: 'end' }}>
                  {val}
                  </Grid>
                  </Grid>;
                  },
                  )
                }
              </div>
              </Grid>
  </Grid>;
};
export default Evaluations;