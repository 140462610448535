import React from 'react';
import './index.css';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  resetPrice,
  resetMinQuantity,
  setSellerRating,
} from '../../store/Search/searchReducer';
import i18n from '../../i18n';
import SellerRatingChip from './SellerRatingChip';
const OffersList = (props: any) => {
  const { tagsList, priceRange, minQuantity, navigate } = props;
  const { filters } = useAppSelector(state => state.search);
  const { categories } = useAppSelector(state => state.common);
  const { sellerRating } = filters;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onDelete = (data: string[], elementToDelete: string, action: any) => {
    const nV = data.filter( e => e !== elementToDelete);
    dispatch(action({ values: nV, navigate }));
  };
  const { language } = i18n;
  const getLabel = (translationName: string, element: any, filter: any) => {
    if (filter.translationName === 'categories') {
      const s = categories.find(e => e._id === element);
      const lg: any = language.substr(0, 2);
      if (s) {
        return s.name[lg];
      }
    }
    return t(filter.translationName, { returnObjects: true })[element];
  };
  return <div>
        { priceRange && <Chip
              style={{
                height: '29px',
                color: '#fff',
                backgroundColor: '#63D4C7',
                marginRight: '8px',
                marginBottom: '8px',
                fontSize: '14px',
              }}
              label={priceRange}
              deleteIcon={<FontAwesomeIcon icon={faTimes} style={{
                height:'14px',
                width: '14px',  
                color: '#fff',
                marginRight: '8px',
              }}
              />}
              onDelete={() => {
                dispatch(resetPrice({ navigate }));
              } }
          />  
    }{minQuantity && <Chip
            style={{
              height: '29px',
              color: '#fff',
              backgroundColor: '#63D4C7',
              marginRight: '8px',
              marginBottom: '8px',
              fontSize: '14px',
            }}
            label={minQuantity}
            deleteIcon={<FontAwesomeIcon icon={faTimes} style={{
              height:'14px',
              width: '14px',  
              color: '#fff',
              marginRight: '8px',
            }}
            />}
            onDelete={() => dispatch(resetMinQuantity({ navigate })) }
            />
      }
    {
      sellerRating > -1 && <Chip
      style={{
        height: '29px',
        color: '#fff',
        backgroundColor: '#63D4C7',
        marginRight: '8px',
        marginBottom: '8px',
        fontSize: '14px',
      }}
      label={<SellerRatingChip></SellerRatingChip>}
      deleteIcon={<FontAwesomeIcon icon={faTimes} style={{
        height:'14px',
        width: '14px',  
        color: '#fff',
        marginRight: '8px',
      }}
      />}
      onDelete={() => dispatch(setSellerRating({ values: -1, navigate })) }
      />
    }
    {tagsList.filter((e: any) => !!e && !!e.data && e.data.length > 0).map((filter: any, index: number) => filter.data.map((el: any) => (
        <Chip
            style={{
              height: '29px',
              color: '#fff',
              backgroundColor: '#63D4C7',
              marginRight: '8px',
              marginBottom: '8px',
              fontSize: '14px',
            }}
            key={index}
            label={getLabel(filter.translationName, el, filter)}
            deleteIcon={<FontAwesomeIcon icon={faTimes} style={{
              height:'14px',
              width: '14px',  
              color: '#fff',
              marginRight: '8px',
            }}
            />}
            onDelete={() => onDelete(filter.data, el, filter.action) }
        />
    ),
    ) )}
  </div>;
};
export default OffersList;