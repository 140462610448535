import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FilledStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { useAppSelector } from '../../store/hooks';

const OffersList = () => {
  const { filters } = useAppSelector(state => state.search);
  const { sellerRating } = filters;
  return <div style={{ display: 'flex' }}>
      {[0, 1, 2, 3, 4].map((e, index: any) =><FontAwesomeIcon
            icon={index <= sellerRating ? FilledStar : EmptyStar}
            color='#C221FA'
            style={{
              height: '15px',
              width: '15px',
              paddingRight: '4px',
            }}
            key={e}
        />)
      }

  </div>;
};
export default OffersList;