import * as React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import TextField from '../../../components/textField';
// import SwitchButton from '../../../components/FormikSwitchButton';
import Checkbox from '../../../components/CheckBox';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik,
  /*Field*/ } from 'formik';
import flags from '../../Home/ProductForm/Flags';
import FormikSelect from '../../../components/FormikSelect';
import CheckboxGroup from '../../../components/CheckboxGroup';
// import SwitchButton from '../../../components/FormikSwitchButton';


export default function SimpleDialog( props: any) {
  
  const { collectedFormsValue, setCollectedFormsValues, update, userId } = props;
  const { t } = useTranslation();
  const categories : any = t('categories', { returnObjects: true });
  const categoiesValues = Object.entries(categories).map(([key, value]) => ({ label: value, value: key }));
  let isBillingAddr = false;
  let billAdress = {};
  if (collectedFormsValue && collectedFormsValue.billingAddress ) {
    isBillingAddr = !collectedFormsValue.isBillingAddress;
    const {
      billingStreetNumber,
      billingCity,
      billingZipCode,
      billingBusinessName,
      billingCountry,
    } = collectedFormsValue.billingAddress;
    billAdress = {
      billingStreetNumber,
      billingCity,
      billingZipCode,
      billingBusinessName,
      billingCountry,
    };
  }

  const CountriesTranslations = t('CountriesList', { returnObjects: true });
  let countriesobjs = Object.entries(CountriesTranslations);
  countriesobjs.sort((cata: any, catb: any) => {
    const textA = cata[1].toUpperCase();
    const textB = catb[1].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
  });
  const countriesList = countriesobjs.map(([key, value]) => {
    const flag = flags.find(e => e.code === key);
    return ({
      label:(<div style={{ display: 'flex' }}>
        <img height='24' width='30' style={{ marginRight: '10px' }} src ={flag ? flag.flag : '' }/><span>{String(value)}</span></div>
      ),
      value: key,
    }
    );
  });
  // const whenProfessionalValidation = Yup.string().when('isProfessional', {
  //   is: 'on',
  //   then: Yup.string().required(t('mandatoryRow')),
  // });
  // const whenIndividualValidation = Yup.string().when('isProfessional', {
  //   is: 'off',
  //   then: Yup.string().required(t('mandatoryRow')),
  // });
  // const whenDifferentInvoiceAdressValidation = Yup.string().when('isBillingAddress', {
  //   is: true,
  //   then: Yup.string().required(t('mandatoryRow')),
  // });
  const textFieldStyle = {
    marginBottom:'30px',
    width:'96%',
  };
  const textFieldInputProps = {
    style: {
      height: '30px',
      backgroundColor: '#fff',
      padding: '4px 0px 4px 8px',
      minWidth: '250px',
    },
  };
  return (
    <Formik
    enableReinitialize
    initialValues={{  ...collectedFormsValue, ...billAdress, isBillingAddress: isBillingAddr }}
    validationSchema={Yup.object().shape({
      // isProfessional: Yup.string().max(255),
      // isBillingAddress: Yup.bool(),
      // civility: whenIndividualValidation,
      // lastName: Yup.string().required(t('mandatoryRow')),
      // firstName: Yup.string().required(t('mandatoryRow')),
      // businessName: whenProfessionalValidation,
      // siret: whenProfessionalValidation,
      // tva: Yup.string().max(255),
      // phone: Yup.string().max(255).required(t('mandatoryRow')),
      // mobile: Yup.string().max(255),
      // website: Yup.string().max(255),
      // streetNumber: Yup.string().max(255).required(t('mandatoryRow')),
      // city: Yup.string().max(255).required(t('mandatoryRow')),
      // zipCode: Yup.string().max(255).required(t('mandatoryRow')),
      // billingStreetNumber: whenDifferentInvoiceAdressValidation,
      // billingCity: whenDifferentInvoiceAdressValidation,
      // billingZipCode: whenDifferentInvoiceAdressValidation,
      // billingBusinessName: whenDifferentInvoiceAdressValidation,
    })}
    onSubmit={(values, { resetForm }) => {
      const accountType = values.isProfessional === 'on' ? 'Professionnel' : 'Particulier';
      const {
        civility,
        lastName,
        firstName,
        businessName,
        siret,
        tva,
        phone,
        website,
        streetNumber,
        city,
        zipCode,
        country,
        billingAddress,
        businessSector,
        isProfessional,
      } = values;
      const isBillingAddress = !values.isBillingAddress;
      const data = {
        isBillingAddress,
        civility,
        lastName,
        firstName,
        businessName,
        siret,
        tva,
        phone,
        website,
        streetNumber,
        city,
        zipCode,
        country,
        billingAddress,
        businessSector,
        accountType,
        isProfessional,
      };
      setCollectedFormsValues({ ...collectedFormsValue, ...data });
      update( { ...collectedFormsValue, ...data }, userId);
      resetForm();
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
        <div style={{ }}>
            <div style={{ justifyContent: 'center', marginTop: '30px', backgroundColor: '#F5FBFB', width: '100%' }}>
            <Grid style={{ justifyContent: 'center' }} container>
            <Grid item xs={12}  style={{ margin: '0px 12px 12px 12px' }}>
            {/* <Field
                fieldName="isProfessional"
                component={SwitchButton}
                setFieldValue={setFieldValue}
                value={values.isProfessional}
                style={{ justifyContent: 'center', marginBottom: '24px' }}
                beforeText="Particulier"
                afterText="Professionel" /> */}
              </Grid>
            {values.isProfessional === 'on' && <React.Fragment>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="businessName"
              error={Boolean(touched.businessName && errors.businessName)}
              helperText={touched.businessName && errors.businessName}
              name="businessName"
              value={values.businessName}
              onChange={handleChange}
              label={`${t('CompanyName')}*`}
              variant="outlined"
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="SIRET"
              error={Boolean(touched.siret && errors.siret)}
              helperText={touched.siret && errors.siret}
              name="siret"
              value={values.siret}
              onChange={handleChange}
              label={`${t('SIRET')}*`}
              variant="outlined"
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="tva"
              label={t('VATNUMBER')}
              variant="outlined"
              error={Boolean(touched.tva && errors.tva)}
              helperText={touched.tva && errors.tva}
              name="tva"
              value={values.tva}
              onChange={handleChange}
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            </React.Fragment>
            }
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="PhoneNumber1"
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              name="phone"
              value={values.phone}
              onChange={handleChange}
              label={`${t('PhoneNumber1')}*`}
              variant="outlined"
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="Website"
              label={t('Website')}
              variant="outlined"
              error={Boolean(touched.website && errors.website)}
              helperText={touched.website && errors.website}
              name="website"
              value={values.website}
              onChange={handleChange}
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="streetNumber"
              error={Boolean(touched.streetNumber && errors.streetNumber)}
              helperText={touched.streetNumber && errors.streetNumber}
              name="streetNumber"
              value={values.streetNumber}
              onChange={handleChange}
              label={`${t('BusinessAdress')}*`}
              variant="outlined"
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="City"
              label={`${t('City')}*`}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              name="city"
              value={values.city}
              onChange={handleChange}
              variant="outlined"
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="ZipCode"
              label={`${t('ZipCode')}*`}
              variant="outlined"
              helperText={touched.zipCode && errors.zipCode}
              error={Boolean(touched.zipCode && errors.zipCode)}
              name="zipCode"
              value={values.zipCode}
              onChange={handleChange}
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px', width: '4px' }}>
                      <FormikSelect
                        name="country"
                        value={values.country}
                        placeholder={t('Country')}
                        style={{ maxWidth: '97%', width: '100%', borderRadius: 0, textAlign: 'start' }}
                        error={Boolean(touched.country && errors.country)}
                        setFieldValue={setFieldValue}
                        options={countriesList}
                      />

            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }} />
            </Grid>
              </div>
          </div>
          <div style={{ justifyContent: 'center', backgroundColor: '#fff', width: '100%' }}>
            <div style={{ fontSize: '16px', paddingTop: '15px' }}>
            DONNÉES DE FACTURATION
              </div>
              <div style={{ margin: '10px 0 10px 0', width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Checkbox
              setFieldValue={setFieldValue}
              fieldName="isBillingAddress"
              value={values.isBillingAddress}
              label="l'adresse de facturation est differente"
              />
              </div>
          {values.isBillingAddress && 
                  <div>
                  <Typography style={{ padding:'16px 0 16px 0' }} variant='h6'>
                    Adresse de facturation
                    </Typography>
                    <form style={{ padding:'16px', justifyContent: 'center' }}>
                    <Grid style={{ justifyContent: 'center' }} container>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="billingBusinessName"
                      name="billingAddress.billingBusinessName"
                      value={values.billingAddress.billingBusinessName}
                      error={Boolean(touched.billingBusinessName && errors.billingBusinessName)}
                      helperText={touched.billingBusinessName && errors.billingBusinessName}
                      onChange={handleChange}
                      label={`${t('CompanyName')}*`}
                      variant="outlined"
                      style={textFieldStyle}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="billingStreetNumber"
                      name="billingAddress.billingStreetNumber"
                      value={values.billingAddress.billingStreetNumber}
                      onChange={handleChange}
                      label={`${t('BusinessAdress')}*`}
                      variant="outlined"
                      style={textFieldStyle}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="City"
                      name="billingAddress.billingCity"
                      value={values.billingAddress.billingCity}
                      error={Boolean(touched.billingCity && errors.billingCity)}
                      helperText={touched.billingCity && errors.billingCity}
                      onChange={handleChange}
                      label={`${t('City')}*`}
                      variant="outlined"
                      style={textFieldStyle}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="billingZipCode"
                      name="billingAddress.billingZipCode"
                      value={values.billingAddress.billingZipCode}
                      error={Boolean(touched.billingZipCode && errors.billingZipCode)}
                      helperText={touched.billingZipCode && errors.billingZipCode}
                      onChange={handleChange}
                      label={`${t('ZipCode')}*`}
                      variant="outlined"
                      style={textFieldStyle}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <FormikSelect
                        name="billingAddress.billingCountry"
                        value={values.billingAddress.billingCountry}
                        placeholder={t('Country')}
                        style={{ maxWidth: '97%', width: '100%', borderRadius: 0, textAlign: 'start' }}
                        error={Boolean(touched.billingCountry && errors.billingCountry)}
                        setFieldValue={setFieldValue}
                        options={countriesList}
                      />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }} />
                    </Grid>
                      </form>
                  </div>        
          }</div>
        <div style={{ backgroundColor: '#F5F9F8', border:'1px #e9efef solid', textAlign: 'center', padding:'16px' }}>
          <Typography style={{ padding:'16px 0 16px 0' }} variant='h6'>
            {t('activitiesQuestion')}
            </Typography>
            <div style={{ display: 'flex' }}>
              <div style={{ height: '100%' }}/>
              <div style={{ width: '20%' }}/>
            <Grid container style={{ justifyContent: 'start' }}>
            <CheckboxGroup noGrid values={values.businessSector} fieldName="businessSector" data={categoiesValues} setFieldValue={setFieldValue} />
            </Grid>
            <div style={{ width: '15%' }}/>
          </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px', marginBottom: '15px' }}>

            <Button variant="contained" type="submit">
            {t('Submit')}
              </Button>
              </div>
          </form>
      );
    }}
        </Formik>
  );
}
