import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  var lg = localStorage.getItem('i18nextLng');
  const { t } = useTranslation();
  React.useEffect(()=> {
    lg = localStorage.getItem('i18nextLng');
  }, [t]);

  var htmlStringEN = `<div style="text-align: left; padding-left: 10vw; padding-right: 10vw;"><p style="margin-top:0in;margin-right:0in;margin-bottom:5.0pt;margin-left:0in;text-align:center;font-size:30px;font-family:Jost;color:#C122FA;font-weight:bold;"><span style="color: rgb(193, 34, 250);">Privacy Policy</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Jost",sans-serif;'><span style="font-family:Jost;">&nbsp;</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">www.izi-place.com website is owned by GRIBYS, which is a data controller of your personal data.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">We have adopted this Privacy Policy, which determines how we are processing the information collected by www.izi-place.com, which also provides the reasons why we must collect certain personal data about you. Therefore, you must read this Privacy Policy before using www.izi-place.com website.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">We take care of your personal data and undertake to guarantee its confidentiality and security.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Personal information we collect:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">When you visit the www.izi-place.com, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the installed cookies on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products you view, what websites or search terms referred you to the Site, and how you interact with the Site. We refer to this automatically-collected information as &ldquo;Device Information.&rdquo; Moreover, we might collect the personal data you provide to us (including but not limited to Name, Surname, Address, payment information, etc.) during registration to be able to fulfill the agreement.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Why do we process your data?</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Our top priority is customer data security, and, as such, we may process only minimal user data, only as much as it is absolutely necessary to maintain the website. Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding website usage. This statistical information is not otherwise aggregated in such a way that it would identify any particular user of the system.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">You can visit the website without telling us who you are or revealing any information, by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the website&rsquo;s features, or you wish to receive our newsletter or provide other details by filling a form, you may provide personal data to us, such as your email, first name, last name, city of residence, organization, telephone number. You can choose not to provide us with your personal data, but then you may not be able to take advantage of some of the website&rsquo;s features. For example, you won&rsquo;t be able to receive our Newsletter or contact us directly from the website. Users who are uncertain about what information is mandatory are welcome to contact us via contact@izi-place.com.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Your rights:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">If you are a European resident, you have the following rights related to your personal data:</span></p>
  <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right to be informed.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right of access.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right to rectification.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right to erasure.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right to restrict processing.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right to data portability.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">The right to object.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Rights in relation to automated decision-making and profiling.</span></li>
  </ul>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">If you would like to exercise this right, please contact us through the contact information below.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Additionally, if you are a European resident, we note that we are processing your information in order to fulfill contracts we might have with you (for example, if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information might be transferred outside of Europe, including Canada and the United States.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Links to other websites:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Our website may contain links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for such other websites or third parties&apos; privacy practices. We encourage you to be aware when you leave our website and read the privacy statements of each website that may collect personal information.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Information security:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We keep reasonable administrative, technical, and physical safeguards to protect against unauthorized access, use, modification, and personal data disclosure in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Legal disclosure:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Contact information:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal Information, you may send an email to contact@izi-place.com.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Jost",sans-serif;'><span style="font-family:Jost;">&nbsp;</span></p></div>`;

  var htmlStringFR = `<div style="text-align: left; padding-left: 10vw; padding-right: 10vw;"><p style="margin-top:0in;margin-right:0in;margin-bottom:5.0pt;margin-left:0in;text-align:center;font-size:30px;font-family:Jost;color:#C122FA;font-weight:bold;"><span style="color: rgb(193, 34, 250);">Politique de confidentialité</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Jost",sans-serif;'><span style="font-family:Jost;">&nbsp;</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Le site www.izi-place.com est la propriété de GRIBYS, qui est le responsable du traitement de vos données personnelles.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Nous avons adopté la présente Politique de confidentialité, qui détermine la manière dont nous traitons les informations collectées par www.izi-place.com, qui fournit également les raisons pour lesquelles nous devons collecter certaines données personnelles vous concernant. Par conséquent, vous devez lire cette politique de confidentialité avant d'utiliser le site web www.izi-place.com.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Nous prenons soin de vos données personnelles et nous nous engageons à en garantir la confidentialité et la sécurité.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Les informations personnelles que nous recueillons :</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Lorsque vous visitez le site www.izi-place.com, nous recueillons automatiquement certaines informations sur votre appareil, notamment des informations sur votre navigateur Web, votre adresse IP, votre fuseau horaire et certains des cookies installés sur votre appareil. En outre, lorsque vous naviguez sur le site, nous recueillons des informations sur les pages Web ou les produits individuels que vous consultez, les sites Web ou les termes de recherche qui vous ont renvoyé au site et la façon dont vous interagissez avec le site. Nous désignons ces informations collectées automatiquement par le terme "informations sur les appareils". En outre, nous pourrions recueillir les données personnelles que vous nous fournissez (y compris, mais sans s'y limiter, le nom, le prénom, l'adresse, les informations de paiement, etc.) lors de l'inscription afin de pouvoir remplir le contrat.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Pourquoi utilisons-nous ces données ?</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Notre priorité absolue est la sécurité des données des clients et, à ce titre, nous ne pouvons traiter que des données minimales sur les utilisateurs, uniquement dans la mesure où cela est absolument nécessaire pour maintenir le site web. Les informations recueillies automatiquement sont utilisées uniquement pour identifier les cas potentiels d'abus et établir des informations statistiques concernant l'utilisation du site web. Ces informations statistiques ne sont par ailleurs pas agrégées de manière à pouvoir identifier un utilisateur particulier du système. </span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Vous pouvez visiter le site Web sans nous dire qui vous êtes ni révéler d'informations permettant à quelqu'un de vous identifier en tant qu'individu spécifique et identifiable. Toutefois, si vous souhaitez utiliser certaines fonctionnalités du site web, ou si vous souhaitez recevoir notre lettre d'information ou fournir d'autres détails en remplissant un formulaire, vous pouvez nous fournir des données personnelles, telles que votre adresse électronique, votre prénom, votre nom, votre ville de résidence, votre organisation, votre numéro de téléphone. Vous pouvez choisir de ne pas nous fournir vos données personnelles, mais il se peut alors que vous ne puissiez pas profiter de certaines fonctionnalités du site web. Par exemple, vous ne pourrez pas recevoir notre bulletin d'information ou nous contacter directement à partir du site web. Les utilisateurs qui ne savent pas quelles informations sont obligatoires sont invités à nous contacter via contact@izi-place.com. </span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Vos droits:</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Si vous êtes un résident européen, vous disposez des droits suivants relatifs à vos données personnelles : </span></p>
  <ul style="margin-bottom:0in;margin-top:0in;" type="disc">
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit d'être informé.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit d'accès.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit de rectification.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit à l'effacement.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit de restreindre le traitement.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit à la portabilité des données.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Le droit d'opposition.</span></li>
      <li style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;color:#474B4F;background:white;'><span style="font-size:14px;font-family:Jost;">Les droits relatifs à la prise de décision automatisée et au profilage.</span></li>
  </ul>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Si vous souhaitez exercer ce droit, veuillez nous contacter via les coordonnées ci-dessous.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">En outre, si vous êtes un résident européen, nous notons que nous traitons vos informations afin d'exécuter les contrats que nous pourrions avoir avec vous (par exemple, si vous passez une commande par le biais du Site), ou autrement pour poursuivre nos intérêts commerciaux légitimes énumérés ci-dessus. En outre, veuillez noter que vos informations peuvent être transférées en dehors de l'Europe, y compris au Canada et aux États-Unis.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Liens vers des sites externes :</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Notre site web peut contenir des liens vers d'autres sites web qui ne nous appartiennent pas ou ne sont pas contrôlés par nous. Sachez que nous ne sommes pas responsables de ces autres sites Web ni des pratiques de confidentialité des tiers. Nous vous encourageons à être attentif lorsque vous quittez notre site Web et à lire les déclarations de confidentialité de chaque site Web susceptible de recueillir des informations personnelles. </span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Sécurité de l’information :</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Nous sécurisons les informations que vous fournissez sur des serveurs informatiques dans un environnement contrôlé et sécurisé, protégé contre tout accès, utilisation ou divulgation non autorisés. Nous prenons des mesures de protection administratives, techniques et physiques raisonnables pour nous protéger contre tout accès, utilisation, modification et divulgation non autorisés des données personnelles sous son contrôle et sa garde. Toutefois, aucune transmission de données sur Internet ou sur un réseau sans fil ne peut être garantie.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Divulgation d'informations juridiques :</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Nous divulguerons toute information que nous recueillons, utilisons ou recevons si la loi l'exige ou le permet, par exemple pour nous conformer à une citation à comparaître ou à une procédure judiciaire similaire, et lorsque nous pensons de bonne foi que la divulgation est nécessaire pour protéger nos droits, votre sécurité ou celle d'autrui, enquêter sur une fraude ou répondre à une demande du gouvernement.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:12.0pt;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><strong><span style="font-size:14px;font-family:Jost;color:#C122FA;">Pour nous contacter :</span></strong></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:0in;margin-left:0in;line-height:normal;font-size:15px;font-family:"Jost",sans-serif;background:white;'><span style="font-size:14px;font-family:Jost;color:#474B4F;">Si vous souhaitez nous contacter pour en savoir plus sur cette politique ou pour toute question relative aux droits individuels et à vos informations personnelles, vous pouvez envoyer un courriel à : contact@izi-place.com.</span></p>
  <p style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Jost",sans-serif;'><span style="font-family:Jost;">&nbsp;</span></p></div>`;

  var templateEN = { __html: htmlStringEN };
  var templateFR = { __html: htmlStringFR };
  return (
    <div dangerouslySetInnerHTML = {lg === 'en-US' ? templateEN : templateFR}></div>
  );
};

export default PrivacyPolicy;