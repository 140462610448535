import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CheckBox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import logo from '../assets/logo_IZIPLACE_512-min.png';
import CreateAccountImg from '../assets/createAccount.svg';
import Grid from '@mui/material/Grid';
import Button from './Button';
// import Select from './Select';
// import { CURRENCIES } from '../constants';
import LanguageSelector from './languageSelector';
import { useTranslation } from 'react-i18next';
import { Person as PersonIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SvgIcon } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import PropTypes from 'prop-types';
// import FormGroup from '@mui/material/FormGroup';
// import ConnexionButon from '../assets/connexionbuton.png';
import SearchBox from './SearchBox';
import { HeaderLoginForm, MobileLoginForm } from './LoginForm';
import { useNavigate } from 'react-router-dom';

export default function Header(props: any) {
  // const currencyList = CURRENCIES.map( (e, index)=> ({ label: `${e.symbol} ${e.code}`, value: `${index}` }));
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(false);
  const { setIsDialogOpened, setIsLoginDialogOpened, setIsFPDialogOpened } = props;
  const openSignupPopup = () => {
    setIsDialogOpened(true);
  };
  const openLoginPopup = () => {
    setIsLoginDialogOpened(true);
  };
  const navigate = useNavigate();
  return (
    <div>
    <Box sx={{ flexGrow: 1, display:{ xs: 'none', sm:'none', md:'inline', lg:'inline' } }}>
      <AppBar style={{ marginBottom: '20px', backgroundColor: '#fff' }} position="static">
        <div style={{ padding:'0 6.6% 0 6.6%', height: '80px'  }}>
        <Grid container>
          <Grid item xs={12} md={2} lg={2}>
        <div style={{ textAlign: 'left', paddingTop:'8px' }}>
        <img onClick={() => navigate('/')} style={{ cursor: 'pointer' }} src={logo} width="200px" height="60px"/>
        </div>
        </Grid>
        <Grid item xs={2} md={2} lg={2} />
              <Grid sx={{ display:{ xs: 'none' } }} style={{ display: 'flex', alignItems:'end', flexDirection: 'row' }} item xs={3} md={3} lg={3}>
                <HeaderLoginForm setIsFPDialogOpened={setIsFPDialogOpened} openLoginPopup={openLoginPopup} />
              </Grid>
              
              <Grid item xs={4} md={4} lg={4}>
              </Grid>
              <Grid style={{ display: 'flex', alignSelf: 'flex-end', transform: 'translate(0px, 6px)' }} item xs={1} md={1} lg={1}>
              <Grid container>
              <Grid item xs={3} md={3} lg={3}>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
                {/* <Select name='currency' data={currencyList} /> */}
              </Grid>
              <Grid item xs={1} md={1} lg={1}>
              </Grid>
              <Grid item xs={4} md={4} lg={4}>
              <LanguageSelector />
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div onClick={openSignupPopup} style={{ position: 'relative', width: '100%', minWidth:'128px', cursor:'pointer' }}>
                  <img id="image" width="128px" height="34px" alt="Login"
                      src={CreateAccountImg}/>
                <span style={{ zIndex: 15, fontSize:'13px', color: 'black', position: 'absolute', width: '100%', top:'7px', right:'-1px', whiteSpace: 'nowrap' }}>{t('Signup')}</span>
                  </div>
              </Grid>
              </Grid>
              </Grid>
              </Grid>
              </div>
              <div style={{ backgroundColor: '#3E3C41', height: '50px', padding:'0 6.6% 0 6.6%'  }} >
                  <SearchBox />
              </div>
      </AppBar>
    </Box>
    <Box  sx={{ flexGrow: 0, flexShrink: 0, display:{ xs: 'inline', sm:'inline', md:'none', lg:'none' } }}>
      <AppBar style={{ backgroundColor: '#fff', height: '80px', position: 'relative', marginBottom: '20px', padding:'0 0 0 0px'  }}>
        <div>
      <Accordion expanded={expanded}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
        <Grid container>
          <Grid item xs={4} md={4} lg={4}/>
          <Grid item xs={4} md={4} lg={4}>
        <div style={{ textAlign: 'start', paddingTop:'8px' }}>
        <img src={logo} onClick={() => navigate('/')} width="154px" height="46px"/>
        </div>
        </Grid>
        <Grid style={{ textAlign: 'right' }} item xs={4} md={4} lg={4}>
        <IconButton
        onClick={()=> setExpanded(!expanded) }
        style={{
          border: '1px solid black',
          borderRadius: '5px',
          top: '26px',
          height: '28px',
          width: '28px',
          right: '10px',
        }}>
              <SvgIcon fontSize="medium">
                <PersonIcon />
              </SvgIcon>
            </IconButton>
        </Grid>
        </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor:'#F5F9F8', border:'1px solid #5ED5C854' }}>
        <div style={{ height: '44px', display:'flex', justifyContent:'space-between', borderBottom:'1px solid rgba(94, 213, 200, 0.33)', marginBottom:'8px' }}>
              <div style={{ display: 'flex', width:'30%', justifyContent:'space-evenly' }}>
              {/* <Select name='currency' data={currencyList} /> */}
              <LanguageSelector />
              </div>
              <Button style={{ padding: 0, width:'50%', height: '30px' }} variant='outlined'> {t('Signup')} </Button>
          </div>
          <MobileLoginForm setExpanded={setExpanded}/>
          <div style={{ width:'100%', display:'flex', justifyContent:'space-between'  }}>
        <FormControlLabel control={
        <CheckBox defaultChecked/>
        }
        label={<Link style={{ alignSelf:'flex-start', padding:'9px 0 9px 0', fontSize:'11px' }} color='text.primary' href="#"  underline="none">{t('RememberMe')}</Link>}
        />
        <Link
          onClick={() => {setIsFPDialogOpened(true); }}
          style={{ alignSelf:'flex-start', padding:'9px 0 9px 0', fontSize:'11px', marginBottom: '5px' }}
          color='primary'
          href="#"
          underline="none">{t('ForgottenPassword')}</Link>
        </div>
        </AccordionDetails>
        </Accordion>
        </div>
        </AppBar>
        </Box>
    </div>
  );
}

Header.propTypes = {
  setIsDialogOpened: PropTypes.func.isRequired,
  setIsLoginDialogOpened:  PropTypes.func.isRequired,
  setIsFPDialogOpened: PropTypes.func.isRequired,
};
