import React from 'react';
import './index.css';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate } from 'react-router';
import DeleteDialog from './deleteDialog';

const MostVsitedLine = (props: any) => {
  const {
    // title,
    // updateDate,
    // Views,
    // Clicks,
    index,
    clicks,
    views,
    offerTitle,
    updatedAt,
    received,
    pending,
    offerId,
    fetchData,
  } = props;
  let offId = offerId;
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const date = moment(updatedAt).format('DD/MM/YYYY');
  return <div className='most-visited-line'
    style={{ backgroundColor: index % 2 === 0 ? '#F5FBFB' : '#fff' }}
  >
      <div className='most-visited-title'>
          { offerTitle }
          </div>
          <div className='divider-messages'>
            </div>
          <div className='most-visited-date'>
          {date}
          </div>
          <div className='divider-messages'>
            </div>
          <div className='most-visited-views'>
          { views || 0 }
          </div>
          <div className='divider-messages'>
            </div>
          <div className='most-visited-clicks'>
          { clicks || 0 }
          </div>
          <div className='divider-messages'>
            </div>
          <div className='most-visited-received'>
          { received || 0 }
          </div>
          <div className='divider-messages'>
            </div>
          <div className='most-visited-waiting'>
          { pending || 0 }
          </div>
          <div className='divider-messages'>
            </div>
          <div className='most-visited-actions'>
          <FontAwesomeIcon
          style={{
            position: 'relative',
            paddingRight: '10px',
            height: '16px',
            width: '16px',
            cursor: 'pointer',
          }}
          icon={faEye}
          color='#5BD6CA'
          onClick={() => navigate('/annonce/' + offId )}
          />
          <FontAwesomeIcon
          style={{
            position: 'relative',
            paddingRight: '10px',
            height: '16px',
            width: '16px',
            cursor: 'pointer',
          }}
          icon={faEdit}
          color='#C025FA'
          onClick={() => navigate('/dashboard/annonce/edit/' + offId )}
          />
          <FontAwesomeIcon
          style={{
            position: 'relative',
            paddingRight: '10px',
            height: '16px',
            width: '16px',
            cursor: 'pointer',
          }}
          icon={faTrash}
          onClick={() => setDeleteDialogOpen(true)}
          color='#F82478'
          />
          <span className='promote-button-stats'>
            <span
            style={{ transform: 'translate(0px, 8px)' }}
            onClick={() => navigate('/promote-offer/' + offId)}
            >
              {t('Boost')}
              </span>
              </span>
          </div>
          {deleteDialogOpen &&
          <DeleteDialog
          fetchData={fetchData}
          name={offerTitle} id={offId} open={deleteDialogOpen} handleClose={() => setDeleteDialogOpen(false)}/>
          }
    </div>;
};

export default MostVsitedLine;
