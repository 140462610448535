import React from 'react';
import './index.css';
import { faReply, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const MessagesLine = (props: any) => {
  const navigate = useNavigate();
  const {
    sender,
    offer,
    lastMessage,
    _id : convId,
  } = props;
  return <div className='messages-line'
  onClick={() => navigate('/messages/' + convId)}
    >
      <div className='messages-emitter'>
          { `${sender?.firstName} ${sender?.lastName}` }
          </div>
          <div className='divider-messages'>
              </div>
          <div className='message-content'>
              <div className='message-offer-title'>
              {!!offer && offer.title}
              </div>
                <div className='message-offer-content'>
              {lastMessage?.content}
              </div>
          </div>
          <div className='divider-messages'>
            </div>
          <div className='message-date'>
              { lastMessage && lastMessage.createdAt && moment(lastMessage.createdAt).format('DD/MM/YYYY') }
          </div>
          <div className='divider-messages'>
              </div>
          <div className='message-actions'>
          <FontAwesomeIcon
          style={{
            position: 'relative',
            paddingRight: '10px',
            height: '16px',
            width: '16px',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/messages/' + convId)}
          icon={faReply}
          color='#5BD6CA'
          />
          <FontAwesomeIcon
          style={{
            position: 'relative',
            height: '16px',
            width: '16px',
          }}
          icon={faTrash}
          color='rgba(248, 36, 120, 1)'
          />
          </div>

    </div>;
};

export default MessagesLine;