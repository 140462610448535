import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface Error {
  uiError: boolean;
}

// Define the initial state using that type
const initialState: Error = {
  uiError: false,
};

export const errorSlice = createSlice({
  name: 'error',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    set: (state) => {
      state.uiError = true;
    },
    reset: (state) => {
      state.uiError = false;
    },  
  },
});

export const { set, reset } = errorSlice.actions;

export default errorSlice.reducer;
