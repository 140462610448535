import React, { useState } from 'react';
import TextField from './textField';
import { Chip } from '@mui/material';

const TextFieldMultiple = (props: any) => {
  const {
    name,
    setFieldValue,
    value,
    error,
    label,
    style,
    inputProps,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const inputChange = (e: any) => {
    setInputValue(e?.target.value);
  };
  const handleKeyDown = (e: any) => {
    const { key } = e;
    if ((key === ' ' || key === 'Enter') && inputValue.length >= 2) {
      e.preventDefault();
      setFieldValue(name, [...value, inputValue]);
      setInputValue('');
    }
  };
  const handleDelete = (item: String) => {
    const nV = value.filter((e: any)=> e !== item);
    setFieldValue(name, nV);
  };
  return (
    <React.Fragment>
    <TextField 
        size="small"
        label={label}
        variant="outlined"
        autoFocus
        error={error}
        onChange={inputChange}
        onKeyDown={handleKeyDown}
        value={inputValue}  
        style={style}
        inputProps={inputProps}
        sx={{
          '& .MuiOutlinedInput-root': {
            paddingLeft: '0px',
          },
        }}
    />
    {value && value.length > 0 && <div style={{ marginTop: '4px', maxWidth: '96%' }}>{value.map((item: string, index: number) => (
              <Chip
                style={{ height: '22px' }}
                key={index}
                label={item}
                onDelete={() => handleDelete(item)}
              />
    ))}</div>
    }
    </React.Fragment>
  );
};

export default TextFieldMultiple;