import React from 'react';
import { useTranslation } from 'react-i18next';

const LegalNotice = () => {
  var lg = localStorage.getItem('i18nextLng');
  const { t } = useTranslation();
  React.useEffect(()=> {
    lg = localStorage.getItem('i18nextLng');
  }, [t]);

  var htmlStringEN = `<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <div style="text-align: left; padding-left: 10vw; padding-right: 10vw;">
  <p style="margin-top:10pt;margin-right:0pt;margin-bottom:5pt;margin-left:0pt;text-align:center;font-size:30px;color:#C122FA;font-weight:bold;">
      <span style="color: rgb(193, 34, 250);font-family:Jost;">Legal Notice</span>
  </p>

  <p style="margin-top:10pt;margin-right:0pt;margin-bottom:5pt;margin-left:0pt;text-align:left;font-size:16px;color:black;">

      <span style="font-family:Jost;">In accordance with the provisions of Articles 6-III and 19 of Law No. 2004-575 of June 21, 2004 for Confidence in the Digital Economy, visitors to the website www.izi-place.com are informed of the following:</span>

  <ul style="font-family:Jost;">
      <li style="margin-bottom: 20pt;">The website www.izi-place.com is published by the company GRIBYS, a simplified joint stock company (with a single shareholder), whose registered office is located at 45 avenue Victor Hugo 93300 Aubervilliers, and which is registered with the Créteil Trade and Companies Register under the number 798 823 829, whose VAT number is FR72798823829, represented by its President, hereinafter referred to as "IZI-PLACE", who can be reached by telephone at: 09 84 38 32 97 and by email at: contact@izi-place.com.

Translated with www.DeepL.com/Translator (free version)</li>


      <li style="margin-bottom: 20pt;">The director of the publication of the site is Mr. Ilan Benarrous, President of the company GRIBYS.</li>

      <li style="margin-bottom: 20pt;">The website www.izi-place.com is hosted by the company AWS, whose head office is located at 52 RUE DU PORT, 92000 NANTERRE and which can be reached on the page: https://aws.amazon.com/fr/contact-us/</li>
  </ul>
  </p>
</p></div>`;

  var htmlStringFR = `<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
  <div style="text-align: left; padding-left: 10vw; padding-right: 10vw;">
  <p style="margin-top:10pt;margin-right:0pt;margin-bottom:5pt;margin-left:0pt;text-align:center;font-size:30px;color:#C122FA;font-weight:bold;">
      <span style="color: rgb(193, 34, 250);font-family:Jost;">Mentions légales</span>
  </p>

  <p style="margin-top:10pt;margin-right:0pt;margin-bottom:5pt;margin-left:0pt;text-align:left;font-size:16px;color:black;">

      <span style="font-family:Jost;">Conformément aux dispositions des articles 6-III et 19 de la Loi n° 2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique, il est porté à la connaissance des visiteurs du site web www.izi-place.com, les informations suivantes : </span>

  <ul style="font-family:Jost;">
      <li style="margin-bottom: 20pt;">le site web www.izi-place.com est édité par la société GRIBYS, société par actions simplifiée (à associé unique), ayant son siège social au 45 avenue Victor Hugo 93300 Aubervilliers, et enregistrée au RCS de Créteil sous le numéro 798 823 829, dont le numéro de TVA est FR72798823829 représentée par son Président, ci-après désignée par « IZI-PLACE », joignable par téléphone au : 09 84 38 32 97 et par email à l’adresse : contact@izi-place.com. </li>


      <li style="margin-bottom: 20pt;">Le directeur de la publication du site est Monsieur Ilan Benarrous, Président de la société GRIBYS.</li>

      <li style="margin-bottom: 20pt;">le site web www.izi-place.com  est hébergé par la société AWS , ayant son siège social au 52 RUE DU PORT, 92000 NANTERRE et joignable sur la page : https://aws.amazon.com/fr/contact-us/</li>
  </ul>
  </p>
</p></div>`;

  var templateEN = { __html: htmlStringEN };
  var templateFR = { __html: htmlStringFR };
  return (
    <div dangerouslySetInnerHTML = {lg === 'en-US' ? templateEN : templateFR}></div>
  );
};

export default LegalNotice;