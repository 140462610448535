import React from 'react';
import './index.css';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import flags from '../OfferEditForm/Flags';
import IconLocation from '../../assets/location-dot-solid';

const ContactSection = (props: any) => {
  const { t } = useTranslation();
  const { companyName,
    mobile,
    phone,
    refContact,
    slogan,
    color,
    languages,
    sellerData,
  } = props;
  const {
    streetNumber,
    zipCode,
    city,
    country,
  } = sellerData;
  const CountriesTranslations: any = t('CountriesList', { returnObjects: true });
  const countryName = CountriesTranslations[country];
  const countryFlag = flags.find(e => e.code === country) ? flags.find(e => e.code === country)?.flag : '';
  const contactCardData = [
    {
      label: t('Street'),
    },
    {
      label: t('ZipCode'),
    },
    {
      label: t('City'),
    },
    {
      label: t('Country'),
    },
  ];
  const values = [
    streetNumber,
    zipCode,
    city,
    <React.Fragment>
      <span>{countryName}</span>
      <img height='20' width='20' style={{ marginLeft: '10px', verticalAlign: 'bottom' }} src ={countryFlag}/>
    </React.Fragment>,
  ];

  return <Grid container spacing={2}>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', marginTop: 'auto', marginBottom: 'auto' }}>
          {languages && languages.length > 0 && <React.Fragment><div
          style={{ fontSize: '15px', color: '#21062E', marginTop: '4px', fontWeight: 'bold' }}
          >{t('Languages')}&nbsp; : &nbsp;</div>
          {languages.map((language: any) => {
            const flag = flags.find((f: any) => f.code === language);
            return <img src={flag?.flag} width='40' height='27' style={{ marginRight: '18px' }}/>;
          })}
          </React.Fragment>
          }
          </Grid>
          <Grid item xs={4}>
              <div className='company-name'>
                  {companyName}
                </div>
                <div className='store-slogan' style={{ color }}>
                  {slogan}
                </div>
                <div className='ref-contact'>
                  {refContact}
                </div>
                <div className='store-phone'>
                  {phone}
                </div>
                <div className='store-phone'>
                  {mobile}
                </div>
          </Grid>
          <Grid item xs={4} style={{ display: 'flex' }}>
            <IconLocation color='#BFBFBF'/>
          <div className='store-adress-card'>
              <div className='store-adress-labels'>
              {
                contactCardData.map((el: any) => (
                    <div style={{ marginTop: '8px' }}>
                      {el.label}&nbsp;:
                      </div>
                ),
                )
              }
              </div>
              <div className='store-adress-values'>
              {
                values.map((el: any) => (
                  <div style={{ marginTop: '8px' }}>
                  &nbsp;
                      &nbsp;
                      {el}
                      </div>
                ),
                )
              }
              </div>
              </div>
          </Grid>
  </Grid>;
};

export default ContactSection;