export const LANGUAGES: Array<any> = [
  { name: 'Français', code: 'FR' },
  { name: 'Anglais', code: 'EN'  },
];
export const CURRENCIES: Array<any> = [
  { code: 'EUR', symbol: '€' },
  { code: 'GBP', symbol: '£' },
  { code: 'USD', symbol: '$' },
  { code: 'CHF', symbol: 'CHF' },
  { code: 'AUD', symbol: '$' },
  { code: 'CAD', symbol: '$' },
  { code: 'SEK', symbol: 'kr' },
  { code: 'NOK', symbol: 'kr' },
  { code: 'DKK', symbol: 'kr' },
  { code: 'ZAR', symbol: 'R' },
  { code: 'JPY', symbol: '¥' },
  { code: 'HKD', symbol: 'HK$' },
  { code: 'CZK', symbol: 'Kč' },
  { code: 'AED', symbol: 'د.إ' },
  { code: 'PLN', symbol: 'zł' },
];

export const countriesFlags: Array<String> = [
  'australia',
  'austria',
  'bangladesh',
  'belgium',
  'bosnia-and-herzegovina',
  'bulgaria',
  'canada',
  'china',
  'croatia',
  'cyprus',
  'czech-republic',
  'denmark',
  'estonia',
  'france',
  'germany',
  'greece',
  'hungary',
  'india',
  'ireland',
  'israel',
  'italy',
  'jordan',
  'latvia_lettonie',
  'lithuania',
  'luxembourg',
  'malta',
  'monaco',
  'netherlands',
  'norway',
  'pakistan',
  'poland',
  'portugal',
  'romania',
  'serbia',
  'slovakia',
  'slovenia',
  'spain',
  'sweden',
  'switzerland',
  'turkey',
  'ukraine',
  'united-arab-emirates',
  'united-kingdom',
  'united-states',
];

export const PRICE_DESC = 'pricedesc';
export const PRICE_ASC = 'priceasc';
export const DATE = 'Date';
