import { createSlice } from '@reduxjs/toolkit';
interface Chat {
  unread: any,
}

// Define the initial state using that type
const initialState: Chat = {
  unread: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUnread: (state, payload: any) => {
      return {
        ...state,
        unread: payload.payload,
      };
    },
  },
});

export const { setUnread } = chatSlice.actions;

export default chatSlice.reducer;
