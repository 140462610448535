import * as React from 'react';
import { Typography, Grid } from '@mui/material';
import Button from '../../../components/Button';
import TextField from '../../../components/textField';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';


export default function AccountForm(props: any) {
  const { goToNextStep, collectedFormsValue, setCollectedFormsValues } = props;
  const { t } = useTranslation();
  let passwordConfirmation = '';
  if (collectedFormsValue && collectedFormsValue.email !== '' ) {
    passwordConfirmation = collectedFormsValue.password;
  }
  return (
    <Formik
    enableReinitialize
    initialValues={{ ...collectedFormsValue, passwordConfirmation }}
    validationSchema={Yup.object().shape({
      email: Yup.string().email(t('emailValidation')).max(255).required(t('mandatoryRow')),
      password: Yup.string().max(255).required(t('mandatoryRow'))
        .matches(/(?=.{8,})(?=.*?[0-9]).*?[a-z].*/, t('passwordValidation')),
      passwordConfirmation: Yup
        .string()
        .max(255).required(t('mandatoryRow'))
        .oneOf([Yup.ref('password'), null], t('passwordNoMatch')),
    })}
    onSubmit={( { email, password } ) => {
      setCollectedFormsValues({  ...collectedFormsValue, email, password });
      goToNextStep();
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
        <div style={{ backgroundColor: '#F5F9F8', border:'1px #e9efef solid', textAlign: 'center' }}>
          <Typography style={{ padding:'16px 0 16px 0' }} variant='h6'>
            {t('ChooseYourLoginAndPassword')}
            </Typography>
            <div style={{ padding:'16px' }}>
            <Grid style={{ justifyContent: 'center' }} container>
              <Grid xs={5} item style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="email"
              label="Email"
              type="email "
              variant="outlined"
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              autoFocus
              name="email"
              onChange={handleChange}
              value={values.email}  
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid xs={5} item style={{ margin: '0px 12px 12px 12px' }}>
            <Typography color='primary' variant='caption'>
              {t('WeWontShareEmail')}
              </Typography>
            </Grid>
            <Grid xs={5} item style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="password"
              label={t('Password')}
              type="password"
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              name="password"
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              onChange={handleChange}
              value={values.password}  
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid xs={5} item style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
                size="small"
                id="passwordVerification"
                label={t('PasswordConfirmation')}
                type="password"
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                name="passwordConfirmation"
                onChange={handleChange}
                value={values.passwordConfirmation}    
                variant="outlined"
                style={{ marginBottom:'8px', width: '100%' }}
                inputProps={{
                  style: {
                    height: '30px',
                    backgroundColor: '#fff',
                    padding: '4px 0px 4px 8px',
                  },
                }}
            />
            </Grid>
            </Grid>
          </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px' }}>
            <Button variant="contained" type="submit">
              {t('next')}
              </Button>
              </div>
          </form>
      );
    }
}
</Formik>
  );
}
