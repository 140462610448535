import * as React from 'react';
import Stepper from '../../components/VerticalStepper';
import ProductFormContainer from './ProductFormContainer';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import './index.css';
import { useParams } from 'react-router-dom';

import { fetchProduct } from '../../api/product';
import { useAppSelector } from '../../store/hooks';


export default function PoductAdd() {
  const { t } = useTranslation();
  const steps = [
    t('Description'),
    t('QuantityPrice'),
    t('PicturesAttachment'),
    // t('Sponsor'),
  ];
  console.log('helooo');
  const { id } = useParams();
  const [offerData, setOfferData] = React.useState<any>(null);
  const { isAuthenticated } = useAppSelector(state => state.user);

  const getOffer = async (Id: any) => {
    const res = await fetchProduct(Id, isAuthenticated);
    setOfferData(res.data);
  };
  React.useEffect(()=> {
    getOffer(id);
  }, [id]);

  const [activeStep, setActiveStep] = React.useState(0);
  return <Grid container className="container-edit">
      {/* <Grid container> */}
      <Grid sx={{ flexGrow: 1, display:{ xs: 'none', sm:'none', md:'none', lg:'inline' } }} item xs={0} lg={3} >
      <Stepper activeStep={activeStep} steps={steps} />
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} >
      { offerData && <ProductFormContainer offerData={offerData} activeStep={activeStep} setActiveStep={setActiveStep}>
        </ProductFormContainer>}
      </Grid>
    </Grid>;
}