import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';

// import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const RenderDifferentParent = (props: any) => {
  const { children, noGrid } = props;
  return (
  <React.Fragment>
    {noGrid && children }
    {!noGrid && <Grid sm={6} md={6} lg={6} item style={{ textAlign: 'start' }}>{children}</Grid>}
    </React.Fragment>
  );
};
export default function CheckboxLabels(props: any) {
  const { values, setFieldValue, data, fieldName, noGrid } = props;
  const handleChange = ( source: any ) => {
    let res = [];
    if (values.indexOf(source) > -1 ) {
      res = values.filter( (e : any) => e !== source);
    } else {
      res = [...values, source];
    }
    setFieldValue(fieldName, res);
  };
  return (
      <React.Fragment>
          {data.map((element: any) => (
            <RenderDifferentParent noGrid={noGrid}>
          <FormGroup>
            <FormControlLabel
            control={<Checkbox onChange={() => handleChange(element.value)} checked={values.indexOf(element.value) > -1 } />}            
            label={element.label}
            sx={{ '& .MuiTypography-root': {
              fontSize: '14px',
              color: '#545C84',
            },
            'MuiButtonBase-root-MuiCheckbox-root': {
              padding: '6px',
            },
            }}
            />
            </FormGroup>
            </RenderDifferentParent>
          ),
          )
    }
    </React.Fragment>
  );
}
RenderDifferentParent.defaultProps = {
  noGrid: false,
};