import React from 'react';
import './index.css';
import Check from '../../assets/check.png';
import { useTranslation } from 'react-i18next';

const BuyerPlan = (props: any) => {
  const { t } = useTranslation();
  const { plans, checked, onModalOpen } = props;
  console.log('checked', checked);
  const planName = checked ? 'buyer-yearly' : 'buyer-monthly';
  const plan = plans.find((e: any) => e.name === planName);
  const amount = plan.amount;
  const data = [1, 2, 3, 4, 5, 6];
  const period = checked ? 'year' : 'month';

  return <div>
      <div className='buyer-header'>
          <div className='section-title'>
              {t('buyer')}
            </div>
            <div className='section-price'>
            {amount}€
              <span className='price-postfix'>
                HT / {t(period)}
                  </span>
            </div>
          </div>
          <div className='subscription-section-subhead'>
              <div className='subscription-button-border'>
              <div className='subscription-button' onClick={() => onModalOpen(plan._id)}>
                  {t('Subscribe')}
                  </div>
                </div>
            </div>
            <div style={{ height: '400px', 'backgroundColor': '#F5FBFB', border: '1px solid #CDE9E6' }}>
            {data.map(() => <div className='row-buyer'>
                <img src={Check} height='19' width='26' />
            </div>)}
            </div>
            <div className='section-bottom'>
                <div style={{ height: '14px' }}>
                    </div>
            <div className='section-price' style={{ color: '#F82478', height: '55px' }}>
            {amount}€
              <span className='price-postfix' style={{ color: '#000' }}>
                HT / {t(period)}
                  </span>
            </div>
            <div
              onClick={() => onModalOpen(plan._id)}
              className='section-bottom-button'
              style={{
                backgroundColor: '#F6256D',
                borderBottom: 'none !important',                
              }}>
                  {t('Subscribe')}
                </div>
            </div>
        </div>;
};
export default BuyerPlan;