import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const HomePageButton = (props: any) => {
  const {
    Icon,
    label,
    color,
    action,
    disabled,
    helperText,
    style,
    responsiveIcons,
  } = props;
  const [ isHovered, setIsHovered] = React.useState(false);
  return <React.Fragment><div
    className={!disabled ? 'home-page-button' : 'home-page-button-disabled' }
    style={{
      opacity: disabled ? 0.5 : 1,
      backgroundColor: !disabled ? isHovered ? '#fff' : color : color, 
      color: !disabled ? isHovered ? color : '#fff' : '#fff',
      border: `1px solid ${color}`,
      cursor: disabled ? 'auto' : 'pointer',
      ...style,
    }}
    onMouseOver={() => setIsHovered(true) }
    onMouseLeave={() => setIsHovered(false) }
    onClick={() => action()}
    >
      <span style={{ width: responsiveIcons ? '25%' : '17%', paddingTop: '10px' }}>
        {Icon({
          color: !disabled ? isHovered ? color : '#fff' : '#fff' })}
        </span>
        <span style={{ width: '65%', paddingTop: '20px' }}>
      {label}
      </span>
      <span style={{ width: responsiveIcons ? '10%' : '17%', paddingTop: '18px' }}>
      <FontAwesomeIcon
        style={{
          cursor: 'pointer',
          paddingTop: '7px',
          height: '19px',
        }}
        color={!disabled ? isHovered ? color : '#fff' : '#fff'}
        icon={faChevronRight} />
        </span>
        { disabled && <span style={{ color: '#fff' }} className="home-page-button-tooltip">{helperText}</span>}
  </div>
  </React.Fragment>;
};
export default HomePageButton;