import React from 'react';
import './index.css';

const Stat = (props: any) => {
  const { label, stat, icon, iconHeight, iconWidth, marginTop } = props;
  return <div className='stat-dash'>
      <div className='stat-icon-container'>
      <img height={iconHeight} width={iconWidth} style={{ marginTop }} src={icon} />
      </div>
      <div className='stat-number'>
          {stat}
          </div>
          <div className='stat-label'>
              {label}
              </div>
    </div>;
};

export default Stat;