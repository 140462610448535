import * as React from 'react';
import { Typography, Grid, Button } from '@mui/material';
import TextField from '../../../components/textField';
import SwitchButton from '../../../components/FormikSwitchButton';
import Checkbox from '../../../components/CheckBox';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import flags from '../ProductForm/Flags';
import FormikSelect from '../../../components/FormikSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export default function SimpleDialog( props: any) {
  const { goToNextStep, collectedFormsValue, setCollectedFormsValues, goBack } = props;
  const { t } = useTranslation();
  let isBillingAddr = false;
  let billAdress = {};
  if (collectedFormsValue && collectedFormsValue.billingAddress ) {
    isBillingAddr = !collectedFormsValue.isBillingAddress;
    const {
      billingStreetNumber,
      billingCity,
      billingZipCode,
      billingBusinessName,
      billingCountry,
    } = collectedFormsValue.billingAddress;
    billAdress = {
      billingStreetNumber,
      billingCity,
      billingZipCode,
      billingBusinessName,
      billingCountry,
    };
  }

  const CountriesTranslations = t('CountriesList', { returnObjects: true });
  let countriesobjs = Object.entries(CountriesTranslations);
  countriesobjs.sort((cata: any, catb: any) => {
    const textA = cata[1].toUpperCase();
    const textB = catb[1].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
  });
  const countriesList = countriesobjs.map(([key, value]) => {
    const flag = flags.find(e => e.code === key);
    return ({
      label:(<div style={{ display: 'flex' }}>
        <img height='24' width='30' style={{ marginRight: '10px' }} src={flag ? flag.flag : '' }/><span>{String(value)}</span></div>
      ),
      value: key,
    }
    );
  });
  const CivlityTranslations = t('Civility', { returnObjects: true });
  const civilities = Object.entries(CivlityTranslations).map(([key, value]) => ({
    value: key,
    label: value,
  }));
  const whenProfessionalValidation = Yup.string().when('isProfessional', {
    is: 'on',
    then: Yup.string().required(t('mandatoryRow')),
  });
  const whenIndividualValidation = Yup.string().when('isProfessional', {
    is: 'off',
    then: Yup.string().required(t('mandatoryRow')),
  });
  const whenDifferentInvoiceAdressValidation = Yup.string().when('isBillingAddress', {
    is: true,
    then: Yup.string().required(t('mandatoryRow')),
  });
  return (
    <Formik
    enableReinitialize
    initialValues={{  ...collectedFormsValue, ...billAdress, isBillingAddress: isBillingAddr }}
    validationSchema={Yup.object().shape({
      isProfessional: Yup.string().max(255),
      isBillingAddress: Yup.bool(),
      civility: whenIndividualValidation,
      lastName: Yup.string().required(t('mandatoryRow')),
      firstName: Yup.string().required(t('mandatoryRow')),
      businessName: whenProfessionalValidation,
      siret: whenProfessionalValidation,
      tva: Yup.string().max(255),
      phone: Yup.string()
        .max(255)
        .required(t('mandatoryRow'))
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, t('phoneInvalid')),
      mobile: Yup.string().max(255)
        .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, t('phoneInvalid')),
      website: Yup.string().max(255),
      streetNumber: Yup.string().max(255).required(t('mandatoryRow')),
      city: Yup.string().max(255).required(t('mandatoryRow')),
      zipCode: Yup.string().max(255).required(t('mandatoryRow')),
      billingStreetNumber: whenDifferentInvoiceAdressValidation,
      billingCity: whenDifferentInvoiceAdressValidation,
      billingZipCode: whenDifferentInvoiceAdressValidation,
      billingBusinessName: whenDifferentInvoiceAdressValidation,
    })}
    onSubmit={(values) => {
      const accountType = values.isProfessional === 'on' ? 'Professionnel' : 'Particulier';
      const {
        civility,
        lastName,
        firstName,
        businessName,
        siret,
        tva,
        phone,
        mobile,
        website,
        streetNumber,
        city,
        zipCode,
        billingStreetNumber,
        billingCity,
        billingZipCode,
        billingBusinessName,
        country,
        billingCountry,
      } = values;
      const isBillingAddress = !values.isBillingAddress;
      const data = {
        isBillingAddress,
        civility,
        lastName,
        firstName,
        businessName,
        siret,
        tva,
        phone,
        mobile,
        website,
        streetNumber,
        city,
        zipCode,
        country,
        billingCountry,
        billingStreetNumber,
        billingCity,
        billingZipCode,
        billingBusinessName,
      };
      setCollectedFormsValues({ ...collectedFormsValue, ...data, accountType });
      goToNextStep();
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
        <div style={{ backgroundColor: '#F5F9F8', border:'1px #e9efef solid', textAlign: 'center', justifyContent:'center' }}>
          <Typography style={{ padding:'16px 0 0 0' }} variant='h6'>
            {t('EnterYourDetails')}
            </Typography>
            <div style={{ padding:'16px', justifyContent: 'center' }}>
              <Field
                fieldName="isProfessional"
                component={SwitchButton}
                setFieldValue={setFieldValue}
                value={values.isProfessional}
                style={{ justifyContent: 'center', marginBottom: '24px' }}
                beforeText="Particulier"
                afterText="Professionel" />
            <Grid style={{ justifyContent: 'center' }} container>
                {values.isProfessional !== 'on' && 
                <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                <FormikSelect
                  name="civility"
                  value={values.civility}
                  placeholder={t('Title')}
                  style={{ width: '100%', borderRadius: 0, textAlign: 'start' }}
                  error={Boolean(touched.civility && errors.civility)}
                  setFieldValue={setFieldValue}
                  options={civilities}
                      />
                </Grid>
                }
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="LastName"
              label={values.isProfessional !== 'on' ? `${t('LastName')}*` : `${t('OwnerLastName')}*`}
              variant="outlined"
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="FirstName"
              variant="outlined"
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              label={ values.isProfessional !== 'on' ? `${t('FirstName')}*` : `${t('OwnerFirstName')}*`}
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            {values.isProfessional === 'on' && <React.Fragment>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="businessName"
              error={Boolean(touched.businessName && errors.businessName)}
              helperText={touched.businessName && errors.businessName}
              name="businessName"
              value={values.businessName}
              onChange={handleChange}
              label={`${t('CompanyName')}*`}
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="SIRET"
              error={Boolean(touched.siret && errors.siret)}
              helperText={touched.siret && errors.siret}
              name="siret"
              value={values.siret}
              onChange={handleChange}
              label={`${t('SIRET')}*`}
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="tva"
              label={t('VATNUMBER')}
              variant="outlined"
              error={Boolean(touched.tva && errors.tva)}
              helperText={touched.tva && errors.tva}
              name="tva"
              value={values.tva}
              onChange={handleChange}
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            </React.Fragment>
            }
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="PhoneNumber1"
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              name="phone"
              value={values.phone}
              onChange={handleChange}
              label={`${t('PhoneNumber1')}*`}
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="PhoneNumber2"
              label={t('PhoneNumber2')}
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              error={Boolean(touched.mobile && errors.mobile)}
              helperText={touched.mobile && errors.mobile}
              name="mobile"
              value={values.mobile}
              onChange={handleChange}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="Website"
              label={t('Website')}
              variant="outlined"
              error={Boolean(touched.website && errors.website)}
              helperText={touched.website && errors.website}
              name="website"
              value={values.website}
              onChange={handleChange}
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="streetNumber"
              error={Boolean(touched.streetNumber && errors.streetNumber)}
              helperText={touched.streetNumber && errors.streetNumber}
              name="streetNumber"
              value={values.streetNumber}
              onChange={handleChange}
              label={`${t('BusinessAdress')}*`}
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="City"
              label={`${t('City')}*`}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
              name="city"
              value={values.city}
              onChange={handleChange}
              variant="outlined"
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
            <TextField 
              size="small"
              id="ZipCode"
              label={`${t('ZipCode')}*`}
              variant="outlined"
              helperText={touched.zipCode && errors.zipCode}
              error={Boolean(touched.zipCode && errors.zipCode)}
              name="zipCode"
              value={values.zipCode}
              onChange={handleChange}
              style={{ marginBottom:'8px', width: '100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
            />
            </Grid>
            <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px', width: '4px' }}>
                      <FormikSelect
                        name="country"
                        value={values.country}
                        placeholder={t('Country')}
                        style={{ width: '100%', borderRadius: 0, textAlign: 'start' }}
                        error={Boolean(touched.country && errors.country)}
                        setFieldValue={setFieldValue}
                        options={countriesList}
                      />

            </Grid>
            <Checkbox
              setFieldValue={setFieldValue}
              fieldName="isBillingAddress"
              value={values.isBillingAddress}
              label="l'adresse de facturation est différente"
              />
            </Grid>
              </div>
          </div>
          {values.isBillingAddress && 
                  <div style={{ backgroundColor: '#e6f4f5', border:'1px #e9efef solid', textAlign: 'center', justifyContent:'start', paddingLeft: '28px' }}>
                  <Typography style={{ padding:'16px 0 16px 0' }} variant='h6'>
                    Adresse de facturation
                    </Typography>
                    <form style={{ padding:'16px', justifyContent: 'start' }}>
                    <Grid style={{ justifyContent: 'start' }} container>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="billingBusinessName"
                      name="billingBusinessName"
                      value={values.billingBusinessName}
                      error={Boolean(touched.billingBusinessName && errors.billingBusinessName)}
                      helperText={touched.billingBusinessName && errors.billingBusinessName}
                      onChange={handleChange}
                      label={`${t('CompanyName')}*`}
                      variant="outlined"
                      style={{ marginBottom:'8px', width: '100%' }}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="billingStreetNumber"
                      name="billingStreetNumber"
                      value={values.billingStreetNumber}
                      error={Boolean(touched.billingStreetNumber && errors.billingStreetNumber)}
                      helperText={touched.billingStreetNumber && errors.billingStreetNumber}
                      onChange={handleChange}
                      label={`${t('BusinessAdress')}*`}
                      variant="outlined"
                      style={{ marginBottom:'8px', width: '100%' }}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="City"
                      name="billingCity"
                      value={values.billingCity}
                      error={Boolean(touched.billingCity && errors.billingCity)}
                      helperText={touched.billingCity && errors.billingCity}
                      onChange={handleChange}
                      label={`${t('City')}*`}
                      variant="outlined"
                      style={{ marginBottom:'8px', width: '100%' }}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <TextField 
                      size="small"
                      id="billingZipCode"
                      name="billingZipCode"
                      value={values.billingZipCode}
                      error={Boolean(touched.billingZipCode && errors.billingZipCode)}
                      helperText={touched.billingZipCode && errors.billingZipCode}
                      onChange={handleChange}
                      label={`${t('ZipCode')}*`}
                      variant="outlined"
                      style={{ marginBottom:'8px', width: '100%' }}
                      inputProps={{
                        style: {
                          height: '30px',
                          backgroundColor: '#fff',
                          padding: '4px 0px 4px 8px',
                        },
                      }}
                    />
                    </Grid>
                    <Grid item xs={5}  style={{ margin: '0px 12px 12px 12px' }}>
                    <FormikSelect
                        name="billingCountry"
                        value={values.billingCountry}
                        placeholder={t('Country')}
                        style={{ width: '100%', borderRadius: 0, textAlign: 'start' }}
                        error={Boolean(touched.billingCountry && errors.billingCountry)}
                        setFieldValue={setFieldValue}
                        options={countriesList}
                      />
                    </Grid>
                    </Grid>
                      </form>
                  </div>        
          }
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px' }}>

              <FontAwesomeIcon onClick={goBack} style={{ position: 'relative', marginRight:'8px', cursor: 'pointer', top: '12px'   }} color='#c122fa' icon={faChevronLeft} />
            <Button variant="contained" type="submit">
            {t('next')}
              </Button>
              </div>
          </form>
      );
    }}
        </Formik>
  );
}
