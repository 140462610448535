import React from 'react';
import './index.css';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

const StyledRating = styled(Rating)({
  '& .Mui-disabled': {
    opacity: 1,
  },
});
  
const StoreHeader = (props: any) => {
  const { t } = useTranslation();
  const { logo, analytics, color } = props;
  return <Grid container className='store-header'>
    <Grid xs={0} md={4} item></Grid>
    <Grid xs={6} md={4} item>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      {logo && <img src={logo} style={{ maxHeight: '76px' }} />}
      </div>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'end' }} xs={6} md={4} item>
      {analytics && <div style={{ display: 'flex', marginTop: '18px' }}>
              <div style={{ fontSize: '26px', fontWeight: 'bold', marginRight: '20px' }}>
                  {!!analytics && (analytics.globalNote || 0)} / 5
                  </div>
              <div style={{ marginTop: '6px' }}>
              <StyledRating
                    readOnly
                    name="customized-color"
                    value={analytics.globalNote || 0}
                    precision={0.1}
                    style={{ marginTop: '3px' }}
                    icon={<FontAwesomeIcon color={color} icon={faSolidStar} style={{ height: '20px', width: '20px', marginRight: '4px' }}/>}
                    emptyIcon={<FontAwesomeIcon color={color} style={{ height: '20px', width: '20px', marginRight: '4px' }} icon={faStar}  />}
                />
                  </div>
                  <div style={{ fontSize: '18px', color: '#21062E', marginTop: '6px', marginLeft: '15px' }}>
                {t('on')} {analytics.notesCount} {t('ratings')}
                </div>
              </div>
        }
      </Grid>
  </Grid>;
};

export default StoreHeader;