import AccountModel from '../../models/Account';

const initialState: AccountModel = {
  email: '',
  civility: '',
  lastName: '',
  firstName: '',
  businessName: '',
  country: '',
  siret: '',
  tva: '',
  phone: '',
  mobile: '',
  website: '',
  streetNumber: '',
  city: '',
  zipCode: '',
  isProfessional: '',
  isBillingAddress: false,
  billingStreetNumber: '',
  billingCity: '',
  billingBusinessName: '',
  billingZipCode: '',
  billingCountry: '',
  businessSector: [],
  newsletter: false,
  didAccept: false,
  _id: '',
  role: '',
  billingAddress: {
    billingBusinessName: '',
    billingStreetNumber: '',
    billingCity: '',
    billingZipCode: '',
    billingCountry: '',
  },
  accountType: 'Particulier',
};

export default initialState;