import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import OfferPage from '../../views/OfferPage';
import OffersListPage from '../../views/OffersListPage';
import MainLayout from '../MainLayout';
import HomePage from '../../views/Home/HomePage';
import Subscription from '../../views/Subscription';
import PromoteOffer from '../../views/PromoteOffer.tsx';
import SubscriptionCreated from '../../views/SubscriptionCreated';
import ConfirmEmail from '../../views/ConfirmEmail';
import EmailConfirmed from '../../views/EmailConfirmed';
import EmailRejected from '../../views/emailRejected';
import Store from '../../views/Store';
import LegalNotice from '../../views/LegalNotice';
import TermsConditions from '../../views/TermsConditions';
import PrivacyPolicy from '../../views/PrivacyPolicy';

const AppRouter = () => {

  return (
    <MainLayout>
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/annonce/:id" element={<OfferPage />} />
        <Route path="/annonces" element={<OffersListPage />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/promote-offer/:offerId" element={<PromoteOffer />} />
        <Route path="/subscription-created" element={<SubscriptionCreated />} />
        <Route path="/login-actions/action-token" element={<ConfirmEmail />} />
        <Route path="/account/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/account/email-rejected" element={<EmailRejected />} />
        <Route path="/legal-notice" element={<LegalNotice/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/terms-conditions" element={<TermsConditions/>} />
        <Route path="/store/:domain" element={<Store />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Suspense>
    </MainLayout>
  );
};

export default AppRouter;
