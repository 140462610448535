import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

export default function CheckboxLabels(props: any) {
  const { label, setFieldValue, fieldName, value } = props;
  const handleChange = () => {
    setFieldValue(fieldName, !value);
  };
  return (
    <FormGroup>
      <FormControlLabel control={<Checkbox defaultChecked checked={value} onChange={handleChange} />} label={label || ''} />
    </FormGroup>
  );
}

CheckboxLabels.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  fieldName: PropTypes.string,
};
