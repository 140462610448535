import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Tabs from '../../components/Tabs/Tabs';
import ProductForm from '../../views/Home/ProductForm';
import ProductUpdateForm from '../../views/OfferEditForm';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import MainLayout from '../MainLayout';
import { useAppSelector } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../views/Dashboard';
import Inbox from '../../views/Inbox';
import MyOffers from '../../views/MyOffers';
import Favorites from '../../views/Favorites';
import Payment from '../../views/Payment' ;
import MyData from '../../views/MyData/MyData';
import Evaluations from '../../views/Evaluations';
import Invoices from '../../views/Invoices';
import Subscription from '../../views/Subscription';
import ShopCustomization from '../../views/ShopCustomization';
import LegalNotice from '../../views/LegalNotice';
import PrivacyPolicy from '../../views/PrivacyPolicy';
import TermsConditions from '../../views/TermsConditions';

const AppRouter = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const { role } = useAppSelector(state => state.account);
  const sellerSections = [
    { label: t('Dashboard'), link: '/dashboard/dashboard' },
    { label: t('Annonce'), link: '/dashboard/annonce' },
    { label: t('Myspace'), link: '/dashboard/shop-customization' },
    { label: t('Data'), link: '/dashboard/mydata' },
    { label: t('Messages'), link: '/messages' },
    { label:t('Favorites'), link: '/dashboard/favorites' },
    { label:t('Evaluations'), link: '/dashboard/evaluations' },
    { label:t('Subscription'), link: '/dashboard/subscription' },
    { label: t('Invoicing'), link: '/dashboard/invoicing' },
  ];
  const buyerSections = [
    { label: t('Data'), link: '/dashboard/mydata' },
    { label: t('Messages'), link: '/messages' },
    { label:t('Favorites'), link: '/dashboard/favorites' },
    { label:t('Subscription'), link: '/dashboard/subscription' },
    { label: t('Invoicing'), link: '/dashboard/invoicing' },
  ];
  const visitorSections = [
    { label: t('Annonce'), disabled: true },
    { label: t('Messages'), disabled: true },
    { label: t('Data'), link: '/dashboard/mydata' },
    { label:t('Favorites'), link: '/dashboard/favorites' },
    { label:t('Subscription'), link: '/dashboard/subscription' },
  ];
  const sectionsObj: any = {
    buyer: buyerSections,
    seller: sellerSections,
    visitor: visitorSections,
  };
  const sections = !!role && sectionsObj[role];
  const [selectedTab, setSelectedTab] = React.useState(0);
  React.useEffect(() => {
    if (sections) {
      const pathName = location.pathname;
      const splitted = pathName.split('/');
      const last = splitted[splitted.length - 1];
      const index = sections.findIndex((e: any) => {
        if (!e.disabled) {
          const lastLink = e.link.split('/');
          return lastLink[lastLink.length - 1 ] === last;
        } return false;
      });
      setSelectedTab(index);
    }
  }, [location, sections]);
  React.useEffect(() => {
    if ( !isAuthenticated ) {
      navigate('/');
    }
  }, [isAuthenticated]);
  return (
    <MainLayout>
      <Box sx={{  }} >
        {sections && sections.length > 0 && <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} sections={sections}/> }
      </Box>
      <Suspense fallback={<div>Loading...</div>}>
        { isAuthenticated && <Routes>
          <Route path="/annonce" element={<MyOffers />} />
          <Route path="/annonce/form" element={<ProductForm />} />
          <Route path="/annonce/edit/:id" element={<ProductUpdateForm />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/messages" element={<Inbox />} />
          <Route path="/payment/:planid" element={<Payment />} />
          <Route path="/favorites" element={<Favorites />} />
          <Route path="/mydata" element={<MyData />} />
          <Route path="/evaluations" element={<Evaluations />} />
          <Route path="/invoicing" element={<Invoices />}/>
          <Route path="/subscription" element={<Subscription/>} />
          <Route path="/legal-notice" element={<LegalNotice/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/terms-conditions" element={<TermsConditions/>} />
          <Route path="shop-customization" element={<ShopCustomization />} />
          </Routes>
        }
      </Suspense>
    </MainLayout>
  );
};

export default AppRouter;
