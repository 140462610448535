import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogTitle,
  Dialog,
  InputAdornment,
  Link,
  DialogActions,
  Typography,
} from '@mui/material';
import TextField from '../../../components/textField';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import Button from '../../../components/Button';
import CreateAccountImg from '../../../assets/createAccount.svg';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../../api/auth';
import SuccessDialog from '../../../components/SuccessDialog';
import { useAppDispatch } from '../../../store/hooks';
import { authenticate } from '../../../store/User/userReducer';
export default function SimpleDialog(props: any) {
  const { setOpen, open, openSignupPopup } = props;
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const [ openFailDialog, setOpenFailDialog] = React.useState(false);
  const [ openNoverifiedDialog, setOpenNoverifiedDialog] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle style={{ backgroundColor: '#F5F9F8' }}>
        <div style={{ textAlign: 'center', marginBottom:'30px' }}>Insciption</div>
        {t('')}
        <div>
      </div>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#F5F9F8' }}>
      <Formik
    enableReinitialize
    initialValues={{
      email: '',
      password: '',
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string().email(t('emailValidation')).max(255).required(t('mandatoryRow')),
      password: Yup.string().max(255).required(t('mandatoryRow')),
    })}
    onSubmit={async ( { email, password } ) => {
      try {
        const res = await login({ email, password });
        if ( res && res.data && res.data.access_token ) {
          dispatch(authenticate({
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          }));
          setOpenSuccessDialog(true);
        } else {
          setOpenNoverifiedDialog(true);
        }
      } catch (error) {
        console.log('errr', error);
        setOpenFailDialog(true);
      }
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
            <div style={{ padding:'16px' }}>
              <div>
            <TextField 
              size="small"
              id="email"
              label="Email"
              type="email "
              variant="outlined"
              error={Boolean(touched.email && errors.email)}
              autoFocus
              name="email"
              onChange={handleChange}
              value={values.email}  
              style={{ marginBottom:'8px' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                  minWidth: '250px',
                },
              }}
              InputProps={{
                style:{ backgroundColor: '#fff' },
                startAdornment: <InputAdornment position="start"><AccountCircleOutlinedIcon /></InputAdornment>,
              }}
            />
            </div>
            <div>
            <TextField 
              size="small"
              id="password"
              label={t('password')}
              type="password"
              variant="outlined"
              style={{ marginBottom:'8px' }}
              name="password"
              error={Boolean(touched.password && errors.password)}
              onChange={handleChange}
              value={values.password}  
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                  minWidth: '250px',
                },
              }}
              InputProps={{
                style:{ backgroundColor: '#fff' },
                startAdornment: (
                  <InputAdornment position="start">
                    <KeyOutlinedIcon style={{ transform: 'rotate(90deg) scale(1, -1)' }}/>
                  </InputAdornment>
                ),            
              }}
            />
            </div>
            <div>
              <Link style={{ alignSelf:'flex-start', padding:'9px 0 9px 0', fontSize:'11px', marginTop:'8px', fontWeight: 'bold' }} color='primary' href="#"  underline="none">{t('ForgottenPassword')}</Link>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px' }}>
          <Button variant="contained" type="submit" style={{ fontWeight: 'bold' }}>
              {t('Connect')}
              </Button>
              </div>
          </form>
      );
    }
}
</Formik>
          </DialogContent>
          <DialogActions style={{ backgroundColor: '#e6f4f5', justifyContent: 'center', display:'flex', flexDirection:'column' }}>
            <div style={{ marginBottom: '16px' }}>
            <Typography variant="caption" >
              Pas encore inscrit ? Créez un compte
              </Typography>
              </div>
              <div onClick={openSignupPopup} style={{ position: 'relative', cursor:'pointer' }}>
                  <img id="image" width="128px" height="34px" alt="Login"
                      src={CreateAccountImg}/>
                <span style={{ zIndex: 15, fontSize:'13px', color: 'black', position: 'absolute', width: '100%', top:'10px', left: '14px', whiteSpace: 'nowrap' }}>{t('Signup')}</span>
                  </div>
            </DialogActions>
            <SuccessDialog
              title={t('LoggedInTitle')}
              sentence={t('LoggedInTitle')}
              open={openSuccessDialog}
              setOpen={setOpenSuccessDialog}
              >
            </SuccessDialog>
            <SuccessDialog
              title={t('wrongCredentials')}
              sentence={t('wrongCredentials')}
              open={openFailDialog}
              setOpen={setOpenFailDialog}
              >
            </SuccessDialog>
            <SuccessDialog
              title={t('emailNotVerfied')}
              sentence={t('checkEmail')}
              open={openNoverifiedDialog}
              setOpen={setOpenNoverifiedDialog}
              >
            </SuccessDialog>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  openSignupPopup: PropTypes.func.isRequired,
};
