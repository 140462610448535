import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



const EmailConfirmed = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>

        <div style={{ color: '#5ED5C8', fontSize: '24px', fontWeight: 'bold', marginTop: '70px' }}>
        {t('congratsAcc')}
            </div>
            <div style={{ color: '#363636', fontSize: '18px', marginTop: '20px' }}>
            {t('youcanConnect')}
            </div>
          <div style={{ width: '100%', margin: 'auto', display: 'flex', marginTop: '140px', justifyContent: 'center' }}>
          <div style={{
            height: '57px',
            color: '#fff',
            backgroundColor: '#BF23F9',
            fontSize: '18px',
            lineHeight: '57px',
            cursor: 'pointer',
            width: '310px',
          }}
          onClick={() => { navigate('/'); }}
          >
            {t('BackToHomePage')}
            </div>
            </div>
        </div>;
};
export default EmailConfirmed;