import React from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import ProductCard from '../../components/productCard';
const ContactSection = (props: any) => {
  const { t } = useTranslation();
  const { recommendedProdcuts, color } = props;
  return <div className='seller-products-store'>
      <div className='seller-offers-title'>
        {t('sellerOffers')}
          </div>
      <div className='products-container-shop'>
        { recommendedProdcuts && recommendedProdcuts.length > 0 &&
        recommendedProdcuts.map((offer: any) => (
            <ProductCard
            style={{ marginRight: '0px' }}
            noBorder
            data={offer}
            color={color}
            />
        ),
        )}
  </div>
  </div>;
};

export default ContactSection;