import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// import FormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../store/hooks';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

const BpIcon = styled('span')(({ theme }) => ({
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#63D4C7',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#CDE9E6',
  },
});
interface CheckBoxFilterGroupProps {
  filterName: string,
  filterData: any[],
  values: any,
  action: any,
  navigate: any,
  allData: any[],
}

const CheckBoxFilterGroup = ( props: CheckBoxFilterGroupProps) => {
  const { filterName, filterData, values, action, navigate, allData } = props;
  const dispatch = useAppDispatch();
  const onChange = (value: string) => {
    let nV = [];
    if (values.indexOf(value) > -1) {
      nV = values.filter( (e:string) => e !== value);
    } else {
      nV = [...values, value];
    }
    dispatch(action({ values: nV, navigate }));
  };
  const { t } = useTranslation();
  function Autocompletes(pr: any) {
    const { data } = pr;
    console.log('data', data);
    const countries = t('CountriesList', { returnObjects: true });
    const [inputValue, setInputValue] = React.useState('');

    return (
      <Autocomplete
        id="combo-box-demo"

        onChange={(event, nvalue:any) => {
          onChange(nvalue.value);
          setInputValue('');
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}    
        options={data}
        getOptionLabel={(option: any) => countries[option.value]}
        sx={{ 
          marginBottom: '30px',
          '& .MuiOutlinedInput-root': {
            padding: '0px',
            borderRadius: '15px',
            height: '30px',
            backgroundColor: '#fff',
            position: 'absolute',
            top: '0px',
          },
          '& .MuiInputLabel-root': {
            top: '-13px',
            position: 'absolute',
          },
        }}
        renderInput={(params) => <TextField
          {...params}
          label={t('Search')}
          />}
      />
    );
  }

  return (
    <React.Fragment>
      { filterName == 'countries' && <Autocompletes
        data={allData}
      />}
    {filterData.map((element: any) => <div style={{ textAlign: 'start' }}>
    <div style={{ display: 'flex', width:'100%', justifyContent: 'space-between' }}>
      <FormControlLabel
      control={<Checkbox
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}  
        onChange={() => onChange(element.value)}
        checked={values.indexOf(element.value) > -1} />}            
      label={element.label}
      sx={{ '& .MuiTypography-root': {
        fontSize: '14px',
        color: '#545C84',
      },
      '& .MuiCheckbox-root': {
        padding: '6px',
        marginLeft: '5px',
      },
      }}
      />
      {/* {filterName === 'categories' &&  <FontAwesomeIcon
        icon={faChevronDown}
        color='#9199C1'
        style={{
          height: '14px',
          width: '14px',
          paddingRight: '8px',
          paddingTop: '10px',
        }}
        />} */}
      </div>
      { filterName === 'categories' && <Divider sx={{ borderColor: '#CDE9E6' }}/>
    }
</div>,
    )
}
</React.Fragment>
  );
};

export default CheckBoxFilterGroup;