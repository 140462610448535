import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import logo from '../assets/logo_IZIPLACE_512-min.png';
import Grid from '@mui/material/Grid';
// import Select from './Select';
// import { CURRENCIES } from '../constants';
import LanguageSelector from './languageSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faUser, faCircle } from '@fortawesome/free-solid-svg-icons';
import SearchBox from './SearchBox';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { logout } from '../store/User/userReducer';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { getUnread } from '../api/messaging';
import { setUnread } from '../store/Chat/ChatReducer';
import { getFavorites } from '../api/favorites';
import { setFavorites } from '../store/Favorites/FavoritesReduce';
import { getPlans } from '../api/payment';
import { setPlans } from '../store/Subscription/SubscriptionReducer';
export default function Header(props: any) {
  const { noSearch } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const splitted = location.pathname.split('/');
  let path: string = '';
  if (splitted && splitted.length > 0) {
    path = splitted[1];
  }
  const accountData = useAppSelector((state) => state.account);
  const { t } = useTranslation();
  // const currencyList = CURRENCIES.map( (e, index)=> ({ label: `${e.symbol} ${e.code}`, value: `${index}` }));
  const subscribeText:any = (accountData?.role === 'visitor') ? t('SubscribeToUnlock') : '';

  const onChangeStatus = () => {
    if (path !== 'dashboard' && accountData && accountData.role) {
      if (accountData.role === 'seller') {
        navigate('/dashboard/dashboard');
      } else if (accountData.role === 'buyer') {
        navigate('/dashboard/mydata');
      } else if (accountData.role === 'visitor') {
        navigate('/dashboard/mydata');
      }
    } else {
      navigate('/');
    }
  };
  const fetchUnreadConversations = async () => {
    const unr : any = await getUnread();
    dispatch(setUnread(unr));
  };
  const fetchFavorites = async () => {
    const favorites : any = await getFavorites();
    dispatch(setFavorites(favorites));
  };
  React.useEffect(() => {
    fetchFavorites();
  }, []);
  const { unread } = useAppSelector(state => state.chat);
  React.useEffect(() => {
    if (location && location.pathname && location.pathname.split('/')[1] !== 'messages'){
      fetchUnreadConversations();
    }
  }, [location]);
  const fetchPlans = async () => {
    const plans : any = await getPlans();
    dispatch(setPlans(plans));
  };
  React.useEffect(() => {
    fetchPlans();
  }, []);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };
  return (
    <div>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ marginBottom: '20px', boxShadow: 'none' }} position="static">
          <div style={{ backgroundColor: '#fff', height: '80px', padding:'0 6.6% 0 6.6%'  }} >
        <Grid container>
          <Grid item xs={2} md={2} lg={2}>
        <div style={{ textAlign: 'left', paddingTop:'8px' }}>
        <img src={logo} onClick={() => navigate('/')} style={{ cursor: 'pointer' }}  width="200px" height="60px"/>
        </div>
        </Grid>
        <Grid item xs={2} md={2} lg={2} />
              <Grid sx={{ display:{ xs: 'none' } }} style={{ display: 'flex', paddingTop:'24px', flexDirection: 'column', alignItems: 'center' }} item xs={3} md={3} lg={3}>
              <div style={{ color: '#22062C', width: '100%', fontSize: '14px' }}>
                  {t('Hello')} {accountData.firstName}
                  </div>
                  {accountData && accountData.role === 'seller' &&
                  <div>
                  <Stack direction="row"  spacing={1} alignItems="center">
                  <Typography style={{ fontSize: '14px' }} color={ (path !== 'dashboard') ? '#22062E' : 'primary' } >{t('sell')}</Typography>
                  <Switch checked={ path !== 'dashboard'} onChange={onChangeStatus} inputProps={{ 'aria-label': 'ant design' }} />
                  <Typography  style={{ fontSize: '14px' }} color={ !(path !== 'dashboard') ? '#22062E' : 'primary' }>{t('buy')}</Typography>
                </Stack>
                  </div>
                  }
              </Grid>
              <Grid style={{ display: 'flex', alignSelf: 'flex-end', transform: 'translate(0px, 6px)' }} item xs={3} md={3} lg={3} />
              <Grid item style={ { height: '18px', paddingTop: '18px'  }} xs={2} md={2} lg={2}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
              <div style={{ display: 'flex', marginRight: '30px', minWidth: '89px' }}>
              <div
                style={{
                  paddingLeft: '13px',
                  cursor: 'pointer',
                }}
              onClick={(e: any) => {setAnchorEl(e.currentTarget); setOpen(true); }}>
              <FontAwesomeIcon
                icon={faUser}
                color='#afb5d1'
                style={{ verticalAlign: 'top' }}
                />
              {/* <FontAwesomeIcon
                icon={faCircle}
                color='#F62C79'
                style={{ height: '6px', width: '6px', verticalAlign: 'top' }}
                /> */}
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem style={{ fontSize: '14px' }} onClick={() => {
                    if (accountData.role === 'seller') {
                      navigate('/dashboard/dashboard');
                    } else if (accountData.role === 'buyer' || accountData.role === 'visitor') {navigate('/dashboard/mydata'); }
                    handleClose(); 
                  }}>{t('Account')}</MenuItem>
                  <MenuItem style={{ fontSize: '14px' }}  onClick={() => { dispatch(logout({ navigate })); handleClose(); }}>{t('logout')}</MenuItem>
                </Menu>
                {/* <FontAwesomeIcon
                icon={faBell}
                color='#afb5d1'
                style={{
                  paddingLeft: '13px',
                  cursor: 'pointer',
                }}
                /> */}
                <FontAwesomeIcon
                icon={faHeart}
                color='#afb5d1'
                style={{
                  paddingLeft: '13px',
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/dashboard/favorites')}
                />
                </div>
              <div style={{ display: 'flex', transform: 'translate(0, -5px)' }}>
                {/* <Select name='currency' data={currencyList} /> */}
                <LanguageSelector />
                </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                {accountData?.role !== 'buyer' &&
                <Tooltip title={subscribeText}>
                <div
                onClick={() => { if (accountData?.role !== 'visitor') { navigate('/dashboard/annonce');}}}
                style={{ whiteSpace:'nowrap',
                  color: '#22062C',
                  fontSize: '15px',
                  opacity: accountData?.role !== 'visitor' ? 1 : 0.5,
                  marginLeft: '16px',
                  cursor:'pointer',
                }}>
                    {t('Annonce')}
                    </div>
                    </Tooltip>
                  }
                    {
                      <Tooltip
                      title={subscribeText}
                      >
                    <div
                    onClick={() => { if (accountData?.role !== 'visitor') { navigate('/messages');}}}
                    style={{ color: '#22062C',
                      opacity: accountData?.role !== 'visitor' ? 1 : 0.5,
                      fontSize: '15px', marginLeft: '16px', cursor:'pointer', display: 'relative' }}>
                        <span>Messages</span>
                        {accountData?.role !== 'visitor' && !!unread && unread.length > 0 && 
                        <FontAwesomeIcon
                          icon={faCircle}
                          color='#F62C79'
                          style={{ height: '6px', width: '6px', verticalAlign: 'top', marginTop: '4px', position: 'absolute' }}
                          />
                        }
                    </div>
                    </Tooltip>
                    }
                    <div
                    onClick={() => navigate('/subscription')}
                    style={{ color: '#22062C', fontSize: '15px', marginLeft: '16px', cursor:'pointer'  }}>
                        {t('Subscription')}
                    </div>                
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
              </Grid>
              </Grid>
              </div>
              {!noSearch &&
              <div style={{ backgroundColor: '#3E3C41', height: '50px', padding:'0 6.6% 0 6.6%'  }} >
                  <SearchBox />
              </div>
              }
      </AppBar>
    </Box>
    </div>
  );
}

