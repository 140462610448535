import * as React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CreateAccountImg from '../../../assets/createAccount.svg';
export default function AccountForm(props: any) {
  const { close } = props;
  const { t } = useTranslation();
  return (
      <div style={{ backgroundColor: '#F5F9F8', border:'1px #e9efef solid', textAlign: 'center' }}>
        <div >
          <Typography style={{ padding:'16px 0 16px 0', fontSize:'26px', color: '#22062E' }} variant='h6'>
            {t('Congrats')}
            </Typography>
          </div>
          <div >
          <Typography style={{
            padding: '16px 0px',
            fontSize: '18px',
            color: 'rgb(145, 153, 193)',
            width: '45%',
            textAlign: 'center',
            margin: 'auto',
          }} variant='h6'>
            {t('CloserToOpportunities')}
            </Typography>
            <Typography style={{
              padding: '16px 0px',
              fontSize: '18px',
              color: 'rgb(145, 153, 193)',
              width: '45%',
              textAlign: 'center',
              margin: 'auto',
            }} variant='h6'>
            {t('ActivateAccountHelper')}
            </Typography>
          </div>
          <div>
          </div>
              <div style={{
                position: 'relative',
                cursor: 'pointer',
                margin: '40px auto 40px auto',
              }}
              onClick={() => close()}
              >
                  <img id="image" height="50px" alt="Login"
                      src={CreateAccountImg}/>
                <span style={{ zIndex: 15, fontSize:'16px', color: 'black', position: 'absolute', width: '100%', top:'12px', left: '0%', whiteSpace: 'nowrap' }}>{t('BackToHomePage')}</span>
                  </div>
          </div>
  );
}
