import axios from 'axios';

export const sendMessage = async (content: any, offerId:any, attachment:any) => {
  const accessToken = localStorage.getItem('access_token');
  const formData = new FormData;
  formData.append('content', content);
  formData.append('offerId', offerId);
  if (attachment) {
    formData.append('attachment', attachment);
  }
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/messages`,
      formData,
      { headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data',
      },
      });
    return data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const putMessage = async (content: any, conversationId:any, attachment:any) => {
  const accessToken = localStorage.getItem('access_token');
  const formData = new FormData;
  formData.append('content', content);
  if (attachment) {
    formData.append('attachment', attachment);
  }
  const { data } = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/conversations/${conversationId}/messages`,
    formData,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'multipart/form-data',
    },
    });
  return data;
};
  

export const getAllConversations = async (status: any, answered: any = null) => {
  const accessToken = localStorage.getItem('access_token');
  let query = '?';
  if (status) {
    query = `?status=${status}`;
  }
  if (answered !== null) {
    query = query === '?' ? `${query}answered=${answered}` : `${query}&answered=${answered}`;
  }
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/conversations${query}`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};

export const getUnread = async () => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/conversations?answered=false`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};

export const getOneConversations = async (conversationId: any) => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/conversations/${conversationId}/messages`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};

export const updateConversationStatus = async (conversationIds: any, status: any) => {
  const accessToken = localStorage.getItem('access_token');
  const query = `?status=${status}&${conversationIds.map((n: any, index: number) => `conversationId[${index}]=${n}`).join('&')}`;
  const { data } = await axios.patch(`${process.env.REACT_APP_API_BASE_URL}/api/conversations${query}`,
    {},
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};
  
  
  