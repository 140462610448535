import React from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import { getSubscription } from '../../api/payment';
import { cancelSubscription } from '../../api/payment';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
const Subscription = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [canceling, setCanceling] = React.useState('none');
  const [values, setValues] = React.useState<any>(null);
  const [subscriptionDetails, setSubscriptionDetails] = React.useState<any>(null);
  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getSubscription();
      setSubscriptionDetails(data);
    };
    fetchData();
  }, []);
  const fields = [
    'subsFormula',
    'subsAmount',
    'invoicingAdress',
    'invoicingEmail',
    'paymentMehtod',
    '',
    'status',
    subscriptionDetails?.cancelAtPeriodEnd !== undefined && !subscriptionDetails?.cancelAtPeriodEnd ? 'nextPaymentat' : '', 
  ];

  React.useEffect(() => {
    if (subscriptionDetails) {
      const {
        subscriptionName,
        currentPeriodEnd,
        amount,
        // currency,
        interval,
        paymentMethod,
        // customerName,
        customerEmail,
        customerAddress,
        status,
        cancelAtPeriodEnd,
      } = subscriptionDetails;
      setValues([
        t(subscriptionName),
        `${amount}€ / ${t(interval)}`,
        `${customerAddress.line1}, ${customerAddress.postal_code} ${customerAddress.city}`,
        customerEmail,
        paymentMethod?.card ? `${paymentMethod.card.brand}` : 'SEPA',
        paymentMethod?.card ? ` ${t('expire')} ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}` : `${t('bankCode')} ${paymentMethod.sepa_debit.bank_code}`,
        cancelAtPeriodEnd ? `${t('ends')} ${moment.unix(currentPeriodEnd).format('DD/MM/YYYY')}` : `${status}`,
        !cancelAtPeriodEnd ? moment.unix(currentPeriodEnd).format('DD/MM/YYYY') : '',
      ]);
    }
  }, [subscriptionDetails]);

  const onCancel = async (id: any) => {
    try {
      cancelSubscription(id);
      enqueueSnackbar(t('subscriptionResil'), {
        variant: 'success',
      });
      setCanceling('canceled');
    } catch (err) {
      enqueueSnackbar(t('error'), {
        variant: 'error',
      });

    } 
  };
  return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
    {canceling === 'none' && <React.Fragment>
      <div style={{ display: 'flex', marginTop: '50px', width: '100%', justifyContent: 'center', textTransform: 'none' }}>
      <div style={{ width: '250px', textAlign: 'start' }}>
          {fields.map((e: any, index: number) => <div style={{
            color: '#B2B2B2',
            fontSize: '18px',
            marginBottom: index % 2 === 0 ? '0px' : '20px',
          }}>{t(e)}{e !== '' && ':'}&nbsp;</div>)}
          </div>
          <div style={{ textAlign: 'start' }}>

          {values && values.map((e: any, index: number) => <div style={{ color: '#171717',
            fontSize: '18px',
            letterSpacing:  index < 2 ? '1px' : '0px',
            fontWeight: index < 2 ? 'bold' : 'inherit',
            marginBottom: index % 2 === 0 ? '0px' : '20px',
          }}>{e}</div>)}
          </div>
          </div>
          {subscriptionDetails && subscriptionDetails.cancelAtPeriodEnd !== true &&
          <div style={{ width: '100%', margin: 'auto', display: 'flex', marginTop: '60px', justifyContent: 'center' }}>
          <div style={{
            width: '250px',
            height: '57px',
            color: '#fff',
            backgroundColor: '#F6256D',
            fontSize: '18px',
            lineHeight: '57px',
            cursor: 'pointer',
            textTransform: 'none',
          }}
          onClick={() => {
            setCanceling('confirm');
            console.log('onCancel', onCancel);
          }
        }
          >
            {t('CancelSubsc')}
            </div>
            </div>
          }
          </React.Fragment>
        }
        {canceling === 'confirm' && <div>
          <div
            style={{
              color: '#707070',
              fontSize: '24px',
              fontWeight: 'bold',
              marginTop: '40px',
              marginBottom: '50px',

            }}          
          >
          {t('CancelSubsc')}
            </div>
            <div
            style={{
              color: '#2D2D2D',
              fontSize: '18px',
            }}
            >
            {t('sureToCancelSub')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '80px' }}>
            <div style={{
              border: '1px solid #F6256D',
              color: '#F6256D',
              fontSize: '18px',
              width: '258px',
              height: '57px',
              lineHeight: '57px',
              cursor: 'pointer',
              marginRight: '30px',
            }}
            onClick={() => onCancel(subscriptionDetails._id)}
            >
              {t('yesCancel')}
              </div>
              <div style={{
                border: '1px solid #BF23F9',
                backgroundColor: '#BF23F9',
                color: '#fff',
                fontSize: '18px',
                width: '303px',
                height: '57px',
                lineHeight: '57px',
                cursor: 'pointer',
                marginLeft: '30px',
              }}
              onClick={() => setCanceling('none')}
              >
              {t('noCancel')}
              </div>  
              </div>
          </div>
        }
        {
          canceling === 'canceled' && <div>
          <div
            style={{
              color: '#707070',
              fontSize: '24px',
              fontWeight: 'bold',
              marginTop: '40px',
              marginBottom: '50px',

            }}          
          >
            {t('cancedledSubsc')}
            </div>
            <div
            style={{
              color: '#5ED5C8',
              fontSize: '18px',
            }}
            >
            {t('yourcancelationok')}
            </div>
            <div
            style={{
              color: '#5ED5C8',
              fontSize: '18px',
            }}
            >
            {t('youwontpayanymore')}
            </div>
            <div style={{ marginTop: '80px', display: 'flex', justifyContent: 'center' }}>
            <div style={{
              border: '1px solid #BF23F9',
              backgroundColor: '#BF23F9',
              color: '#fff',
              fontSize: '18px',
              width: '303px',
              height: '57px',
              lineHeight: '57px',
              cursor: 'pointer',
            }}
            onClick={() => { navigate('/'); }}
            >
              {t('BackToHomePage')}
              </div>
              </div>
          </div>
        }
        </div>;
};
export default Subscription;