import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { faHeart as FilledStar } from '@fortawesome/free-solid-svg-icons';
import { faHeart as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import VioletRectangle from '../../assets/Rectangleviolet.png';
import { useSnackbar } from 'notistack';
import { postNote, updateNote } from '../../api/favorite';
import { useTranslation } from 'react-i18next';


function SimpleDialog(props: any) {
  const { t } = useTranslation();
  const { onClose,
    open,
    seller,
    offer,
    myRating,
    myComment,
    ratingId,
    getOffer,
  } = props;
  const StarStyle = {
    height: '35px',
    width: '35px',
    paddingRight: '5px',
    cursor: 'pointer',
  };
  const [rating, setRating] = React.useState(myRating);
  const handleClose = () => {
    onClose();
  };
  React.useEffect(() => setRating(myRating), [myRating]);
  const [mouseOverPosition, setMouseOverPosition] = React.useState(-1);
  const isFilled = (index: number) => {
    if (mouseOverPosition > -1 && index <= mouseOverPosition) return true;
    else if (mouseOverPosition > -1 && index > mouseOverPosition) return false;
    else if (rating > -1 && index <= rating ) return true;
    else if (rating > -1 && index > rating ) return false; 
  };
  const { enqueueSnackbar } = useSnackbar();
  const [comment, setComment] = React.useState(myComment);
  React.useEffect(() => setComment(myComment), [myComment]);
  const rate = async () => {
    if (rating === -1 ) return;
    try {
      let rt = rating;
      if (ratingId === null) {
        await postNote(rt, comment, seller, offer);
      } else {
        await updateNote(rt, comment, seller, offer, ratingId);
      }
      enqueueSnackbar(t('ReviewSaved'), {
        variant: 'success',
      });
      handleClose();
      getOffer();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('error'), {
        variant: 'error',
      });
      handleClose();
    }
  };

  const onChange = (value: any) => setRating(value);
  return (
    <Dialog 
    sx={{ '& .MuiDialog-paper	':{ minWidth: '413px' } }} 
    onClose={handleClose} open={open}>
      <DialogTitle style={{ margin: 'auto',
        font:'normal normal medium 24px/24px Jost',
        fontWeight: 'normal',
        fontSize: '24px',
        marginBottom: '14px',
      }}>
          {t('giveFeedback')}</DialogTitle>
      <div style={{ display: 'flex', margin: 'auto' }}>
      {[1, 2, 3, 4, 5].map((e) =><FontAwesomeIcon
            icon={isFilled(e) ? FilledStar : EmptyStar}
            color='#63D4C7'
            style={StarStyle}
            key={e}
            onMouseOver={() => setMouseOverPosition(e) }
            onMouseLeave={() => setMouseOverPosition(-1)}
            onClick={() => onChange(e)}
        />)
      }
      </div>
      <div style={{ margin: 'auto', marginTop: '21px', marginBottom: '24px' }}>
      <TextField
          id="outlined-multiline-static"
          label={t('helpOthers')}
          multiline
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ width: '356px' }}
        />
          </div>
          <div style={{ margin: 'auto', marginBottom: '22px' }}>
          <div onClick={rate} style={{ position: 'relative', top:'4px', cursor:'pointer' }}>
                  <img id="image" width="90px" height="30px" alt="Login"
                      src={VioletRectangle}/>
                <span style={{ fontSize:'14px', color: '#fff', position: 'absolute', width: '100%', top:'5px', left: '20px', whiteSpace: 'nowrap' }}>{(t('Send'))}</span>
                  </div>
              </div>

    </Dialog>
  );
}

export default SimpleDialog;