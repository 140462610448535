import * as React from 'react';
import Stepper from '../../../components/VerticalStepper';
import ProductFormContainer from './ProductFormContainer';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import './index.css';
export default function PoductAdd() {
  const { t } = useTranslation();
  const steps = [
    t('Description'),
    t('QuantityPrice'),
    t('PicturesAttachment'),
    // t('Sponsor'),
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  return <div>
    <Grid container className="container-offer">
      {/* <Grid container> */}
      <Grid sx={{ flexGrow: 1, display:{ xs: 'none', sm:'none', md:'none', lg:'inline' } }} item xs={0} lg={3} >
      <Stepper activeStep={activeStep} steps={steps} />
      </Grid>
      <Grid item xs={12} sm={12} md={9} lg={9} >
      <ProductFormContainer activeStep={activeStep} setActiveStep={setActiveStep}></ProductFormContainer>
      </Grid>
    </Grid>
    </div>;
}