import React from 'react';
import { useTranslation } from 'react-i18next';



function Payment() {
  const { t } = useTranslation();
  return <div>
        <div style={{ color: '#707070', fontSize: '24px', marginBottom: '40px', marginTop: '40px', fontWeight: 'bold' }}>
        {t('welcomeToIzi')}
          </div>
        <div style={{ color: '#5ED5C8', fontSize: '18px', marginBottom: '10px' }}>
        {t('SubsActive')}
          </div>
        <div style={{ color: '#5ED5C8', fontSize: '18px' }}>
        {t('manageSubsc')}
          </div>
        </div>;
}
export default Payment;