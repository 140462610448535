import * as React from 'react';
import CheckBox from '@mui/material/Checkbox';
import TextField from '../components/textField';
import Button from '../components/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import ConnexionButon from '../assets/connexionbuton.png';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { login } from '../api/auth';
import SuccessDialog from './SuccessDialog';
import { useAppDispatch } from '../store/hooks';
import { authenticate } from '../store/User/userReducer';

export function HeaderLoginForm(props: any) {
  const { t } = useTranslation();
  const { openLoginPopup, setIsFPDialogOpened } = props;
  const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const [openFailDialog, setOpenFailDialog] = React.useState(false);
  const [ openNoverifiedDialog, setOpenNoverifiedDialog] = React.useState(false);
  const [ rememberMe, setRememberMe] = React.useState(false);
  const dispatch = useAppDispatch();
  return (
      <Formik
    enableReinitialize
    initialValues={{
      email: '',
      password: '',
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string().email(t('emailValidation')).max(255),
      password: Yup.string().max(255),
    })}
    onSubmit={async ( { email, password } ) => {
      if (email === '' || email === '') {
        openLoginPopup();
      } else {
        try {
          const res = await login({ email, password });
          if ( res && res.data && res.data.access_token ) {
            dispatch(authenticate({
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            }));            setOpenSuccessDialog(true);
          } else {
            setOpenNoverifiedDialog(true);
          }
        } catch (error) {
          setOpenFailDialog(true);
        }
      }
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
            <Grid sx={{ display:{ xs: 'none' } }} style={{ display: 'flex' }} item xs={3} md={3} lg={3}>
          <FormGroup style={{ display: 'flex', flexDirection: 'column' }} >
            <FormControlLabel  onClick={() => setRememberMe( !rememberMe )} style={{ color: '#000', padding:'0px', fontSize:'11px' }} control={<CheckBox checked={rememberMe} defaultChecked/>}
            label={<Link style={{ alignSelf:'flex-start', padding:'9px 0 9px 0', fontSize:'11px' }} color='text.primary' href="#"  underline="none">{t('RememberMe')}</Link>} />
            <TextField 
              size="small"
              id="email"
              label="Email"
              type="email "
              error={Boolean(touched.email && errors.email)}
              name="email"
              onChange={handleChange}
              value={values.email}
              variant="outlined"
              style={{ marginRight: '5px', height: '30px', width: '210px' }}
              inputProps={{
                style: {
                  top: '-4px',
                  padding: '0px 0px 0px 8px',
                  height:'30px',
                },
              }}
              InputLabelProps={{
                style: {
                  top: '-4px',
                  padding:'0',
                },
              }}
          />
              </FormGroup>
          <FormGroup style={{ display: 'flex', flexDirection: 'column' }} >
          <Link
            onClick={() => setIsFPDialogOpened(true)}
          style={{ alignSelf:'flex-start', padding:'9px 0 5px 0', fontSize:'11px', marginTop:'8px' }} color='primary' href="#"  underline="none">{t('ForgottenPassword')}</Link>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <TextField 
              size="small"
              id="password"
              label={t('Password')}
              type="password"
              name="password"
              error={Boolean(touched.password && errors.password)}
              onChange={handleChange}
              value={values.password}
              variant="outlined"
              style={{ marginRight: '5px', height: '30px', marginTop:'4px', width: '120px' }}
              inputProps={{
                style: {
                  top: '-4px',
                  height:'30px',
                  padding: '0px 0px 0px 8px',
                },
              }}
              InputLabelProps={{
                style: {
                  top: '-4px',
                  padding:'0',
                },
              }}
          />
                <div onClick={() => handleSubmit()} style={{ position: 'relative', top:'4px', cursor:'pointer' }}>
                  <img id="image" width="90px" height="30px" alt="Login"
                      src={ConnexionButon}/>
                <span style={{ zIndex: 15, fontSize:'13px', color: '#fff', position: 'absolute', width: '100%', top:'5px', left: '0px', whiteSpace: 'nowrap' }}>{t('Connect')}</span>
                  </div>
            </div>
          </FormGroup>
              </Grid>

            <SuccessDialog
              title={t('LoggedInTitle')}
              sentence={t('LoggedInTitle')}
              open={openSuccessDialog}
              setOpen={setOpenSuccessDialog}
              >
            </SuccessDialog>
            <SuccessDialog
              title={t('wrongCredentials')}
              sentence={t('wrongCredentials')}
              open={openFailDialog}
              setOpen={setOpenFailDialog}
              >
            </SuccessDialog>
            <SuccessDialog
              title={t('emailNotVerfied')}
              sentence={t('checkEmail')}
              open={openNoverifiedDialog}
              setOpen={setOpenNoverifiedDialog}
              >
            </SuccessDialog>
          </form>
      );
    }
}
</Formik>
  );
}

export function MobileLoginForm(props: any) {
  const { setExpanded } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  return (
      <Formik
    enableReinitialize
    initialValues={{
      email: '',
      password: '',
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string().email(t('emailValidation')).max(255),
      password: Yup.string().max(255),
    })}
    onSubmit={async ( { email, password } ) => {
      try {
        const res = await login({ email, password });
        if ( res && res.data && res.data.access_token ) {
          dispatch(authenticate({
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          }));
          setExpanded(false);
        }
      } catch (error) {
        console.log('err', error);
      }

    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
        <TextField 
              size="small"
              id="email"
              label="Email"
              type="email "
              error={Boolean(touched.email && errors.email)}
              name="email"
              onChange={handleChange}
              value={values.email}
              variant="outlined"
              style={{ width:'100%', marginBottom:'8px' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
          />
            <TextField 
              size="small"
              id="password"
              label={t('password')}
              type="password"
              name="password"
              error={Boolean(touched.password && errors.password)}
              onChange={handleChange}
              value={values.password}
              variant="outlined"
              style={{ width:'100%' }}
              inputProps={{
                style: {
                  height: '30px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                },
              }}
          />
          <Button type="submit" style={{ width: '100%', marginTop:'16px' }} variant="contained">
            {t('Connect')}
            </Button>
          </form>
      );
    }
}
</Formik>
  );
}
