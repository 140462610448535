import axios from 'axios';

export const postFavorite = async (offerId: string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/favorites`,
    { offer: offerId },
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return res;
};

export const deleteFavorite = async (offerId: string) => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/favorites?offer=${offerId}`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return res;
};

export const getFavorites = async () => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/favorites`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};
    
