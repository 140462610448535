import React from 'react';
import Tabs from '../../components/simpleTabs/Tabs';
import { useTranslation } from 'react-i18next';
import Catalog from '../../assets/catalogue.png';

const fn = (props: any) => {
  const { t } = useTranslation();
  const { description, attachmentUrls } = props;
  const sections = [
    { label: t('Description'), link: '#' },
    { label: t('attachment'), link: '#' },
    // { label: t('PaymentMethods'), link: '#' },
    // { label: t('Shipping'), link: '#' },
  ];

  const [selectedTab, setSelectedTab] = React.useState(0);
  const getFileName = (fname:string) => {
    const splitted = fname.split('/');
    const name = splitted[splitted.length - 1];
    const splName = name.split('-');
    const extensionT = name.split('.');
    const extension = (extensionT && extensionT.length > 0 ) ? '.' + extensionT[extensionT.length - 1] : ''; 
    const sname = splName.slice(0, splName.length - 1);
    return `${sname.join('')}${extension}`;
  };

  return (
        <div style={{ width: '85%', margin: 'auto', minHeight: '200px' }}>
        <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} sections={sections}/>
        <div>
            { selectedTab === 0 && <p style={{ textAlign: 'start', whiteSpace: 'pre-line' }}>
                {description}
                </p>}
            {
  selectedTab === 1 &&
  attachmentUrls &&
  attachmentUrls.length > 0 &&
  attachmentUrls.map( (el: any) => {
    const attachmentName = getFileName(el);
    return <div style={{ display: 'flex', marginTop: '30px', width: '300px', justifyContent: 'center', flexDirection: 'column' }}>
  <div style={{ overflow: 'hidden', marginRight: '40px', display: 'relative' }}>
  {
    <a
    href={el}
    download={el}
    style={{ textDecoration: 'none',
      color: '#2A2A2A',
      fontSize: '16px',
    }}
    ><img src={Catalog} style={{
      maxHeight: '80px',
      maxWidth: '60px',
      wordWrap: 'break-word',
    }}
    />
    {attachmentName}
    </a>
  }
  </div>
  </div>;
  })}
  </div>
  </div>
  );
};
export default fn;