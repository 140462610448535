import React from 'react';
import Certificate from '../../assets/certificate.png';
import maestro from '../../assets/maestro.png';
import masterCard from '../../assets/masterCard.png';
import visaCard from '../../assets/visaCard.png';
import CustomSwitch from '../../components/CustomSwitch';
import { useTranslation } from 'react-i18next';

const ChosePlan = (props: any) => {
  const { t } = useTranslation();
  const { checked, setChecked } = props;
  
  const data = [
    'AccessWholesalers',
    'AccessAllOffers',
    'UnlimitedNumberInquiries',
    'EvaluateWholesalers',
    'TechnicalAssistance',
    'AutomaticTranslation',
    'PublishYourOffers',
    'DashboardTracking',
  ];
  return <div>
    <div className='plan-header'>
    <div className='section-title'>
        {t('ChooseYourPlan')}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                {t('Monthly')}
                    </div>
                    <div style={{ marginLeft: '8px', transform: 'translatey(-6px)' }}>
                     <CustomSwitch checked={checked} setChecked={setChecked}/>
                    </div>
        <div>
        {t('Yearly')}
            </div>
        </div>
          </div>
          <div className='subscription-section-subhead chose-plan-cert'>
              <img style={{ transform: 'translate(-10px, 4px)' }} src={Certificate} height='53' width='59'/>
              <div>
                  <div style={{ fontSize: '18px', color: '#C025FA', textAlign: 'center', textTransform: 'uppercase' }}>
                  {t('SatsifactionGuarantee')}
                    </div>
                    <div style={{ fontSize: '16px', color: '#22062E', textAlign: 'center' }}>
                  {t('WeAreConfident')}
                    </div>
                </div>
            </div>
            {data.map(e => <div className='row-subscription'>{t(e)}</div>)}
            <div className='section-bottom' style={{
              backgroundColor: '#F5FBFB',
              borderTop: '1px solid #CDE9E6',
              paddingTop: '20px',
            }}>
                <div style={{ color: '#22062E', fontSize: '16px' }}>
                {t('SafePayment')}
                </div>
                <div style={{ display: 'flex', width: '100%', marginTop: '20px' }}>
                <div style={{ width: '20%' }}>
                    </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '60%' }}>
                    <img height='29' width='53' src={masterCard}/>
                    <img height='30' width='50' src={maestro}/>
                    <img height='29' width='94' src={visaCard}/>
                </div>
                <div style={{ width: '20%' }}>
                    </div>
                    </div>
                </div>
      </div>;
};
export default ChosePlan;