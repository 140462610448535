import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const Card = (props) => {
  const { id, src, index, moveCard, deleteImage } = props;
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'image',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const [{}, drag, {}] = useDrag({
    type: 'image',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  return <div>
    <img
    ref={ref}
    data-handler-id={handlerId}
    style={{ marginLeft: '9px', border: 'rgba(198, 198, 198, 1) 1px solid'  }}
    height="40" width="40" src={src} />
        <FontAwesomeIcon
        onClick={() => deleteImage(index)}
        style={{ 
          position: 'absolute',
          height: '16px',
          width: '16px',
          top: '-4px',
          left: '42px',
          cursor: 'pointer',
          marginRight: '8px',
          zIndex: 4,
        }} color='rgba(248, 36, 120, 1)' icon={faTimesCircle} />
    </div>;
};

export default Card;