import React from 'react';
import './index.css';
import iconCounter from '../../assets/icons/icon_announce_counter.png';
// import iconDoor from '../../assets/icons/icon_door.png';
import iconEye from '../../assets/icons/icon_eye.png';
import iconFavorites from '../../assets/icons/icon_favorites.png';
import iconMouse from '../../assets/icons/icon_mouse.png';
import Stat from './stat';
import { useTranslation } from 'react-i18next';
import HomePageButton from '../../components/HomePageButton';
import NewAnnounceIcon from '../../assets/icons/icon_new_announce';
// import SpeakerIcon from '../../assets/icons/icon_speaker';
import StoreIcon from '../../assets/icons/icon_store';
import MessagesContainer from './MessagesContainer';
import MostVisited from './MostVisited';
import { getAnalytics } from '../../api/analytics';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setViews, setClicks, setOffersData, setFavorites } from '../../store/Analytics/analyticsReducer';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { views, clicks, offersAnalytics, favoritesCount } = useAppSelector(state => state.analytics);
  const { unread } = useAppSelector(state => state.chat);
  const statsData = [
    {
      label: t('Offers'),
      stat: offersAnalytics ? offersAnalytics.length : 0,
      icon: iconCounter,
      iconHeight: 33,
      iconWidth: 33,
      marginTop: '19px',
    },
    {
      label: t('Views'),
      stat: views,
      icon: iconEye,
      iconHeight: 25,
      iconWidth: 39,
      marginTop: '23px',


    },
    {
      label: t('Clics'),
      stat: clicks,
      icon: iconMouse,
      iconHeight: 42,
      iconWidth: 21,
      marginTop: '14px',


    },
    // {
    //   label: t('StoreVisits'),
    //   stat: 985,
    //   icon: iconDoor,
    //   iconHeight: 38,
    //   iconWidth: 36,
    //   marginTop: '16px',


    // },
    {
      label: t('Bookmarks'),
      stat: favoritesCount,
      icon: iconFavorites,
      iconHeight: 27,
      iconWidth: 28,
      marginTop: '22px',


    },
  ];
  const homPageButtonsData = [{
    label: t('CreateNewOffer'),
    color: '#F6256D',
    Icon: NewAnnounceIcon,
    action: () => navigate('/dashboard/annonce/form'),
  },
  // {
  //   label:  t('BoostVisibility'),
  //   color: '#BF23F9',
  //   Icon: SpeakerIcon,
  //   action: () => {},
  // },
  {
    label:  t('GoToMyStore'),
    color: '#5ED5C8',
    Icon: StoreIcon,
    action: () => navigate('/dashboard/shop-customization'),
  },
  ];
  const dispatch = useAppDispatch();
  const fetchData = async () => {
    const data = await getAnalytics();
    if (data) {
      dispatch(setViews(data.views));
      dispatch(setClicks(data.clicks));
      dispatch(setOffersData(data.offersAnalytics));
      dispatch(setFavorites(data.favoritesCount));
    }
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  return <div className='dashboard-container'>
      <div className='dashboard-title'>
          {t('Statistics')}
          </div>
        <div className='stats-container'>
            {
                statsData.map( (e:any) => <Stat {...e}/>)
            }
            </div>
            <div className='homepage-ctr'>
            {
                homPageButtonsData.map((e: any) => <HomePageButton {...e} />)
            }
          </div>
          { unread && unread.length > 0 &&
          <MessagesContainer
          data={unread}
          />
          }
          <MostVisited fetchData={fetchData}/>
  </div>;
};

export default Dashboard;