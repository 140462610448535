import React from 'react';
import DropZone from 'react-dropzone';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
export default function MyDropzone(ps: any) {
  const { t } = useTranslation();
  const { setFieldValue } = ps;
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [imgSrcs, setImgSrcs] = React.useState<Array<any>>([]);
  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0 || imgSrcs.length === 7) {
      return;
    }
    setFiles([...files, ...acceptedFiles]);
    setFieldValue('banner', acceptedFiles[0]);
    // setImagesList([...imagesList, ...acceptedFiles]);
    acceptedFiles.map( (file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        setImgSrcs([...imgSrcs, reader.result]);
      };
    });
  };
  const deleteImage = () => {
    setFiles([]);
    // setImagesList([]);
    setImgSrcs([]);
  };
  return (
    <React.Fragment>
  {imgSrcs.length === 0 &&
<DropZone
  onDrop={onDrop}
  accept={['image/gif', 'image/jpeg', 'image/png']}
  maxFiles={1}
  maxSize={5000000}
  >
  {(props: any) => {
    const { getRootProps, getInputProps } = props;
    return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box sx={{
          height: '70px',
          cursor: 'pointer',
          backgroundColor: '#fff' }}>
          <div style={{ padding: '0px 80px 0px 80px',
            color: '#545C84',
            textAlign: 'center',
            fontSize: '14px',
            paddingTop: '8px',
          }}
          dangerouslySetInnerHTML={{ __html: t('AddPhotoText') }}
          >
            </div>
        </Box>
      </div>
    </section>
    );
  }
  
}
</DropZone>
} {
  imgSrcs.length > 0 && <div style={{ display: 'relative' }}>
    <img src={imgSrcs[0]} style={{ maxWidth: '80%' }}/>
    <FontAwesomeIcon
        onClick={() => deleteImage()}
        style={{ 
          position: 'absolute',
          height: '30px',
          width: '30px',
          cursor: 'pointer',
          marginRight: '8px',
          zIndex: 4,
        }} color='rgba(248, 36, 120, 1)' icon={faTimesCircle} />
    </div>
}
<div style={{ color: '#BF28FA', fontSize: '12px', paddingTop:'10px', display:'flex', justifyContent: 'space-evenly' }}>
        <div>
        <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', top: '4px', marginRight:'8px'  }} color='#BF28FA' icon={faInfoCircle} />
       {`${t('LogoHelper')}`}
       </div>
       <div>
        <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', top: '4px', marginRight:'8px'  }} color='#BF28FA' icon={faInfoCircle} />
       {`${t('IdealDimension')}`}
       </div>
        </div>
</React.Fragment>
  );
}