import axios from 'axios';

export const getClientSecret = async (planId: string, promoCode: string = '') => {
  const accessToken = localStorage.getItem('access_token');
  const query = promoCode ? `?promotionCode=${promoCode}` : '';
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/plans/${planId}/subscriptions${query}`,
      {},
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
    return data;
  } catch (err: any) {
    if (err && err.response) {
      return {
        message: err?.response?.data?.message,
        error: true,
      };
    }
  }
};

export const promoteOffer = async (offerId: any, count: any) => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/offers/${offerId}/promotions?cycle=${count}`,
      {},
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
    return data;
  } catch (err: any) {
    if (err && err.response && err.response.status == 409) {
      return err.response.data;
    }
  }
};


export const getPlans = async () => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/plans`,
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
    return data;
  } catch (err: any) {
    if (err && err.response && err.response.status == 409) {
      return err.response.data;
    }
  }
};


export const getSubscription = async () => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/subscriptions`,
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
    return data;
  } catch (err: any) {
    if (err && err.response && err.response.status == 409) {
      return err.response.data;
    }
  }
};

export const cancelSubscription = async (id: any) => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios
    .put(`${process.env.REACT_APP_API_BASE_URL}/api/subscriptions/${id}?action=cancel`,
      {},
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
  return data;
};

