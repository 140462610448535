import * as React from 'react';
import './tabs.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Tabs = (props: any) => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const { sections, selectedTab, setSelectedTab } = props;
  const handleChange = (index: number) => {
    if (index !== selectedTab && !sections[index].disabled) {
      setSelectedTab(index);
      navigate(sections[index].link);
    }
  };
  const width = (100.0 / (sections.length)) - 2;
  return (
    <div className="tabContainer">
      { sections.map( (e: any, index: number) => <div
        style={{ width: `${width}%` }}
        className={selectedTab === index ? 'selected' : 'notSelected'}
        onClick={() => handleChange(index)}
       >
         <span className={e.disabled ? 'tooltip' :  'tabText'}
        //  style={{ opacity: e.disabled ? 0.5 : 1 }}
         >
        {e.label}
        { e.disabled && <span className="tooltiptext">{t('SubscribeToUnlock')}</span>}
        </span>
        </div>,
      )}
      </div>
  );
};

export default Tabs;