import * as React from 'react';
import { Typography } from '@mui/material';
import AccordionElement from '../../../components/AccordionElement';
// import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { updateUser } from '../../../api/account';
import LoadingDialog from '../../../components/loadingDialog';
import CoordinatesForm from './CoordinatesForm';
import ContactForm from './ContactForms';
import { setAccount } from '../../../store/Account/accountReducer';
import { useAppDispatch } from '../../../store/hooks';

export default function PoductAdd(props: any) {
  const { initialValues, accountId } = props;
  const [collectedFormsValue, setCollectedFormsValue] = React.useState(initialValues);
  const [ activeStep, setActiveStep ] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useAppDispatch();
  // const { t } = useTranslation();
  const update = async (data: any, userId: any) => {
    setIsLoading(true);
    const vs = { ...data };
    delete vs.isProfessional;
    const res = await updateUser(data, userId);
    dispatch(setAccount(res.data));
    setIsLoading(false);
    return res;
  };
  console.log(setActiveStep);
  const { t } = useTranslation();
  return <div>
    <Typography variant="h4" color="primary" style={{ margin:'30px 0px 30px 0px', textTransform: 'uppercase' }}>{t('Data')}</Typography>
    <AccordionElement active={activeStep === 0 || true} noPadding canBeExpanded={activeStep > 0} title={t('MyCompany')}>
    <CoordinatesForm
    update={update}
    userId={accountId}
    collectedFormsValue={collectedFormsValue} goToNextStep={() => setActiveStep(1)} setCollectedFormsValues={setCollectedFormsValue}></CoordinatesForm>
    </AccordionElement>
    <AccordionElement active={activeStep === 1 || true} canBeExpanded={activeStep > 1} noPadding whiteFooter title={t('ReferContact')}>
    <ContactForm
    update={update}
    userId={accountId}
    collectedFormsValue={collectedFormsValue} goToNextStep={() => setActiveStep(2)} setCollectedFormsValues={setCollectedFormsValue} />
    </AccordionElement>
    <div style={{ display: 'flex', width:'100%', flexBasis:'center', justifyContent: 'center', marginTop:'32px',  alignItems: 'baseline'  }}>
          {/* <Button
           onClick={() => update(collectedFormsValue, accountId)}
           disabled={ activeStep !== 2 } 
           variant="contained"
           type="submit"
           style={{ width:'27%', marginRight:'30px', marginBottom:'100px', height:'60px', fontSize:'18px' }}>
          <FontAwesomeIcon icon={faCheck} style={{ paddingRight: '8px' }} />
              {t('Submit')}
              </Button> */}
              </div>
              <LoadingDialog open={isLoading} />
  </div>;
}