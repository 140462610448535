import React from 'react';
import { useParams } from 'react-router-dom';
import { fetchProduct } from '../../api/product';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { getPlans } from '../../api/payment';
import { setPlans } from '../../store/Subscription/SubscriptionReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import PaymentForm from './PaymentForm';

const PromoteOffer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { offerId } = useParams();
  const [offer, setOffer] = React.useState<any>(null);
  const [count, setCount] = React.useState(1);
  const [submitted, setSubmitted] = React.useState(false);
  const { plans } = useAppSelector((state: any) => state.subscrption);
  React.useEffect(() => { 
    const getOffer = async (offId: any) => {
      const { data } = await fetchProduct(offId, true);
      setOffer(data);
    };
    if (offerId) {
      getOffer(offerId);
    }
  }, [offerId]);
  const fetchPlans = async () => {
    const pls : any = await getPlans();
    dispatch(setPlans(pls));
  };
  React.useEffect(() => {
    fetchPlans();
  }, []);
  const pl = plans && plans.length > 0 && plans.find( (e: any) => e.name === 'top-offer' );
  return <div>
    {!submitted && <React.Fragment>
     <Typography variant="h4" color="primary" style={{ margin:'30px 0px 30px 0px' }}>
    {`${t('Boost')} ${t('Offer')}    `}''{offer && offer.title}''
    </Typography>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}>
      <Button
      variant='contained'
      disabled={submitted}
      style={{ height: '40px', fontSize: '35px' }}
      onClick={() => {
        if (count > 1) {
          setCount(count - 1);
        }
      }
    }
      >
        -
        </Button>
        <div style={{ height: '40px', lineHeight: '40px',
          borderTop: '1px solid #c122fa',
          width: '100px',
          borderBottom: '1px solid #c122fa',
        }}>
          {count * 7} {t('Days')}
          </div>
          <Button
            style={{ height: '40px', fontSize: '35px' }}
            onClick={() => setCount(count + 1)}
            variant='contained'
            disabled={submitted}
            >
            +
        </Button>
      </div>
      <div>
      <Typography variant="h4" color="primary" style={{ margin:'30px 0px 10px 0px' }}>
      {pl && `${pl.amount * count} €`}
      </Typography>
      <Button
            style={{ height: '40px', fontSize: '20px' }}
            onClick={() => setSubmitted(true)}
            variant='contained'
            >
            {t('Submit')}
        </Button>
        </div>
        </React.Fragment>
        }
        <div>
          {submitted && pl && <PaymentForm
          offerId={offerId}
          count={count}
          offer={offer}
          amount={pl.amount}
          />}
          </div>
    </div>;
};

export default PromoteOffer;