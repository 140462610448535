import axios from 'axios';
import qs from 'query-string';

export const fetchOffers = async (filters: any, limitp: number = 0) => {
  const accessToken = localStorage.getItem('access_token');
  const {
    categories,
    productCondition,
    sellerRating,
    minQuantity,
    minPrice,
    maxPrice,
    countries,
    searchBar,
    page,
    limit,
    sort,
  } = filters;
  let onlyFilledFilters: any = {};
  if (categories.length > 0 ) onlyFilledFilters.categories = categories;
  if (productCondition.length > 0 ) onlyFilledFilters.productCondition = productCondition;
  if (countries.length > 0 ) onlyFilledFilters.countries = countries;
  if (sellerRating > -1 ) onlyFilledFilters.note = sellerRating + 1;
  if (minQuantity > 0 ) onlyFilledFilters.minQte = minQuantity;
  if (minPrice > 0 ) onlyFilledFilters.minPrice = minPrice;
  if (maxPrice > 0 ) onlyFilledFilters.maxPrice = maxPrice;
  if (searchBar) onlyFilledFilters.searchBar = searchBar;
  if (page) onlyFilledFilters.page = page;
  if (limit) onlyFilledFilters.limit = 12;
  if (limitp && limitp !== 0) onlyFilledFilters.limit = limitp;
  if (sort) onlyFilledFilters.sort = sort;
  onlyFilledFilters.topOffer = false;
  let topFilters = { ...onlyFilledFilters };
  topFilters.topOffer = true;
  topFilters.page = page;
  topFilters.sort = 'Date';
  topFilters.limit = 6;
  const query = qs.stringify(onlyFilledFilters, { arrayFormat: 'bracket' });
  const topOffersQuery = qs.stringify(topFilters, { arrayFormat: 'bracket' });
  let res, topres;
  if (accessToken) {
    res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/offers?${query}`,
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
    topres = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/offers?${topOffersQuery}`,
      { headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      });
  } else {
    res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/offers?${query}`);
    topres = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/offers?${topOffersQuery}`);
  }
  return { offers: res.data, topoffers: topres.data };
};
