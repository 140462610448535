import React from 'react';
import { useTranslation } from 'react-i18next';
import { verifyEmail } from '../../api/account';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';



const ConfirmEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const onVerify = async (token: any) => {
    try {
      await verifyEmail(token);
      navigate('/account/email-confirmed');
    } catch (err: any) {
      navigate('/account/email-rejected');
    }
  };
  const [token, setToken] = React.useState<any>(null);
  React.useEffect(() => {
    if (search && search.length > 0 ) {
      const st = search.substring(1);
      const qs = queryString.parse(st, { arrayFormat: 'bracket-separator' });
      const tokenData = qs.key;
      setToken(tokenData);
    }
  }, [search]);
  return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>

        <div style={{ color: '#5ED5C8', fontSize: '24px', fontWeight: 'bold', marginTop: '70px' }}>
        {t('oneClickAway')}
            </div>
          <div style={{ width: '100%', margin: 'auto', display: 'flex', marginTop: '80px', justifyContent: 'center' }}>
          <div style={{
            height: '57px',
            color: '#fff',
            backgroundColor: '#BF23F9',
            fontSize: '18px',
            lineHeight: '57px',
            cursor: 'pointer',
            width: '310px',
          }}
          onClick={() => onVerify(token)}
          >
          {t('clickToConfirmEmail')}
            </div>
            </div>
        </div>;
};
export default ConfirmEmail;