import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../index';
import jwt_decode from 'jwt-decode';

// Define a type for the slice state
interface User {
  name: string
  isAuthenticated: boolean
  isVisitor: boolean,
}

// Define the initial state using that type
const initialState: User = {
  name: 'Izy place Admin',
  isAuthenticated: localStorage.getItem('access_token') ? true : false,
  isVisitor: false,
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    set: (state, payload: PayloadAction<string>) => {
      state.name = payload.payload;
    },
    reset: state => {
      state.name = initialState.name;
    },
    authenticate: (state, action: any) => {
      localStorage.setItem('access_token', action.payload.accessToken);
      localStorage.setItem('refresh_token', action.payload.refreshToken);
      const tokenDate: any = (new Date()).getTime();
      localStorage.setItem('time', tokenDate);
      const decodedToken:any = jwt_decode(action.payload.accessToken);
      if (decodedToken &&
        decodedToken.resource_access &&
        decodedToken.resource_access['iziplace-client']
        && decodedToken.resource_access['iziplace-client'].roles
        && (decodedToken.resource_access['iziplace-client'].roles.indexOf('visitor') > -1
        )) {
        return ({
          ...state,
          isAuthenticated: true,
          isVisitor: true,
        });
      }
      return ({
        ...state,
        isAuthenticated: true,
      });
    },
    logout: (state, { payload }) => {
      const { navigate } = payload;
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      navigate('/');
      return ({
        ...state,
        isAuthenticated: false,
      });
    },
    logoutnew: (state) => {
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('access_token');
      return ({
        ...state,
        isAuthenticated: false,
      });
    },
    resetIsVisitor: (state) => ({
      ...state,
      isVisitor: false,
    }),
  },
});

export const { set, reset, authenticate, logout, logoutnew, resetIsVisitor } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.user.name;

export default userSlice.reducer;
