import React from 'react';
import './index.css';
import BuyerPlan from './BuyerPlan';
import ChosePlan from './ChosePlan';
import SellerPlan from './SellerPlan';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import SubscriptionDetails from './SubscriptionDetails';
// import { TextField } from '@mui/material';
import { getClientSecret } from '../../api/payment';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import LoadingDialog from '../../components/loadingDialog';
import SubscriptionModal from './subscriptionDialog';
import { setSubscripiton } from '../../store/Subscription/SubscriptionReducer';
import { getPlans } from '../../api/payment';
import { setPlans } from '../../store/Subscription/SubscriptionReducer';
import { setIsSignupDialogOpen } from '../../store/Common/CommonReducer';

const Subscription = () => {
  // const { noTransform } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const { plans } = useAppSelector(state => state.subscrption);
  const account = useAppSelector(state => state.account);
  const isSubscribed = account && (account.role === 'buyer' || account.role === 'seller');
  const [checked, setChecked] = React.useState(false);
  const [promoCode, setPromoCode] = React.useState('');
  const [subscModalOpen, setSubscModalOpen] = React.useState(false);
  const [chosenPlan, setChosenPlan] = React.useState('');
  const { isAuthenticated } = useAppSelector(state => state.user);
  const dispatch = useAppDispatch();
  const onModalOpen = (p: any) => {
    if (isAuthenticated) {
      setChosenPlan(p);
      setSubscModalOpen(true);  
    } else {
      dispatch(setIsSignupDialogOpen(true));
    }
  };
  const onSubscribe = async (code: string, planId: string) => {
    try {
      setIsLoading(true);
      const d = await getClientSecret(planId, code);
      dispatch(setSubscripiton(d));
      setIsLoading(false);
      if (d && !d.error) {
        const query = code ? `?code=${code}` : '';
        navigate('/dashboard/payment/' + planId + query );
        return true;
      } else if ( d && d.error){
        if (d.message === 'promotion code not found') {
          enqueueSnackbar(t('promoCodeInvalid'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('eror'), { variant: 'error' });
        }
      }
    } catch (err: any) {
      setIsLoading(false);
      enqueueSnackbar(t('promoCodeInvalid'), { variant: 'error' });
      return false;
    }
  };
  const fetchPlans = async () => {
    const pls : any = await getPlans();
    dispatch(setPlans(pls));
  };
  React.useEffect(() => {
    fetchPlans();
  }, []);
  return <div>
    {/* {!isSubscribed && <div style={{ height: '74px', alignItems: 'center', display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(62, 60, 65)', transform: noTransform ? 'translateY(0px)' : 'translateY(-20px)' }}>
    <div style={{ color: '#fff', fontSize:'21px', marginRight: '24px' }}>
    {t('promoCodeHelper')}
    </div>
    <div style={{ color: '#fff' }}>
    <TextField
    placeholder='PROMOCODE'
    style={{
      fontSize: '14px',
      borderRadius: '0px',
    }}
    sx={{
      '& .MuiOutlinedInput-root': {
        borderRadius: '0px',
      },
    }}
    inputProps={{
      style:{
        backgroundColor: '#fff',
        fontSize: '14px',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderRadius: '0px',
      },
    }}
    value={promoCode}
    onChange={(e: any) => {setPromoCode(e.target.value); }}
    />
    </div>
    </div>
    } */}
  <div style={{ marginBottom: '100px' }}>
            <div style={{
              margin: 'auto',
              fontSize: '26px',
              marginBottom: '20px',
              marginTop: '20px',
              // textTransform: 'uppercase',
            }}>
                {!isSubscribed && t('Subscription')}
                {isSubscribed && <SubscriptionDetails />
                  }
                </div>
              {!isSubscribed && plans && plans.length > 0 &&
            <div className='plans-container'>
                <div className='buyer-plan'>
                    <BuyerPlan
                  plans={plans}
                  checked={checked}
                  onSubscribe={onSubscribe}
                  promoCode={promoCode}
                  onModalOpen={onModalOpen}
                    />
                </div>
                <div className='chose-plan'>
                    <ChosePlan checked={checked} setChecked={setChecked}></ChosePlan>
                </div>
                <div className='seller-plan'>
                <SellerPlan
                   plans={plans}
                   checked={checked}
                   onSubscribe={onSubscribe}
                   promoCode={promoCode}
                   onModalOpen={onModalOpen}
                ></SellerPlan>
                </div>
                </div>
              }
        </div>
        {isLoading && <LoadingDialog
          open={isLoading}
        />
        }
        <SubscriptionModal
        chosenPlan={chosenPlan}
        open={subscModalOpen}
        onClose={() => setSubscModalOpen(false)}
        setPromoCode={setPromoCode}
        promoCode={promoCode}
        onSubscribe={onSubscribe}
        />
        </div>;
};
export default Subscription;