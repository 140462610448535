import React from 'react';
// import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { promoteOffer } from '../../../api/payment';
import {
  Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CircularProgress } from '@mui/material';


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const language = localStorage.getItem('i18nextLng');
const lg: any = language ? language.substring(0, 2) : 'fr';
const publishableKey : any = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(publishableKey,
  { locale: lg });
function Payment(props: any) {
  const { t } = useTranslation();
  const { offerId, count, offer } = props;
  const [isPAid, setIsPaid] = React.useState(false);
  const [data, setData] = React.useState<any>(null);
  const [options, setOptions] = React.useState<any>(null);
  console.log('data', data);
  React.useEffect(() => {
    const getClientS = async (id: any, cn: any) => {
      const d = await promoteOffer(id, cn);
      setData(d);
    };
    if (offerId) {
      getClientS(offerId, count);
    }
  }, []);

  React.useEffect(() => {
    if (data) {
      setOptions({
        clientSecret: data.clientSecret,
      });
    }
  }, [data]);
  return (
      <div style={{ padding: '0 6.6%', marginTop: '30px', margin: 'auto', width: '1000px' }}>
          {options && !isPAid &&
    <Elements stripe={stripePromise} options={options}>
        <CheckoutForm setIsPaid={setIsPaid}/>
    </Elements>
    }
    {!options && !isPAid  && <CircularProgress style={{ marginTop: '100px' }}/>}
    {
      isPAid && <div>
        <div style={{ color: '#707070', fontSize: '24px', marginBottom: '40px' }}>
        Votre annonce est promue.
          </div>
        <div style={{ color: '#5ED5C8', fontSize: '18px' }}>
          C'est bon, vous bénéficiez d'un référencement prioritaire pour 
          </div>
        <div style={{ color: '#5ED5C8', fontSize: '18px', fontWeight: 'bold' }}>
          {offer.title}
          </div>
        <div style={{ color: '#5ED5C8', fontSize: '18px', fontWeight: 'bold' }}>
          {`${(count * 7)} ${t('Jours')}`}
          </div>
        <div style={{ color: '#5ED5C8', fontSize: '18px', fontWeight: 'bold' }}>
          Date de fin : {moment().add(count * 7, 'days').format('DD/MM/YYYY')}
          </div>
        </div>
    }
    </div>
  );
}
export default Payment;