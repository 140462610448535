import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './index.css';
const Invoices = (props: any) => {
  const { data } = props;
  const { t } = useTranslation();
  const date = moment.unix(data.created).format('DD/MM/YYYY');
  const startDate = moment.unix(data.period_start).format('_YYYY_MM_DD');
  const plan: any = data?.lines?.data[0]?.description;
  let p = '';
  if (plan){
    p = plan.replace(/\s/g, '');
    let i = p.indexOf('(');
    p = p.substring(2, i);
  }
  return <div className='invoice-line'>
      <div style={{ color: '#22062E', fontSize: '16px', width: '30%', textAlign: 'start' }}>
          Facture{`${startDate}`}{p}
          </div>
          <div style={{ color: '#707070', fontSize: '16px', width: '30%', textAlign: 'center'  }}>
              {date}
          </div>
          <div style={{ color: '#22062E', fontSize: '16px', width: '30%', display: 'flex', justifyContent: 'end'  }}>
              <a href={data.invoice_pdf} download={`Facture${startDate}`} className='invoice-download'>
                  {t('Download')}
                  </a>
          </div>
  </div>;
};
export default Invoices;