import { createSlice } from '@reduxjs/toolkit';
interface Common {
  categories: any[],
  isSignupDialogOpened: boolean,
  language: string,
}

// Define the initial state using that type
const initialState: Common = {
  categories: [],
  isSignupDialogOpened: false,
  language: 'en',
};

export const commonSlice = createSlice({
  name: 'common',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCategories: (state, payload: any) => {
      return {
        ...state,
        categories: payload.payload,
      };
    },
    setLanguage: (state, payload: any) => {
      return {
        ...state,
        language: payload.payload,
      };
    },
    setIsSignupDialogOpen: (state, { payload }) => {
      return {
        ...state,
        isSignupDialogOpened: payload,
      };
    },
  },
});

export const { setCategories, setIsSignupDialogOpen, setLanguage } = commonSlice.actions;

export default commonSlice.reducer;
