import React, { useState } from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as solidHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as regularHeart } from '@fortawesome/free-regular-svg-icons';
import { faPaperPlane as faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import { CURRENCIES } from '../../constants';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import RatingDialog from './RatingDialog';
import MessageDialog from './MessageDialog';
import { getFavorites, deleteFavorite, postFavorite } from '../../api/favorites';
import { setFavorites } from '../../store/Favorites/FavoritesReduce';
import { useSnackbar } from 'notistack';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import { setIsSignupDialogOpen } from '../../store/Common/CommonReducer';

const StyledRating = styled(Rating)({
  '& .Mui-disabled': {
    opacity: 1,
  },
});


// import { useNavigate } from 'react-router-dom'; 
export default function (props: any) {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector(state => state.user);
  const { enqueueSnackbar } = useSnackbar();
  const {
    updateDate,
    price,
    minOrder,
    unit,
    brand,
    availableAmount,
    title,
    currency,
    offerNote,
    _id: id,
    seller,
    myRating,
    myComment,
    ratingId,
    getOffer,
    noLimitOfAmount,
    productCondition,
  } = props;
  // const navigate = useNavigate();
  const [ratingOpen, setRatingOpen] = useState(false);
  const { role } = useAppSelector(state => state.account);
  const date = moment(updateDate).format('DD-MM-YYYY');
  const { t } = useTranslation();
  let curr = CURRENCIES.find(e => e.code === currency);
  let currencySymbol = '';
  if (curr) {
    currencySymbol = curr.symbol;
  }
  console.log(unit);
  const units = t('UnitsSingular', { returnObjects: true });
  const unitsPlural = t('Units', { returnObjects: true });
  let unitLabel = Object.entries(units).find( (a) => a[0] === unit );
  let unitLabelPlural = Object.entries(unitsPlural).find( (a) => a[0] === unit );
  let unitF = '';
  if (unitLabel) {
    unitF = unitLabel[1];
  }
  let unitP = '';
  if (unitLabelPlural) {
    unitP = unitLabelPlural[1];
  }
  const [messageOpen, setMessageOpen] = React.useState(false);
  const data = [
    { label: `${t('minimalQuantity')}`,
      value: minOrder === 1 ? `${minOrder} ${unitF}` : `${minOrder} ${unitP}`,
    },
    { label: `${t('availableQuantity')}`,
      value: noLimitOfAmount ? t('noQuantityLimit') : availableAmount === 1 ? `${availableAmount} ${unitF}` : `${availableAmount} ${unitP}`,
    },
    { label: `${t('Brand')}`,
      value: brand,
    },
    { label: `${t('ProductCondition')}`,
      value: `${(t('ProductStatusList', { returnObjects: true }))[productCondition]}`,
    },
    { label: `${t('LastUpdate')}`,
      value: date,
    },
  ];
  const fetchFavorites = async () => {
    const favorites : any = await getFavorites();
    dispatch(setFavorites(favorites));
  };
  const addToFavorites = async (offerId: string) => {
    try {
      await postFavorite(offerId);
      fetchFavorites();
      enqueueSnackbar(t('offerAddedToFavs'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar( t('error'), { variant: 'error' });
    }
  };

  const deleteFromFavorites = async (offerId: string) => {
    try {
      await deleteFavorite(offerId);
      fetchFavorites();
      enqueueSnackbar(t('OfferDelfromFavs'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar( t('error'), { variant: 'error' });
    }
  };
  const { list } = useAppSelector(state => state.favorites);
  const isFavorite = list.length > 0 && list.find((e: any) => e.offer._id === id);
  return <div style={{ height: '100%' }}>
      <div className='description-title'>
      {title}
          </div>
          <div className='price'>
          <span >{`${price ? price.toFixed(2) : '' } ${currencySymbol}`}</span>
          <span className='unit'>/ {unitF}
              </span>
              </div>
              { seller && seller._id &&
              <RatingDialog
                open={ratingOpen}
                onClose={() => setRatingOpen(false)}
                seller={seller._id}
                offer={id}
                myRating={myRating}
                myComment={myComment}
                ratingId={ratingId}
                getOffer={getOffer}
              />
              }
            { seller && seller._id &&
              <MessageDialog
                open={messageOpen}
                onClose={() => setMessageOpen(false)}
                seller={seller._id}
                offer={id}
                myRating={myRating}
                ratingId={ratingId}
                getOffer={getOffer}
              />
              }
          <div className='rating-container'>
                <StyledRating
                    readOnly
                    name="customized-color"
                    value={offerNote || 0}
                    precision={0.1}
                    style={{ fontSize: '1em', transform: 'translateY(3px)' }}
                    icon={<FontAwesomeIcon
                      style={{ marginRight: '2px ' }}
                      color='#63D4C7' icon={solidHeart} />}
                    emptyIcon={<FontAwesomeIcon
                      style={{ marginRight: '2px ' }}
                      color='#63D4C7' icon={regularHeart}  />}
                />
              <span onClick={() => {
                if (isAuthenticated) {
                  setRatingOpen(true);
                } else {
                  dispatch(setIsSignupDialogOpen(true));
                }
              }
              } className='rate-text'>
                  {t('RateOffer')}
                  </span>
          </div>
          <div className='container-desc'>
            {data.map((e: any)=> <div className='text-container'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className='cell-title'>
                        {e.label}
                        </div>
                        <div className='cell-value'>
                        {e.value}
                        </div>
            </div>
          </div>,
            )}
          </div>
          <div className='buttons-cont'>
          <Button
          style={{ minWidth: '160px' }}
          variant={isFavorite ? 'contained' : 'outlined'}
          className='button-desc'
          onClick={() => {
            if (isAuthenticated) {
              if (isFavorite) {
                deleteFromFavorites(id);
              } else { addToFavorites(id);}
            } else {
              dispatch(setIsSignupDialogOpen(true));
            }
          }}
          >
          <FontAwesomeIcon className='button-ic'
          icon={isFavorite ? solidHeart : regularHeart }
          color={isFavorite ? '#fff' : '#BD2BFA'}
          />
              {t('Favorites')}
              </Button>
              <Button
              style={{ minWidth: '160px' }} 
              onClick={() => {
                if (isAuthenticated) {
                  setMessageOpen(true);
                } else {
                  dispatch(setIsSignupDialogOpen(true));
                }
              }}
              disabled={role === 'visitor'} variant="contained" className='button-desc'>
              <FontAwesomeIcon className='button-ic' icon={faPaperPlane} color='#fff'/>
              Message
              </Button>
          </div>
      </div>;
}