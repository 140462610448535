import axios from 'axios';

export const getInvoices = async () => {
  const accessToken = localStorage.getItem('access_token');
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/invoices`, { headers: {
    Authorization: `Bearer ${accessToken}`,
  } },
  );
  return data;
};

