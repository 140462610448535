import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { CURRENCIES } from '../../constants';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faSolidHeart, faTimes, faEye, faPencilAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import BadgeTopAnnonce from '../../assets/BadgeTopAnnonce.png';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { postFavorite, deleteFavorite, getFavorites } from '../../api/favorites';
import { useSnackbar } from 'notistack';
import { setFavorites } from '../../store/Favorites/FavoritesReduce';

const ProductCard = (props: any) => {
  let navigate = useNavigate();
  const { isAuthenticated } = useAppSelector(state => state.user);
  const { list } = useAppSelector(state => state.favorites);
  const { data,
    style,
    favorite,
    hasEye,
    canEdit,
    noBorder,
    isEyeCrossed,
    onToggle,
    color,
  } = props;
  const { t } = useTranslation();
  const units = t('UnitsSingular', { returnObjects: true });
  const unitsPlural = t('Units', { returnObjects: true });
  const { enqueueSnackbar } = useSnackbar();
  let unitLabel = Object.entries(units).find( (a) => a[0] === data.unit );
  let unitPluralLabel = Object.entries(unitsPlural).find( (a) => a[0] === data.unit );
  let unitF = '', unitP = '';
  if (unitLabel && unitPluralLabel) {
    unitF = unitLabel[1];
    unitP = unitPluralLabel[1];
  }
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  let curr = CURRENCIES.find(e => e.code === data.currency);
  let currencySymbol = '';
  if (curr) {
    currencySymbol = curr.symbol;
  }
  const dispatch = useAppDispatch();

  const fetchFavorites = async () => {
    const favorites : any = await getFavorites();
    dispatch(setFavorites(favorites));
  };

  const addToFavorites = async (offerId: string) => {
    try {
      await postFavorite(offerId);
      fetchFavorites();
      enqueueSnackbar(t('offerAddedToFavs'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar( t('error'), { variant: 'error' });
    }
  };
  const deleteFromFavorites = async (offerId: string) => {
    try {
      await deleteFavorite(offerId);
      fetchFavorites();
      enqueueSnackbar(t('OfferDelfromFavs'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar( t('error'), { variant: 'error' });
    }
  };

  const isFavorite = list.find((e: any) => !!e && !!e.offer && e.offer._id === data._id);
  const isSolid = (isFav: any, isMov: any) => {
    if (isFav && !isMov) return true;
    else if (isFav && isMov ) return false;
    else if (!isFav && !isMov) return false;
    else if (!isFav && isMov) return true;
  };
  return <div onClick={() => {
    navigate(`/annonce/${data._id}`);
    console.log(data);
  }}
  className='product-card-container hvr-border-fade'
  style={{ ...style, border: noBorder ? 'none' : '1px #CDE9E6 solid' }}>
    <div className='fav-icon'
            onMouseOver={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}    
    >
      {data.topOffer && <div className='top-offer-badge'><img src={BadgeTopAnnonce}/></div>}
      {isAuthenticated && !hasEye && <FontAwesomeIcon
        style={{ cursor: 'pointer' }}
        color={color ? color : '#C221FA'}
        onClick={(e: any) =>{
          e.stopPropagation();
          if (isFavorite || favorite) {
            deleteFromFavorites(data._id);
          } else {
            addToFavorites(data._id);
          }
        }
        }
        // icon={!isMouseOver ? faHeart : faSolidHeart}
        icon={favorite ? faTimes : isSolid(isFavorite, isMouseOver) ? faSolidHeart : faHeart}
        />
      }
      </div>
      {hasEye && <div
      onClick={(e) => {
        e.stopPropagation();
        onToggle(data._id);
      }}
      className='eye-icon'>
      <FontAwesomeIcon
            style={{ cursor: 'pointer', height: '30px', width: '30px', transform: 'translateY(8px)' }}
            color={isEyeCrossed ? '#FC8FBB' : '#AEEAE3' }
            onClick={() =>{}
            }
            icon={isEyeCrossed ? faEyeSlash : faEye}
            />
        </div>
        }
        {canEdit && <div className='edit-icon'>
        <FontAwesomeIcon
            style={{ cursor: 'pointer', height: '25px', width: '25px', transform: 'translateY(8px)' }}
            color='#A8A8A8'
            onClick={(e) => { e.stopPropagation(); navigate(`/dashboard/annonce/edit/${data._id}`); }}
            icon={faPencilAlt}
            />
        </div>
        }
      <div style={{ width:'230px', height:'230px', marginBottom: '10px', overflow: 'hidden', display: 'flex', justifyContent: 'center' }}>
          <img className='product-card-img' src={data.pictureUrls[0]} />
          </div>
          <div>
          </div>
          <div className='product-card-price' style={{ color: color ? color : '#C221FA' }}>
          {`${data.price ? data.price.toFixed(2) : '' } ${currencySymbol}`} <span className='product-card-unit'>/ {unitF}</span>
          </div>
          <div className='product-card-name'>
          {data.title > 60 ? `${data.title.substring(0, 60)}...` : data.title}
          </div>
          <div className='product-card-quantity'>
          {data.minOrder === 1 ? `${data.minOrder} ${unitF} (${t('minimalQuantity')})` : `${data.minOrder} ${unitP} (${t('minimalQuantity')})`}
          </div>
          <div className='product-card-description'>
          {data.description.length > 60 ? `${data.description.substring(0, 60)}...` : data.description}
          </div>
</div>;
};

ProductCard.defaultProps = {
  style: {},
  favorite: false,
};
export default ProductCard;