import React from 'react';
import './index.css';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import FilterSection from './FilterSection';
import { useTranslation } from 'react-i18next';
import CheckBoxFilterGroup from './CheckBoxFilterGroup';
import flags from '../Home/ProductForm/Flags';
import PriceQuantitySection from './PriceQuantitySection';
import SellerRatingFilter from './SellerRatingFilter';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import TagsList from './TagsList';
import {
  setCategories,
  setCountries,
  setFilters,
  setProductCondition,
  setResults,
  setDidReadUrl,
  setIsLoading,
} from '../../store/Search/searchReducer';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import i18n from '../../i18n';
import { fetchOffers } from '../../api/searchOffers';


const Filters = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { filters, didReadUrl } = useAppSelector(state => state.search);
  const { categories } = useAppSelector(state => state.common);
  const dispatch = useAppDispatch();
  const { language } = i18n;
  const [categoriesValues, setCategoriesValues] = React.useState([]);
  React.useEffect(() => {
    if (language && categories && categories.length > 0) {
      const languageCode = language.substr(0, 2);
      let sortedCategories = [...categories];
      sortedCategories.sort((cata: any, catb: any) => {
        const textA = cata.name[languageCode].toUpperCase();
        const textB = catb.name[languageCode].toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
      });
      const formattedCategories: any = sortedCategories.map((e: any) => ({ label: e.name[languageCode], value: e._id }));
      setCategoriesValues(formattedCategories);
    }
  }, [language, categories]);
  React.useEffect(() => {
    const getOffers = async () => {
      dispatch(setIsLoading(true));
      const data = await fetchOffers(filters);
      dispatch(setResults(data));
      dispatch(setIsLoading(false));
    };
    if (didReadUrl) {
      getOffers();
    }
    console.log(filters);
  }, [filters]);
  React.useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
      console.log(event);
      window.history.pushState(null, document.title,  window.location.href);
      //window.location.reload();
      setFilters({});
    });
    /*filters.categories = [];
    filters.countries = [];
    filters.limit = 12;
    filters.maxPrice = 0;
    filters.minPrice = 0;
    filters.minQuantity = 0;
    filters.page = 1;
    filters.productCondition = [];
    filters.searchBar = '';
    filters.sellerRating = -1;
    filters.sort = 'date';*/
  }, []);
  const productConditionsTranslations: any = t('ProductStatusList', { returnObjects: true });
  const productConditions = Object.entries(productConditionsTranslations).map(([key, value]) => ({ label: value, value: key }));
  const CountriesTranslations = t('CountriesList', { returnObjects: true });
  let countriesobjs = Object.entries(CountriesTranslations);
  countriesobjs.sort((cata: any, catb: any) => {
    const textA = cata[1].toUpperCase();
    const textB = catb[1].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
  });
  const countries = countriesobjs.map(([key, value]) => {
    const flag = flags.find(e => e.code === key);
    return ({
      label:(<div style={{ display: 'flex' }}>
        <img height='24' width='30' style={{ marginRight: '10px' }} src={flag ? flag.flag : '' }/><span>{String(value)}</span></div>
      ),
      value: key,
    }
    );
  });
  const [ctrList, setCtrList] = React.useState([countries.find( e => e.value === 'FR'), ...countries.slice(0, 4)]);
  const tagsList = [
    {
      translationName: 'categories',
      data: filters.categories,
      filterName: 'categories',
      action: setCategories,
      navigate,
    },
    { translationName: 'CountriesList',
      data: filters.countries,
      filterName: 'countries',
      action: setCountries,
      navigate,
    },
    { translationName: 'ProductStatusList',
      data: filters.productCondition,
      filterName: 'productCondition',
      action: setProductCondition,
      navigate,
    },
  ];
  let priceRange, minQuantity;
  if (filters.minPrice && filters.maxPrice) {
    priceRange = `${filters.minPrice}€---${filters.maxPrice}€`;
  }
  if (filters.minQuantity) {
    minQuantity = `>${filters.minQuantity}`;
  }
  // transform url filters to store on Init
  const [firstStime, setFirstTime] = React.useState(true);
  React.useEffect(() => {
    const { search } = location;
    if (firstStime) {
      if ( search && search.length > 0) {
        const st = search.substring(1);
        const qs = queryString.parse(st, { arrayFormat: 'bracket-separator' });
        try {
          console.log('qs', qs);
          const qq = JSON.stringify(qs);
          const aa = JSON.parse(qq);
          aa.page = parseInt(aa.page);
          aa.limit = parseInt(aa.page);
          dispatch(setFilters(aa));
        } catch (error) {
          console.log('err', error);
        }
        setFirstTime(false);
      } else {
        dispatch(setFilters({}));
        setFirstTime(false);
      }
      dispatch(setDidReadUrl(true));
    }
  }, []);
  React.useEffect(()=> {
    return function () { dispatch(setDidReadUrl(false)); };
  }, []);
  return <div className='filters-container'>
      <div className='filters-title'>
          {t('Filters')}
          </div>
          <TagsList
            tagsList={tagsList}
            priceRange={priceRange}
            minQuantity={minQuantity}
            navigate={navigate}
            />
          <Divider sx={{ borderColor: '#CDE9E6', border: 'none' }}></Divider>
          <Accordion style={{ backgroundColor:'#f5fbfb', margin:'0' }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: '#63d4c7', fontSize: '18px', maxHeight: '64px' }}
            >
              {t('Categories')}
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxFilterGroup
                filterName='categories'
                filterData={categoriesValues}
                allData={categoriesValues}
                values={filters.categories}
                action={setCategories}
                navigate={navigate}
                />     
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ borderColor: '#CDE9E6', border: 'none' }}></Divider>
          <Accordion style={{ backgroundColor:'#f5fbfb', margin:'0' }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: '#63d4c7', fontSize: '18px', maxHeight: '64px', whiteSpace: 'nowrap' }}
            >
              {t('ProductCondition')}
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxFilterGroup
                filterName='productCondition'
                filterData={productConditions}
                allData={productConditions}
                values={filters.productCondition}
                action={setProductCondition}
                navigate={navigate}
                />  
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ borderColor: '#CDE9E6', border: 'none' }}></Divider>
          <Accordion style={{ backgroundColor:'#f5fbfb', margin:'0' }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: '#63d4c7', fontSize: '18px', maxHeight: '64px', whiteSpace: 'nowrap' }}
            >
              {t('QuantityPrice')}
            </AccordionSummary>
            <AccordionDetails>
              <PriceQuantitySection
                /> 
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ borderColor: '#CDE9E6', border: 'none' }}></Divider>
          <Accordion style={{ backgroundColor:'#f5fbfb', margin:'0' }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: '#63d4c7', fontSize: '18px', maxHeight: '64px', whiteSpace: 'nowrap' }}
            >
              {t('SellerRating')}
            </AccordionSummary>
            <AccordionDetails>
              <SellerRatingFilter
                />
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ borderColor: '#CDE9E6', border: 'none' }}></Divider>
          <Accordion style={{ backgroundColor:'#f5fbfb', margin:'0' }}>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ color: '#63d4c7', fontSize: '18px', maxHeight: '64px' }}
            >
              {t('Countries')}
            </AccordionSummary>
            <AccordionDetails>
              <CheckBoxFilterGroup
                  filterName='countries'
                  filterData={ctrList}
                  values={filters.countries}
                  action={setCountries}
                  navigate={navigate}
                  allData={countries}
                  />
              {ctrList.length < 6 && <div
                onClick={
                  () => setCtrList([countries.find( e => e.value === 'FR'), ...countries.filter( e => e.value !== 'FR')])
                  }
                id='view-countries' style={{ marginTop: '15px' }}
                >
                  {t('ViewAllCountries')}
                </div>}
            </AccordionDetails>
          </Accordion>
          
          <Divider sx={{ borderColor: '#CDE9E6', border: 'none' }}></Divider>
  </div>;
};
export default Filters;