import * as React from 'react';
import TextField from '../../../components/textField';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import FormikSelect from '../../../components/FormikSelect';
import TextFieldMultiple from '../../../components/TextFieldMultiple';
import { Typography } from '@mui/material';
import flags from './Flags';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import i18n from '../../../i18n';
import { useAppSelector } from '../../../store/hooks';

export default function SimpleDialog(props: any) {
  const { t } = useTranslation();
  const {
    formData,
    setFormData,
  } = props;
  const textFieldStyle = {
    marginBottom:'30px',
    width:'96%',
  };
  const categoryTextFieldStyle = {
    marginBottom:'0px',
    width:'96%',
  };
  const textFieldInputProps = {
    style: {
      height: '30px',
      backgroundColor: '#fff',
      padding: '4px 0px 4px 8px',
      minWidth: '250px',
    },
  };
  const multipleTextFieldInputProps = {
    style: {
      paddingLeft: 'Opx',
      height: '30px',
      backgroundColor: '#fff',
      padding: '4px 0px 4px 8px',
      minWidth: '250px',
      maxWidth: '96%',
    },
  };
  const { setActiveStep } = props;
  const { language } = i18n;
  const [categoriesValues, setCategoriesValues] = React.useState([]);
  const { categories } = useAppSelector(state => state.common);
  React.useEffect(() => {
    if (language && categories && categories.length > 0) {
      const languageCode = language.substr(0, 2);
      const formattedCategories: any = categories.map((e: any) => ({ label: e.name[languageCode], value: e._id }));
      formattedCategories.sort((cata: any, catb: any) => {
        const textA = cata.label.toUpperCase();
        const textB = catb.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
      });
      setCategoriesValues(formattedCategories);
    }
  }, [language, categories]);
  
  const productConditionsTranslations: any = t('ProductStatusList', { returnObjects: true });
  const productConditions = Object.entries(productConditionsTranslations).map(([key, value]) => ({ label: value, value: key }));
  const CountriesTranslations: any = t('CountriesList', { returnObjects: true });
  let countriesobjs = Object.entries(CountriesTranslations);
  countriesobjs.sort((cata: any, catb: any) => {
    const textA = cata[1].toUpperCase();
    const textB = catb[1].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
  });

  const countries = countriesobjs.map(([key, value]) => {
    const flag = flags.find(e => e.code === key);
    return ({
      label:(<div style={{ display: 'flex' }}>
        <img height='24' width='30' style={{ marginRight: '10px' }} src={flag ? flag.flag : '' }/><span>{String(value)}</span></div>
      ),
      value: key,
    }
    );
  });
  const typographyStyle = {
    marginBottom: '9px',
    transform: 'translate(2px,-4px)',
  };

  return (
      <Formik
    enableReinitialize
    initialValues={{
      title: '',
      brand: '',
      category: [],
      description: '',
      keyWords: [],
      productCondition: '',
      country: 'FR',
    }}
    validationSchema={Yup.object().shape({
      title: Yup.string().max(255).required(t('mandatoryRow')),
      brand: Yup.string().max(255).required(t('mandatoryRow')),
      category: Yup.array().required(t('mandatoryRow')),
      description: Yup.string().required(t('mandatoryRow')),
      keyWords: Yup.array(),
      productCondition: Yup.string().max(255).required(t('mandatoryRow')),
      country: Yup.string().max(255).required(t('mandatoryRow')),
    })}
    onSubmit={async ( values ) => {
      setFormData({ ...formData, ...values });
      setActiveStep(1);
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => {
      console.log('valuesss', values);
      return (
          <form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item style={{ textAlign: 'start' }} xs={6} md={6} lg={6}>
            <TextField 
              size="small"
              id="title"
              label={t('FormTitle')}
              type="title "
              variant="outlined"
              error={Boolean(touched.title && errors.title)}
              autoFocus
              name="title"
              onChange={handleChange}
              value={values.title}  
              style={textFieldStyle}
              inputProps={textFieldInputProps}
            />
            <FormikSelect
              name="category"
              value={values.category}
              error={Boolean(touched.category && errors.category)}
              multiple
              placeholder={'Categories'}
              style={categoryTextFieldStyle}
              setFieldValue={setFieldValue}
              options={categoriesValues}
              maximum={3}
              labelColor='#545C84'
              >
            </FormikSelect>
            <div style={{ ...typographyStyle, marginBottom: '23px' }} >
            <FontAwesomeIcon
              style={{
                paddingRight: '8px',
                paddingTop: '6px',
                position: 'relative',
                top: '4px',
              }}
              color='rgba(191, 40, 250, 1)'
              icon={faInfoCircle}/>
            <Typography color="primary" variant="caption">
                {t('Threemaximum')}
                </Typography>
            </div>
            <TextFieldMultiple
                name="keyWords"
                setFieldValue={setFieldValue}
                value={values.keyWords}
                label={t('Tags')}
                error={Boolean(touched.keyWords && errors.keyWords)}
                style={{ ...categoryTextFieldStyle,
                  backgroundColor: '#fff',
                }}
                inputProps={multipleTextFieldInputProps}
                />
            <div style={typographyStyle}>
            <FontAwesomeIcon
              style={{
                paddingRight: '8px',
                paddingTop: '6px',
                position: 'relative',
                top: '4px',
              }}
              color='rgba(191, 40, 250, 1)'
              icon={faInfoCircle}/>
            <Typography color="primary" variant="caption">
                {t('PressSpace')}
                </Typography>
            </div>
            <FormikSelect
              name="productCondition"
              error={Boolean(touched.productCondition && errors.productCondition)}
              value={values.productCondition}
              placeholder={t('ProductCondition')}
              style={textFieldStyle}
              setFieldValue={setFieldValue}
              options={productConditions}
              labelColor='#545C84'
              />
            </Grid>
            <Grid item style={{ textAlign: 'end' }} xs={6} md={6} lg={6}>
            <TextField 
              size="small"
              id="brand"
              label={t('Brand')}
              type="brand "
              variant="outlined"
              error={Boolean(touched.brand && errors.brand)}
              autoFocus
              name="brand"
              onChange={handleChange}
              value={values.brand}  
              style={textFieldStyle}
              inputProps={textFieldInputProps}
              />
            <TextField 
              size="small"
              id="description"
              label="Description"
              variant="outlined"
              error={Boolean(touched.description && errors.description)}
              autoFocus
              name="description"
              multiline
              rows={4}
              onChange={handleChange}
              value={values.description}  
              style={{
                width:'96%',            
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0px',
                },
              }}
              inputProps={{
                style: {
                  height: '100px',
                  backgroundColor: '#fff',
                  padding: '4px 0px 4px 8px',
                  minWidth: '250px',
                },
              }}
            />
           <div style={{ ...typographyStyle, textAlign: 'end', width: '93%', display: 'flex', marginLeft: '20px', paddingTop: '6px' }} >
           <FontAwesomeIcon color='rgba(248, 36, 120, 1)' icon={faExclamationCircle} style={{ paddingRight: '8px', paddingTop: '6px' }} />
            <Typography color="#F82478" variant="caption" style={{ textAlign: 'start' }}>
            Les coordonnées de contact sont interdites dans la description (numéro de téléphone, e-mail, site Web).
                </Typography>
            </div>
            <FormikSelect
              name="country"
              value={values.country}
              placeholder={t('ExpeditionCountry')}
              style={textFieldStyle}
              error={Boolean(touched.country && errors.country)}
              setFieldValue={setFieldValue}
              options={countries}
              />
            </Grid>
          <div style={{ display: 'flex', width:'100%', justifyContent: 'center', marginTop:'32px' }}>
          <Button variant="contained" type="submit" style={{ width:'160px', height:'40px', fontSize:'16px' }}>
              {t('next')}
              </Button>
              </div>
              </Grid>
          </form>
      );
    }
}
</Formik>
  );
}
