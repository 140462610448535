import React from 'react';
// import { Elements } from '@stripe/react-stripe-js';
// import CheckoutForm from './checkoutForm';
import {
//   CardElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';



function Payment() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const language = localStorage.getItem('i18nextLng');
  const lg: any = language ? language.substring(0, 2) : 'fr';
  elements?.update({ locale : lg });
  const [isLoading, setIsLoading] = React.useState(false);
  const onSubmit = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    if (stripe && elements) {
      stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: document.location.origin,
        },
        redirect: 'if_required',
      }).then(( { error }: any) => {
        console.log('error', error);
        setIsLoading(false);
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        } else {
          navigate('/subscription-created');
        }
      }).catch(() => {
        setIsLoading(false);
        enqueueSnackbar(t('PaymentErr'), { variant: 'error' });
      });
    }
  
  };

  return (
      <div style={{ marginTop: '50px' }}>
    <form onSubmit={onSubmit}>
      <PaymentElement />
      {/* <CardElement /> */}
      <Button
        style={{ marginTop: '20px', width : '100%' }} variant='contained'
        type="submit"
        disabled={isLoading}
        >{isLoading && <CircularProgress style={{ width: '14px',
          height: '14px',
          marginRight: '8px',
          color: '#fff',
        }}/>}
          {t('Confirm')}</Button>
    </form>
    </div>
  );
}
export default Payment;