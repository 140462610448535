import { createSlice } from '@reduxjs/toolkit';
// import AccountModel from '../../models/Account';
import initialState from './initialState';
// Define a type for the slice state

// Define the initial state using that type

export const accountSlice = createSlice({
  name: 'account',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAccount: (state, payload: any) => {
      return payload.payload;
    },
    resetAccount: () => ({ ...initialState }),
  },
});

export const { setAccount, resetAccount } = accountSlice.actions;

export default accountSlice.reducer;
