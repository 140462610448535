import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../store/hooks';
import ProductCard from '../../components/productCard';
// import data from './mocks';

const Favorites = () => {
  const { t } = useTranslation();
  const { list } = useAppSelector(state => state.favorites);
  console.log('list', list);
  return <div style={{ marginTop: '34px' }}>
      <div style={{ width: '175px',
        textAlign: 'center',
        fontSize: '16px',
        margin: 'auto',
        color: '#fff',
        textTransform: 'capitalize',
        height: '30px',
        backgroundColor: '#5ED5C8',
        borderRadius: '15px',
        lineHeight: '30px',
      }}>
          {t('Offers')}
          </div>
  <div style={{ padding: '0 6.6% 0 6.6%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginLeft: '6px',
    transform: 'translateY(-30px)',
    marginTop: '31px',
  }}>
      {list && list.length > 0 && list.map( (e: any) => <ProductCard
      favorite={true}
      data={e.offer} />)}
      {
        (!list || list.length === 0) && <div style={{ color: '#707070', fontSize: '16px', marginTop: '50px' }}>
          <div style={{ marginBottom: '20px' }}>
            {t('noFavoritesL1')}
            </div>
            <div>
            {t('noFavoritesL2')}
            </div>
            
          </div>
      }
</div>
</div>;
};

export default Favorites;