import React from 'react';
import Catalog from '../../assets/catalogue.png';
import { useTranslation } from 'react-i18next';
export default function Catalogs(props: any) {
  const { t } = useTranslation();
  const { catalog } = props;
  return (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '40px', marginBottom: '70px' }}>
      <div style={{ color: '#21062E', fontSize: '26px', marginBottom: '40px' }}>
        {t('Catalogs')}
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: 'auto', justifyContent: 'start' }}>{
  catalog.length > 0 && catalog.map( (el: any) => {
    let nameOfDoc = '';
    if (el.length < 14) {
      nameOfDoc = el;
    } else {
      const splitted = el.split('.');
      const extension = splitted[splitted.length - 1];
      const splitted2 = el.split('/');
      const first10 = (splitted2[splitted2.length - 1]).slice(0, 14);
      nameOfDoc = `${first10}...${extension}`;
    }
    return <div style={{ display: 'relative' }}>
    <div style={{ overflow: 'hidden', marginRight: '40px', display: 'relative' }}>
    {
      <a
      href={el}
      download={el}
      style={{ fontSize: '12px', color: 'black', textDecoration: 'none' }}
      ><img src={Catalog} style={{
        height: '155px',
        width: '130px',
        wordWrap: 'break-word',
      }}
      />
      <div>
      {nameOfDoc}
      </div>
      </a>
    }
    </div>
    </div>;
  })
  }
    </div>
</div>
  );
}