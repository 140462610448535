import React from 'react';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as FilledStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as EmptyStar } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSellerRating } from '../../store/Search/searchReducer';

const OffersList = (props: any) => {
  //const { navigate } = props;
  console.log(props);
  const StarStyle = {
    height: '23px',
    width: '23px',
    paddingRight: '5px',
    cursor: 'pointer',
  };
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector(state => state.search);
  const { sellerRating } = filters;
  const [mouseOverPosition, setMouseOverPosition] = React.useState(-1);
  const isFilled = (index: number) => {
    if (mouseOverPosition > -1 && index <= mouseOverPosition) return true;
    if (mouseOverPosition === -1 && index > sellerRating) return false;
    if (mouseOverPosition === -1 && index <= sellerRating) return true;
  };
  const onChange = (value: any) => dispatch(setSellerRating({ values: value /*, navigate*/ }));
  return <div style={{ display: 'flex' }}>
      {[0, 1, 2, 3, 4].map((e, index) =><FontAwesomeIcon
            icon={isFilled(index) ? FilledStar : EmptyStar}
            color='#C221FA'
            style={StarStyle}
            key={e}
            onMouseOver={() => setMouseOverPosition(index) }
            onMouseLeave={() => setMouseOverPosition(-1)}
            onClick={() => onChange(index)}
        />)
      }

  </div>;
};
export default OffersList;