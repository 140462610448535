import * as React from 'react';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import PhotosDropZone from '../../../components/DropZone/DropZone';
import DocumentsDropZone from '../../../components/DropZone/DocumentDropZone';
export default function SimpleDialog(props: any) {
  const { t } = useTranslation();
  const { setActiveStep } = props;
  const { formData, setFormData } = props;
  // const [imagesList, setImagesList] = React.useState([]);
  const [document, setDocument] = React.useState();
  return (
      <Formik
    enableReinitialize
    initialValues={{
      imagesList: [],
    }}
    validationSchema={Yup.object().shape({
      imagesList: Yup.array().min(1),
    })}
    onSubmit={async ( /*values: any*/ ) => {
      // setFormData({ ...formData, attachment: document, pictures: values.imagesList });
      setActiveStep(3);
    }}
  >
    {({
      handleSubmit,
      setFieldValue,
      values,
      errors,
    //   setFieldValue,
    }) => {
      return (
          <form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item style={{ textAlign: 'start', padding:'30px' }} xs={6} md={6} lg={6}>
            <PhotosDropZone
            error={Boolean(errors.imagesList)}
            imagesList={values.imagesList}
            setImagesList={(value: any) => {
              console.log('formData', formData);
              setFieldValue('imagesList', value);
              setFormData({ ...formData, pictures: value });
            }}/>
            </Grid>
            <Grid item style={{ textAlign: 'start', padding:'30px' }} xs={6} md={6} lg={6}>
            < DocumentsDropZone
            setFormData={setFormData}
            formData={formData}
            document={document} setDocument={setDocument}></DocumentsDropZone>
            </Grid>
          <div style={{ display: 'flex', width:'100%', justifyContent: 'center', marginTop:'32px' }}>
          <Button variant="contained" type="submit" style={{ width:'160px', height:'40px', fontSize:'16px' }}>
              {t('next')}
              </Button>
              </div>
              </Grid>
          </form>
      );
    }
}
</Formik>
  );
}
