import React from 'react';
import DropZone from 'react-dropzone';
import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
import DraggableImage from './draggableImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /*faTrash,*/
  faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';


export default function MyDropzone(pros: any) {
  const {
    //setImagesList,
    imagesList,
    error,
    deleteOldPic,
    addPhoto,
    setFormData,
    formData,
  } = pros;
  console.log('imagesList', imagesList);
  const { t } = useTranslation();
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [newImgSrcs, setNewImgSrcs] = React.useState<Array<any>>([]);
  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles.length === 0 || (newImgSrcs.length + imagesList.length) === 7) {
      return;
    }
    setFiles([...files, ...acceptedFiles]);
    addPhoto(acceptedFiles[0]);
    acceptedFiles.map( (file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        setNewImgSrcs([...newImgSrcs, reader.result]);
      };
    });
  };
  // const onDelete = () => {
  //   setFiles([]);
  //   setImagesList([]);
  //   setNewImgSrcs([]);
  // };
  const deleteNewPic = (index: number) => {
    setFiles(files.filter( (e, i) => i !== index ));
    setFormData({ ...formData, pictures: formData.pictures.filter( (e : any, i: any) => i !== index ) });
    setNewImgSrcs(newImgSrcs.filter( (e, i) => i !== index ));
  };
  const { enqueueSnackbar } = useSnackbar();

  const onDropRejected = (e: any) => {
    let isDifType = false;
    e.forEach((element: any) => {
      element.errors.forEach((err: any) => {
        if (err.code === 'file-invalid-type') {
          isDifType = true;
        }
      });
    });
    if (!isDifType) {
      enqueueSnackbar(t('maxPicSizeExceeded'), { variant: 'error' });
    }
  };
  return (
<DropZone
  onDrop={onDrop}
  onDropRejected={onDropRejected}
  accept={['image/gif', 'image/jpeg', 'image/png']}
  maxFiles={7}
  maxSize={5000000}
  >
  {(props: any) => {
    const { getRootProps, getInputProps } = props;
    return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box sx={{
          height: '70px',
          border: error ? '1px solid #d32f2f' : '1px solid #C6C6C6',
          cursor: 'pointer',
          backgroundColor: '#BF28FA',
          color: '#fff',
        }}>
          <div style={{ padding: '0px 80px 0px 80px',
            color: '#fff',
            textAlign: 'center',
            fontSize: '14px',
            paddingTop: '8px',
          }}
          dangerouslySetInnerHTML={{ __html: t('AddPhotoText') }}
          >
            </div>
        </Box>
      </div>
      <div style={{ color: '#BF28FA', fontSize: '12px', paddingTop:'15px', display:'flex' }}>
        <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', top: '4px', marginRight:'8px'  }} color='#BF28FA' icon={faInfoCircle} />
       {`${t('AcceptedImageFormats')} ${t('firstImage')}`}
        </div>
      <div style={{ display: 'flex', marginTop:'20px' }}>
      {imagesList.length > 0 && imagesList.map((e: any, index: any ) => 
      <div style={{
        position: 'relative',
        textAlign: 'center',
        color: 'white',
      }}
      >
      <DraggableImage
        src={e}
        id={`dragimg-index${index}`}
        index={index}
        isOld
        deleteImage={deleteOldPic}
        />
      </div>,
      )}
            {newImgSrcs.length > 0 && newImgSrcs.map((e: any, index: any ) => 
      <div style={{
        position: 'relative',
        textAlign: 'center',
        color: 'white',
      }}
      >
      <DraggableImage
        src={e}
        id={`dragimg-index${index}`}
        index={index}
        deleteImage={deleteNewPic}
        />
      </div>,
            )}
        {/* {
          imagesList.length > 0 &&
          <div 
          onClick={onDelete}
          style={{ 
            marginLeft: '9px',
            width: '40px',
            height: '40px',
            backgroundColor: '#fff',
            border: 'rgba(198, 198, 198, 1) 1px solid',
          }}>
          <FontAwesomeIcon
          style={{
            margin: 'auto',
            top: '10px',
            position: 'relative',
            paddingLeft: '13px',
          }}
          icon={faTrash}
          color='rgba(248, 36, 120, 1)'
          />
            </div>
        } */}
      </div>
    </section>
    );
  }
  
}
</DropZone>
  );
}
