import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  {
    root: {
      width: '90%',
    },
    iconContainer: { // define styles for icon container
      transform: 'scale(2)',
    },
  },
);

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    marginLeft: '4px',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
          '#c122fa',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
          '#c122fa',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));
  
export default function StepperCustom(props: any) {
  const classes = useStyles();
  const { activeStep, steps, orientation } = props;
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper orientation={orientation} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
        {steps.map((label: any, index: number) => (
          <Step sx={{}} key={label}>
            <StepLabel classes={{ iconContainer: classes.iconContainer }} ><span style={{
              color: (activeStep >= index)  ? '#c122fa' : '#afb5d1',
              fontWeight: 'bold',              
            }}>{label}</span></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

StepperCustom.defaultProps = {
  orientation: 'horizontal',
};