import React from 'react';

const IconBuy = (props: any) => {
  const { color } = props;
  return (
<svg id="buy_icon" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <path id="Tracé_17" data-name="Tracé 17" d="M221.041,290.368a15.528,15.528,0,0,1-6.487-1.762c-2.762-1.31-4.089-1.266-4.971-1.222a2.548,2.548,0,0,1-2.176-.674c-.256-.256-1.419-1.649.867-3.935a5.737,5.737,0,0,1,4.061-1.487l-7.915-7.915a2.775,2.775,0,0,1,.006-3.931,2.78,2.78,0,0,1,1.966-.819h0a2.751,2.751,0,0,1,1.963.815l3.288,3.289a2.731,2.731,0,0,1,.521-.718,2.772,2.772,0,0,1,2.791-.691,2.784,2.784,0,0,1,4.437-1.18,2.738,2.738,0,0,1,.523-.725,2.784,2.784,0,0,1,3.933-.006L229,274.562c3.236,3.234,2.875,7.923-.88,11.68l-.025.025-2.132,2.131a6.719,6.719,0,0,1-4.926,1.97Zm-10.941-4.9a11.923,11.923,0,0,1,5.273,1.417c5.7,2.705,8.009,1.392,9.254.164l2.15-2.15a.178.178,0,0,1,.019-.019c2.276-2.3,3.835-5.989.876-8.948l-5.17-5.17a.876.876,0,0,0-1.241,1.237l0,0,1.289,1.289a.955.955,0,0,1-1.35,1.352l-3.007-3.007a.876.876,0,0,0-1.237,1.239l2.148,2.148a.955.955,0,0,1-1.35,1.352l-3.007-3.007a.877.877,0,0,0-1.491.626.859.859,0,0,0,.252.611l2.148,2.148a.955.955,0,1,1-1.35,1.352l-7.3-7.3a.863.863,0,0,0-.613-.256.887.887,0,0,0-.878.878.861.861,0,0,0,.254.613l9.88,9.88a.95.95,0,0,1,.145,1.161.962.962,0,0,1-1.09.43c-.036-.009-3.455-.991-5.082.632-.739.741-.848,1.2-.829,1.3.082.055.1.074.714.052.178-.011.374-.019.594-.019Zm10.5-15.374h0Z" transform="translate(-181.206 -240.368)" fill={color}/>
  <path id="Tracé_18" data-name="Tracé 18" d="M68.3,75.455H64.955A.955.955,0,0,1,64,74.5V64.955A.955.955,0,0,1,64.955,64H68.3a3.34,3.34,0,0,1,2.337,5.727A3.34,3.34,0,0,1,68.3,75.455Zm-2.386-1.909H68.3a1.432,1.432,0,0,0,0-2.864H65.909Zm0-4.773H68.3a1.432,1.432,0,0,0,0-2.864H65.909Z" transform="translate(-58.273 -58.273)" fill={color}/>
  <path id="Tracé_19" data-name="Tracé 19" d="M196.773,75.455h-1.909A2.867,2.867,0,0,1,192,72.591V64.955a.955.955,0,1,1,1.909,0v7.636a.956.956,0,0,0,.955.955h1.909a.956.956,0,0,0,.955-.955V64.955a.955.955,0,1,1,1.909,0v7.636A2.867,2.867,0,0,1,196.773,75.455Z" transform="translate(-172.938 -58.273)" fill={color}/>
  <path id="Tracé_20" data-name="Tracé 20" d="M323.813,70.656a.955.955,0,0,1-.819-.464l-2.864-4.773a.954.954,0,1,1,1.636-.981l2.047,3.408,2.045-3.408a.954.954,0,1,1,1.636.981l-2.864,4.773a.949.949,0,0,1-.817.464Z" transform="translate(-288.225 -58.247)" fill={color}/>
  <path id="Tracé_21" data-name="Tracé 21" d="M352.955,124.015a.955.955,0,0,1-.955-.955v-4.773a.955.955,0,1,1,1.909,0v4.773A.955.955,0,0,1,352.955,124.015Z" transform="translate(-317.502 -105.708)" fill={color}/>
  <path id="Tracé_22" data-name="Tracé 22" d="M15.743,22.91H5.248A5.033,5.033,0,0,1,0,18.137V4.773A5.033,5.033,0,0,1,5.248,0H40.931a5.033,5.033,0,0,1,5.248,4.773V18.137a4.443,4.443,0,0,1-.945,2.722,1.118,1.118,0,0,1-1.461.237.9.9,0,0,1-.262-1.329,2.65,2.65,0,0,0,.569-1.63V4.773a3.02,3.02,0,0,0-3.149-2.864H5.248A3.02,3.02,0,0,0,2.1,4.773V18.137A3.02,3.02,0,0,0,5.248,21h10.5a.959.959,0,1,1,0,1.909Z" fill={color}/>
</svg>
  );
};
IconBuy.defaultProps = {
  color: '#fff',
};
export default IconBuy;