import React from 'react';
import { Dialog, DialogTitle, Button } from '@mui/material';
import { deleteProduct } from '../../api/product';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';


const DeleteDialog = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    id,
    open,
    name,
    handleClose,
    fetchData,
    // offersCount,
  } = props;
  const { t } = useTranslation();
  const onSubmit = async () => {
    try {
      await deleteProduct(id);
      fetchData();
      enqueueSnackbar(t('offerDeleted'));
      handleClose();
    } catch (err) {
      enqueueSnackbar(t('error'));
      handleClose();
    }
  };
  return <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
          {t('DeleteOffer')} {name} ?
          </DialogTitle>
        <Button
        onClick={onSubmit}
        >
            {t('Confirm')}
            </Button>
        </Dialog>;
};

export default DeleteDialog;