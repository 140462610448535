import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
const Card = (props) => {
  const { src, index, deleteImage, isOld } = props;
  const ref = useRef(null);
  return <div>
    <img
    ref={ref}
    style={{ marginLeft: '9px', border: 'rgba(198, 198, 198, 1) 1px solid'  }}
    height="40" width="40" src={src} />
        <FontAwesomeIcon
        onClick={() => isOld ? deleteImage(src) : deleteImage(index)}
        style={{ 
          position: 'absolute',
          height: '16px',
          width: '16px',
          top: '-4px',
          left: '42px',
          cursor: 'pointer',
          marginRight: '8px',
          zIndex: 4,
        }} color='rgba(248, 36, 120, 1)' icon={faTimesCircle} />
    </div>;
};

export default Card;