import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';


export default function SimpleDialog(props: any) {
  const { open } = props;

  return (
    <Dialog   PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
    open={open}>
      <DialogTitle><CircularProgress></CircularProgress></DialogTitle>
      
    </Dialog>
  );
}