import React from 'react';
import './index.css';
import moment from 'moment';


const MessageTitle = (props: any) => {

  const {
    // index,
    setSelectedMessage,
    isSelected,
    // title,
    // date,
    // imgSrc,
    id,
    offer,
    selected,
    setSelected,
    selectedList,
  } = props;
  return <div
    className={isSelected ? 'message-title-selected' : 'message-title'}
    onClick={() => setSelectedMessage()}>
      {offer && offer.pictureUrls && offer.pictureUrls.length > 0 &&
        <div className='message-title-image-container'>
            <img src={offer.pictureUrls[0]} style={{ maxHeight: '100%', maxWidth: '100%' }}/>
            </div>
      }
        <div className='mesage-title-text-container'>
            <div className='message-title-title' style={{ color: isSelected ? '#fff' : '#21062E' }}>
            { offer.title.length > 20 ? `${offer.title.substring(0, 20)}...` : offer.title }
            </div>
            <div className='message-title-preview'>
            { offer.description.length > 20 ? `${offer.description.substring(0, 20)}...` : offer.description }
            </div>
            <div className='message-title-date' style={{ color: isSelected ? '#fff' : '#21062E' }}>
            {moment(offer.createdAt).format('DD/MM/YYYY')}
            </div>
        </div>
        { isSelected && <div className='message-title-triangle'>
        </div>
        }
        <div className={ isSelected ? 'message-title-circle' : 'message-title-circle-nselected' }>
        <input
        onClick={(e: any) => {
          e.stopPropagation();
          if (!selected) { 
            setSelected([...selectedList, id]);
          } else { setSelected(selectedList.filter((s: any) => s !== id));}
        }}
        checked={selected} type="checkbox" id="vehicle1" className='message-title-check' />            
        </div>
        </div>;
};
export default MessageTitle;