import React from 'react';
import './index.css';
import diamond from '../../assets/diamond.svg';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setCategories } from '../../store/Search/searchReducer';

const PromotedCategory = (props: any) => {
  const { data } = props;
  const { language } = useAppSelector( state => state.common );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [categoryName, setCategoryName] = React.useState('');
  let filters: any = { categories: [] };
  filters = useAppSelector(state => state.search).filters;
  const onCategoryClick = (value: string) => {
    let nV = [];
    let values: any = filters.categories;
    if (values.indexOf(value) > -1) {
      nV = values.filter( (e:string) => e !== value);
    } else {
      nV = [...values, value];
    }
    let differentPath = false;
    if (location.pathname.length > 0 && location.pathname.split('/').indexOf('annonces') === -1) {
      differentPath = true;
    } 
    dispatch(setCategories({ values: nV, navigate, differentPath }));
  };

  React.useEffect(() => {
    if (language) {
      const languageCode = language.substr(0, 2);
      setCategoryName(data.name[languageCode]);
    }
  }, [language]);

  return <div
   onClick={() => onCategoryClick(data._id)}
   className='promoted-category-container'>
      <div style={{
        width: '100%',
        textAlign: 'center',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
        <div>
      <img className='diamond' src={diamond} />
      </div>
      <div>
      <img src={data.icons.black} style={{
        transform: 'translateY(-122px)',
        maxHeight: '37px',
        maxWidth: '37px',
      }}/>
      </div>
      </div>
      <div className='promoted-category-title'>
      {categoryName}
      </div>
  </div>;
};
PromotedCategory.defaultProps = {
  language: 'fr',
};
export default PromotedCategory;