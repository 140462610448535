import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AntSwitch from './AntSwitch';

export default function CustomizedSwitch(props: any) {
  const { beforeText, afterText, style, setFieldValue, fieldName, value } = props;
  const handleChange = () => {
    if (value === 'on') {
      setFieldValue(fieldName, 'off');
    } else {
      setFieldValue(fieldName, 'on');
    }
  };
  return (
    <Stack direction="row" style={ style } spacing={1} alignItems="center">
    <Typography color="black">{beforeText}</Typography>
    <AntSwitch checked={value === 'on'} onChange={handleChange} inputProps={{ 'aria-label': 'ant design' }} />
    <Typography color="primary">{afterText}</Typography>
  </Stack>
  );
}
