import { createSlice } from '@reduxjs/toolkit';
interface Subscription {
  plans: any,
  subscription: any,
}

// Define the initial state using that type
const initialState: Subscription = {
  plans: [],
  subscription: null,
};

export const SubscriptionSlice = createSlice({
  name: 'subscription',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setPlans: (state, payload: any) => {
      return {
        ...state,
        plans: payload.payload,
      };
    },
    setSubscripiton: (state, payload: any) => {
      return {
        ...state,
        subscription: payload.payload,
      };
    },
  },
});

export const { setPlans, setSubscripiton } = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;
