import React from 'react';
import './index.css';
import { faTrash, faBoxOpen, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { updateConversationStatus } from '../../api/messaging';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import SellerCard from './SellerCard';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import { useAppSelector } from '../../store/hooks';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  tooltip: {
    background: 'transparent',
  },
});

const CustomizedTooltip = styled(Tooltip)({
  '& .MuiTooltip-tooltip': {
    background: 'none !important',
  },
});

const MessageHeader = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    offer,
    _id: conversationId,
    fetchData,
    selectedStatus,
    recipient,
    sender,
  } = props;
  const {
    title,
    // date,
    description,
    pictureUrls,
    _id: offerId,
  } = offer;
  const { enqueueSnackbar } = useSnackbar();
  const { _id: userId } = useAppSelector(state => state.account);
  const [otherPart, setOtherPart] = React.useState({});
  React.useEffect(() => {
    if (recipient && sender && userId) {
      console.log('recipient sender',
        recipient,
        sender);
      console.log('userId', userId);
      let otherPar = (recipient && recipient._id === userId) ? sender : recipient;
      setOtherPart(otherPar);
    }
  }, [userId, recipient, sender]);

  const updateConversation = (id: any) => async (status: any) => {
    await updateConversationStatus([id], status);
    enqueueSnackbar(t('updatedStatus'), {
      variant: 'success',
    });
    const fetchFnObj: any = {
      All: () => fetchData('active', null),
      AwaitingReply: () => fetchData(null, false),
      Replied: () => fetchData(null, true),
      Archived: () => fetchData('archived'),
      Trash: () => fetchData('deleted'),
    };
    const fdata = fetchFnObj[selectedStatus];
    fdata();
  };
  const showButtons = ['Archived', 'Trash'].indexOf(selectedStatus) === -1;
  const updateAction = updateConversation(conversationId);
  return <div className='message-content-header'>
            <div className='message-content-image'>
              {pictureUrls && pictureUrls.length > 0 &&
                <img src={pictureUrls[0]} style={{ maxWidth: '100%', maxHeight: '100%' }} />
              }
                </div>
            <div
            onClick={() => navigate(`/annonce/${offerId}`)}
            style={{ marginRight: '2.7%', width: '30%', cursor: 'pointer' }}>
                <div className='message-content-sender'>
                { title.length > 20 ? `${title.substring(0, 20)}...` : title }

                </div>
                <div className='message-content-title'>
                { description.length > 20 ? `${description.substring(0, 20)}...` : description }
                </div>
            </div>
            <ClickAwayListener onClickAway={handleTooltipClose}>
            <CustomizedTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                classes={classes}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
            title={<SellerCard seller={otherPart}/>}>
            <div
            onClick={() => handleTooltipOpen()}
            className='message-content-header-button' style={{ backgroundColor: '#5ED5C8', color: '#fff' }}>
                <FontAwesomeIcon
                    icon={faAddressCard}
                    color='#fff'
                    style={{
                      height: '14px',
                      width: '14px',
                      marginRight: '8px',
                    }}
                    />
                {t('ContactDetails')}
                </div>
                </CustomizedTooltip>
                </ClickAwayListener>
                {showButtons && <div
                className='message-content-header-button'
                onClick={() => updateAction('archived')}
                style={{ backgroundColor: '#fff', color: '#C122FA'  }}>
                    <FontAwesomeIcon
                    icon={faBoxOpen}
                    color='#C122FA'
                    style={{
                      height: '14px',
                      width: '14px',
                      marginRight: '8px',
                    }}
                    />
                    {t('Archive')}
                </div>
                }
                {showButtons && <div
                onClick={() => updateAction('deleted')}
                className='message-content-header-button' style={{ backgroundColor: '#fff', color: '#F6256D'  }}>
                <FontAwesomeIcon
                icon={faTrash}
                color='rgba(248, 36, 120, 1)'
                style={{
                  height: '14px',
                  width: '14px',
                  marginRight: '8px',
                }}
                />
                    {t('Delete')}
                </div>
                }
            </div>;
};
export default MessageHeader;