import React from 'react';
import Header from '../../components/Header';
import SignupDialog from '../../views/Home/SignupDialog/dialog';
import LoginDialog from '../../views/Home/LoginDialog/dialog';
import ForgottenPasswordDialog from '../../views/Home/forgottenPasswordDialog/dialog';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { setIsSignupDialogOpen } from '../../store/Common/CommonReducer';
import ConnectedHeader from '../../components/ConnectedHeader';
import RoleChoiceDialog from '../../views/Home/RoleChoiceDialog';

const AppRouter = (props: any) => {
  // const [isSignupDialogOpened, setIsSignupDialogOpened] = React.useState(false);
  const { noSearch } = props;
  const dispatch = useAppDispatch();
  const [isLoginDialogOpened, setIsLoginDialogOpened] = React.useState(false);
  const [isFPDialogOpened, setIsFPDialogOpened] = React.useState(false);
  const { isAuthenticated } = useAppSelector((state) => state.user);
  const { isSignupDialogOpened } = useAppSelector( state => state.common);
  const setIsSignupDialogOpened = (value: any) => dispatch(setIsSignupDialogOpen(value));
  return (
    <React.Fragment>
      {!isAuthenticated &&
    <React.Fragment>
    <Header
    setIsLoginDialogOpened={setIsLoginDialogOpened}
    setIsDialogOpened={setIsSignupDialogOpened}
    setIsFPDialogOpened={setIsFPDialogOpened}
    />
  <SignupDialog open={isSignupDialogOpened} setOpen={setIsSignupDialogOpened}/>
  <LoginDialog openSignupPopup={setIsSignupDialogOpened} open={isLoginDialogOpened} setOpen={setIsLoginDialogOpened}/>
  <ForgottenPasswordDialog open={isFPDialogOpened} setOpen={setIsFPDialogOpened} />
  </React.Fragment>
  }
  {isAuthenticated && 
  <div>
    <RoleChoiceDialog />
    <ConnectedHeader noSearch={noSearch}/>
    </div>
  }
  {props.children}
  </React.Fragment>
  );
};

export default AppRouter;
