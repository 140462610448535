import React from 'react';
import LeftSection from './LeftSection';
import RatingsSection from './RatingsSection';
import './index.css';
import { fetchNotesAnalytics } from '../../api/analytics';
import { fetchReviews } from '../../api/evaluations';
import { useAppSelector } from '../../store/hooks';

const Evaluations = () => {
  const [type, setType] = React.useState('received');
  const { _id: userId } = useAppSelector(state => state.account);
  const [analytics, setAnalytics] = React.useState<any>(null);
  const [reviews, setReviews] = React.useState<any>(null);
  const fetchAnalytics = async (t: string) => {
    const data = await fetchNotesAnalytics(t);
    setAnalytics(data);
  };
  const fetchRevs = async (uid: string, t: string) => {
    const data = await fetchReviews(uid, t);
    setReviews(data);
  };
  React.useEffect(() => {
    if (userId) {
      fetchAnalytics(type);
      fetchRevs(userId, type);
    }
  }, [type, userId]); 
  return <div className='evaluation-container'>
    { analytics &&
      <LeftSection analytics={analytics} type={type} setType={setType}/>
    }{
      !!reviews && reviews.length > 0 && <RatingsSection type={type} data={reviews} />
    }
  </div>;
};
export default Evaluations;