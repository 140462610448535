import { configureStore } from '@reduxjs/toolkit';
import userReduce from './User/userReducer';
import errorReducer from './Error/errorReducer';
import accountReducer from './Account/accountReducer';
import searchReducer from './Search/searchReducer';
import CommonReducer from './Common/CommonReducer';
import analyticsReducer from './Analytics/analyticsReducer';
import chatReducer from './Chat/ChatReducer';
import FavoritesReduce from './Favorites/FavoritesReduce';
import SubscriptionReducer from './Subscription/SubscriptionReducer';
export const store = configureStore({
  reducer: {
    user: userReduce,
    error: errorReducer,
    account: accountReducer,
    search: searchReducer,
    common: CommonReducer,
    analytics: analyticsReducer,
    chat: chatReducer,
    favorites: FavoritesReduce,
    subscrption: SubscriptionReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
