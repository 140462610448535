import * as React from 'react';
import {
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';
export default function SimpleDialog(props: any) {
  const { setOpen, open, title, sentence } = props;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle style={{ backgroundColor: '#F5F9F8' }}>
        <div style={{ textAlign: 'center', marginBottom:'30px' }}>{title}</div>
        <div>
      </div>
      </DialogTitle>
      <DialogContent style={{ backgroundColor: '#F5F9F8' }}>
          {sentence}
          </DialogContent>
    </Dialog>
  );
}
