import React from 'react';  
const CategoriesMenu = (props: any) => {
  const {
    categoriesValues,
    size,
    onCategoryClick,
  } = props;
  const [ hovered, setHovered] = React.useState<any>(null);
  return <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    // top: '40px',
    // left: '-17px',
    zIndex: 10,
  }}>
      <div
          id="demo-simple-select-standard"
          style={{
            // height: '50px',
            borderLeft: 'none',
            borderBottom: 'none',
            width: '23%',
            color: '#fff',
          }}
        >
          {categoriesValues.map((e: any) => <div
            style={{ width: size,
              // minWidth: '280px',
              backgroundColor: hovered === e.value ? '#C122FA' : '#343138',
              color: '#FFFFFF',
              height: '44px',
              fontSize: '14px',
              textAlign: 'start',
              boxSizing: 'border-box',
              lineHeight: '44px',
              paddingLeft: '16px',
              borderBottom: '1px solid #3E3C41',
              cursor: 'pointer',
            }}
            onMouseOver={() => setHovered(e.value)}
            onMouseLeave={() => setHovered(null)}
            onClick={() => onCategoryClick(e.value)}
              >
              {e.label}
            </div>)}
        </div>

  </div>
  ;
};
export default CategoriesMenu;