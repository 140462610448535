import React from 'react';
import DropZone from 'react-dropzone';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Catalog from '../../../assets/catalogue.png';

export default function MyDropzone(ps: any) {
  const { setFieldValue } = ps;
  const { t } = useTranslation();
  const [files, setFiles] = React.useState<Array<File>>([]);
  const [imgSrcs, setImgSrcs] = React.useState<Array<any>>([]);
  const onDrop = (acceptedFiles: any) => {
    setFiles([...files, ...acceptedFiles]);
    setFieldValue('catalog', [...files, ...acceptedFiles]);
    console.log('files ', files);
    acceptedFiles.map( (file: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        setImgSrcs([...imgSrcs, reader.result]);
      };
    });
  };
  const deleteImage = (index: number) => {
    const filteredFiles = files.filter((e, i: number) => i !== index);
    setImgSrcs(imgSrcs.filter((e, i: number) => i !== index));
    setFiles(filteredFiles);
    setFieldValue('catalog', filteredFiles);
  };
  return (
    <React.Fragment>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: 'auto', justifyContent: 'start' }}>{
  imgSrcs.length > 0 && imgSrcs.map( (el, i:number) => {
    const isPdf = el.split(';')[0].includes('application/pdf');
    return <div style={{ display: 'relative' }}>
    <div style={{ overflow: 'hidden', marginRight: '40px', display: 'relative' }}>
    {isPdf && <div>
    <iframe
        src={el}
        id='displayFile'
        className='embedded-pdf'
        style={{  maxWidth: '115px', maxHeight: '155px', padding: 0 }}
        // type='application/pdf'
        scrolling='no'
        />
      <div
        style={{ 
          cursor: 'pointer',
          marginRight: '8px',
          bottom: '91px',
          left: '153px',
          zIndex: 4,
        }}      
      >{files[i].name ? files[i].name.slice(0, 14) : ''}
      </div>
      </div>
      }
    {
      !isPdf && <div style={{ wordWrap: 'break-word' }}>
        <img src={Catalog} style={{
          height: '155px',
          width: '130px',
          wordWrap: 'break-word',
        }}
      />
        <div
        style={{ 
          cursor: 'pointer',
          marginRight: '8px',
          bottom: '91px',
          left: '153px',
          zIndex: 4,
        }}      
      >{files[i].name ? files[i].name.slice(0, 14) : ''}</div>
        </div>
    }
    </div>
    <FontAwesomeIcon
        onClick={() => deleteImage(i)}
        style={{ 
          height: '30px',
          transform: 'translate(52px, -40px)',
          width: '30px',
          cursor: 'pointer',
          marginRight: '8px',
          bottom: '91px',
          left: '153px',
          zIndex: 4,
        }} color='rgba(248, 36, 120, 1)' icon={faTimesCircle} />

    </div>;
  })
  }
    </div>
<DropZone
  onDrop={onDrop}
  maxFiles={1}
  maxSize={5000000}
  >
  {(props: any) => {
    const { getRootProps, getInputProps } = props;
    return (
    <section>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Box sx={{
          height: '70px',
          border: '1px solid #C6C6C6',
          cursor: 'pointer',
          width: '400px',
          margin: 'auto',
          backgroundColor: '#fff' }}>
          <div style={{ padding: '0px 80px 0px 80px',
            color: '#545C84',
            textAlign: 'center',
            fontSize: '14px',
            paddingTop: '8px',
          }}
          dangerouslySetInnerHTML={{ __html: t('AddAttachementText') }}
          >
          </div>
        </Box>
      </div>
    </section>
    );
  }
  
}
</DropZone>
</React.Fragment>
  );
}