import * as React from 'react';
import { Typography, Grid, FormControlLabel, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../components/CheckBox';
import { Formik } from 'formik';
import CheckboxGroup from '../../../components/CheckboxGroup';
import { signup } from '../../../api/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import SuccessDialog from '../../../components/SuccessDialog';
import { CircularProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

export default function SimpleDialog(props: any) {
  const { enqueueSnackbar } = useSnackbar();

  const language = localStorage.getItem('i18nextLng');
  const [captchaVerified, setCaptchaVerified] = React.useState(false);
  const [isLoading, setIsLoading ] = React.useState(false);
  const { collectedFormsValue, setUserCreated, goBack } = props;
  const [ openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
  const { t } = useTranslation();
  const categories : any = t('categories', { returnObjects: true });
  const categoiesValues = Object.entries(categories).map(([key, value]) => ({ label: value, value: key }));
  return (
    <Formik
    enableReinitialize
    initialValues={collectedFormsValue}
    onSubmit={async (values) => {
      const {
        businessSector,
        newsletter,
      } = values;
      setIsLoading(true);
      const {
        billingCountry,
        billingStreetNumber,
        billingCity,
        billingZipCode,
        billingBusinessName,
      } = collectedFormsValue;
      let billingAddress = {
        billingCountry,
        billingStreetNumber,
        billingCity,
        billingZipCode,
        billingBusinessName,
      };
      let newData = { ...collectedFormsValue };
      delete newData.billingCountry;
      delete newData.billingStreetNumber;
      delete newData.billingCity;
      delete newData.billingZipCode;
      delete newData.billingBusinessName;
      try {
        await signup({ ...newData, billingAddress, businessSector, newsletter });
        setIsLoading(false);
        setUserCreated(true);
      } catch (err: any) {
        if (err.response.status === 409) {
          enqueueSnackbar(err.response.data.message, {
            variant: 'error',
          });
          setIsLoading(false);
        }

      }
    }}
    >
    {({
      // errors,
      // touched,
      values,
      // handleChange,
      handleSubmit,
      setFieldValue,
    }) => {
      return (
    <React.Fragment>
      <form onSubmit={handleSubmit} style={{ padding:'16px' }}>
        <div style={{ backgroundColor: '#F5F9F8', border:'1px #e9efef solid', textAlign: 'center', padding:'16px' }}>
          <Typography style={{ padding:'16px 0 16px 0' }} variant='h6'>
            {t('activitiesQuestion')}
            </Typography>
            <div style={{ display: 'flex' }}>
              <div style={{ height: '100%' }}/>
              <div style={{ width: '20%' }}/>
            <Grid container style={{ justifyContent: 'center', width: '65%' }}>
            <CheckboxGroup values={values.businessSector} fieldName="businessSector" data={categoiesValues} setFieldValue={setFieldValue} />
            </Grid>
            <div style={{ width: '15%' }}/>
          </div>
          </div>
          <div style={{ backgroundColor: '#e6f4f5', border:'1px #e9efef solid', display:'flex', textAlign: 'start', padding: '8px 30x 8px 0px' }}>
          <div style={{ width: '20%' }}/>
          <div>
          <div>
          <FormControlLabel
                label={String(t('subscToNewsLEtter'))}
                sx={{ '& .MuiTypography-root': {
                  fontSize: '11px',
                },
                '& .MuiFormControlLabel-root': {
                  marginLeft: '9px',
                  marginRight: '0px',
                },
                'MuiButtonBase-root-MuiCheckbox-root': {
                  padding: '6px',
                },
                }}
                control={
                  <Checkbox
                  setFieldValue={setFieldValue}
                  fieldName="newsletter"
                  value={values.newsletter}
                  />
                }
              />
              </div>
              <div>
          <FormControlLabel
                label={String(t('acceptConditions'))}
                sx={{ '& .MuiTypography-root': {
                  fontSize: '11px',
                },
                'MuiButtonBase-root-MuiCheckbox-root': {
                  padding: '6px',
                },
                '& .MuiFormControlLabel-root': {
                  marginLeft: '9px',
                  marginRight: '0px',
                },
                }}
                control={
                  <Checkbox
                    setFieldValue={setFieldValue}
                    fieldName="didAccept"
                    value={values.didAccept}
                  />    
                }
              />
              </div>
              </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px' }}>
          <ReCAPTCHA
              hl={language || ''}
              sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY || ''}
              onChange={()=>setCaptchaVerified(!captchaVerified)}
            />
            </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop:'32px' }}>
          <FontAwesomeIcon onClick={goBack} style={{ position: 'relative', marginRight:'8px', cursor: 'pointer', top: '12px'   }} color='#c122fa' icon={faChevronLeft} />
            <Button disabled={!values.didAccept || !captchaVerified} variant="contained" type="submit">
            {isLoading && <CircularProgress style={{ width: '20px',
              height: '20px',
              marginRight: '8px',
              color: '#fff',
            }}/>}<span>{t('Submit')}</span>
              </Button>
              </div>
              </form>
              <SuccessDialog
                title={t('AccountCreatedTitle')}
                sentence={t('AccountCreated')}
                open={openSuccessDialog}
                setOpen={setOpenSuccessDialog}
                >
              </SuccessDialog>
          </React.Fragment>
      );
    }}
  </Formik>
  );
}
