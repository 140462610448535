import React from 'react';
import './index.css';
import Rating from '@mui/material/Rating';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faStar as faSolidStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';



const StyledRating = styled(Rating)({
  '& .Mui-disabled': {
    opacity: 1,
  },
});
  
const Evaluations = (props: any) => {
  const { t } = useTranslation();
  const { analytics, color } = props;
  const pratings = [
    { label: t('Excellent'), value: 5 },
    { label: t('Good'), value: 4 },
    { label: t('Okay'), value: 3 },
    { label: t('Disappointing'), value: 2 },
    { label: t('VeryDisappointing'), value: 1 },
  ];
  console.log('analytics', analytics);
  return <div className='left-section-ratings'>
      <div className='left-section-card-rt'>
          <React.Fragment><div style={{ fontSize: '20px' }}>
              {t('GlobalRating')}
              </div>
              <div className='note-seller-s'>
              <div style={{ fontSize: '26px', fontWeight: 'bold' }}>
                  {!!analytics && (analytics.globalNote || 0)} / 5
                  </div>
              <div style={{ marginTop: '6px' }}>
              <StyledRating
                    readOnly
                    name="customized-color"
                    value={analytics.globalNote || 0}
                    precision={0.1}
                    icon={<FontAwesomeIcon color={color} icon={faSolidStar} />}
                    emptyIcon={<FontAwesomeIcon color={color} icon={faStar}  />}
                />
                  </div>
              </div>
              </React.Fragment>
              <div className='reviews-count'>
              <Divider sx={{ '& .MuiDivider-root': {
                borderColor: '#CDE9E6',
                opacity: 1,
              } }}/>
              {analytics.notesCount} {t('ratings')}
              <Divider sx={{ '& .MuiDivider-root': {
                borderColor: '#CDE9E6',
                opacity: 1,
              } }}/>
              </div>
              <div className='reviews-values'>
                {
                  analytics.notes && analytics.notes.length > 0 &&
                  pratings.map((e: any) => {
                    const nt = analytics.notes.find((s: any) => s.note === e.value);
                    let val = nt ? nt.count : 0;
                    return <div className='review-row'>
                  <div className='review-label'>
                      {e.label}
                    </div>
                  <div className='review-value'>
                  {val}
                  </div>
                  </div>;
                  },
                  )
                }
              </div>
          </div>
  </div>;
};
export default Evaluations;