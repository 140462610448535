import * as React from 'react';
import PropTypes from 'prop-types';
import {
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';
import Stepper from '../../../components/Stepper';
import AccountForm from './AccountForm';
import ActivitiesForm from './ActivitiesForm';
import CoordinatesForm from './CoordinatesForm';
import SuccessPopup from './SuccessPopup';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';


const initialValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  civility: '',
  lastName: '',
  firstName: '',
  businessName: '',
  country: 'FR',
  siret: '',
  tva: '',
  phone: '',
  mobile: '',
  website: '',
  streetNumber: '',
  city: '',
  zipCode: '',
  isProfessional: 'on',
  isBillingAddress: false,
  billingStreetNumber: '',
  billingCity: '',
  billingBusinessName: '',
  billingZipCode: '',
  billingCountry: 'FR',
  businessSector: [],
  newsletter: true,
  didAccept: false,
};
export default function SimpleDialog(props: any) {
  const { setOpen, open } = props;
  const [userCreated, setUserCreated] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [collectedFormsValues, setCollectedFormsValues] = React.useState(initialValues);
  const handleClose = () => {
    setOpen(false);
  };
  const goToStep = (step: number) => () => setActiveStep(step);
  const { t } = useTranslation();

  const steps = [
    t('Account'),
    t('Coordinates'),
    t('Activity'),
  ];
  
  return (
    <React.Fragment>
    {!userCreated && <Dialog sx={{ '& .MuiDialog-paper	':{ maxWidth: '800px' } }} open={open}>
      <DialogTitle>
        <div style={{ textAlign: 'center', marginBottom:'30px' }}>{t('Register')}
        <span onClick={() => {
          setCollectedFormsValues(initialValues);
          setActiveStep(0);
          handleClose();
        }}>
        <FontAwesomeIcon
          icon={faWindowClose}
          color='#afb5d1'
          style={{
            margin: 'auto',
            right: '10px',
            position: 'absolute',
            paddingLeft: '13px',
            cursor: 'pointer',
          }}
          />
          </span>
        </div>
        <div>
      <Stepper steps={ steps } activeStep={activeStep}/>
      </div>
      </DialogTitle>
      <DialogContent>
        {activeStep === 0 && <AccountForm
          collectedFormsValue={collectedFormsValues}
          setCollectedFormsValues={setCollectedFormsValues}
          goToNextStep={goToStep(1)}/>}
        {activeStep === 1 && <CoordinatesForm
          collectedFormsValue={collectedFormsValues}
          goBack={goToStep(0)}
          setCollectedFormsValues={setCollectedFormsValues}          
          goToNextStep={goToStep(2)}/>}
        {activeStep === 2 && <ActivitiesForm
            goBack={goToStep(1)}
            setUserCreated={setUserCreated}
            collectedFormsValue={collectedFormsValues}
            setCollectedFormsValues={setCollectedFormsValues}        
        />}
          </DialogContent>
    </Dialog>
  }
    {userCreated && 
          <Dialog sx={{ '& .MuiDialog-paper	':{ maxWidth: '800px', width: '50%' } }} onClose={handleClose} open={open}>
        <SuccessPopup close={handleClose}/>
        </Dialog>
        }
    </React.Fragment>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
