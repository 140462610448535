import React, { useLayoutEffect, useState } from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Select } from '@mui/material';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { setOneCategory, setSearchBar } from '../store/Search/searchReducer';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from '../i18n';
import CategoriesMenu from './CategoriesMenu';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderTopLeftRadius: '15px',
      borderBottomLeftRadius: '15px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      height: '30px',
      backgroundColor: '#fff',
      '& fieldset': {
        borderRight: 'none',
        borderTop: 'none',
      },
    },
  },
})(TextField);


const CustomButton = styled(Button)({
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  fontSize: '11px',
  border: 'none',
  background: 'transparent linear-gradient(45deg, rgba(160, 27, 207, 1) 0%, rgba(188, 32, 255, 1) 100%) 0% 0% no-repeat padding-box;',
});


// const BoostButton = styled(Button)({
//   borderTopRightRadius: '0px',
//   borderBottomRightRadius: '0px',
//   borderTopLeftRadius: '0px',
//   borderBottomLeftRadius: '0px',
//   background: 'transparent linear-gradient(45deg, rgba(153, 22, 199, 1) 0%, rgba(153, 22, 199, 1) 1%, rgba(192, 50, 252, 1) 100%) 0% 0% no-repeat padding-box;',
//   fontSize: '15px',
//   border: 'none',
//   height: '30px',
//   color: '#fff',
//   width: '9.7%',
//   position: 'relative',
//   top: '10px',
//   display: 'none',
// });

  
const SearchBox = () => {
  const { t } = useTranslation();
  const { language } = i18n;
  const [categoriesValues, setCategoriesValues] = React.useState([]);
  const { categories } = useAppSelector(state => state.common);
  let filters: any = { categories: [] };
  filters = useAppSelector(state => state.search).filters;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [size, setSize] = React.useState('300px');
  const ref: any = React.useRef();
  const updateDimensions = () => {
    if (ref.current) setSize(ref.current.clientWidth);
  };
  React.useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    setSize(ref.current.clientWidth);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const [width, height] = useWindowSize();
  console.log('height', height);
  React.useEffect(() => {
    if (language && categories && categories.length > 0) {
      const languageCode = language.substr(0, 2);
      let sortedCategories = [...categories];
      sortedCategories.sort((cata: any, catb: any) => {
        const textA = cata.name[languageCode].toUpperCase();
        const textB = catb.name[languageCode].toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;    
      });
      const formattedCategories: any = sortedCategories.map((e: any) => ({ label: <div style={{
        // display: 'flex',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        // overflow: scroll
      }}>
        <span style={{ width: '34px',
          display: 'inline-block',
          textAlign: 'left',
          maxWidth: '34px' }}><img
          style={{
            maxHeight:'21px',
            maxWidth: '21px',
            transform: 'translateY(4px)',
            margin: 'auto',
          }} src={e.icons.white} />
          </span>
        {e.name[languageCode]}
        </div>
      , value: e._id }));
      setCategoriesValues(formattedCategories);
    }
  }, [language, categories]);
  const [open, setOpen] = React.useState(false);

  const onCategoryClick = async (value: string) => {
    let nV = [];
    let values: any = filters.categories;
    if (values.indexOf(value) > -1) {
      nV = [value];
    } else {
      nV = [value];
    }
    let differentPath = false;
    if (location.pathname.length > 0 && location.pathname.split('/').indexOf('annonces') === -1) {
      differentPath = true;
    }
    // dispatch(resetFilters());
    dispatch(setOneCategory({ values: nV, navigate, differentPath }));
    setOpen(false);
  };
  const [searchBarValue, setSearchBarValue] = React.useState('');
  const onSearchBarSearch = () => {
    let differentPath = false;
    if (location.pathname.length > 0 && location.pathname.split('/').indexOf('annonces') === -1) {
      differentPath = true;
    }
    dispatch(setSearchBar({ values: searchBarValue, navigate, differentPath }));
  };
  React.useEffect(()=> {
    setSearchBarValue(filters.searchBar);
  }, [filters.searchBar]);

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleOpen = () => {
  //   setOpen(true);
  // };
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div
    ref={ref}
    tabIndex={0}
    onBlur={(e: any) => {
      if (!e.currentTarget.contains(e.relatedTarget)) {
        setOpen(false);
      }
    }
    }
    style={{ position: 'relative',
      // left: '7.5%',
      fontSize: '18px',
      // top: '90px',
      width: '23%',
      textAlign: 'center',
      color: '#fff' }}>
        <div
          style={{
            height: '50px',
            // background: 'transparent',
            backgroundColor: '#343138',
            border: 'none',
            color: '#fff',
            lineHeight: '50px',
            width: '100%',
            fontSize: '16px',
            cursor: 'pointer',
            marginLeft: '0px',
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: '11px',
            boxSizing: 'border-box',
          }}
          onClick={() => setOpen(!open)}>
            <span>
        {t('Categories')}
      </span>
      {(location.pathname !== '/' || (location.pathname === '/' && width < 1300)) &&
      <FontAwesomeIcon
        icon={open ? faArrowUp : faArrowDown}
        color='#FFFFFF'
        style={{
          height: '14px',
          width: '14px',
          marginTop: '16px',
          marginRight: '16px',
        }}
        />
      }
      </div>
      {(open || (location.pathname === '/'  && width > 1300 )) &&
      <CategoriesMenu
        categoriesValues={categoriesValues}
        size={size}
        onCategoryClick={onCategoryClick}
        />
      }
      </div>
  <div style={{
    position: 'relative',
    top: '10px',
    display: 'flex',
    width: '45%',
  }}>
    <div style={{
      top: '10px',
      display: 'flex',
      width: '59%%',
    }}>
      <CustomTextField 
      placeholder={`${t('Search')}...`}
      onChange={e => setSearchBarValue(e.target.value)}
      onKeyDown={ (e: any) => (e.keyCode === 13) ? onSearchBarSearch() : null }
      value={searchBarValue}
      />
      <FormControl variant="standard" sx={{ m: 0, width: '33%', minWidth: 120 }}>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={'ts'}
          onChange={() => {}}
          style={{
            backgroundColor: '#F5FAF9',
            height: '30px',
            borderLeft: 'none',
            borderBottom: 'none',
            width: '100%',
          }}
          label="Age"
        >
          <MenuItem value="ts">
            {t('allWebsite')}
          </MenuItem>
        </Select>
      </FormControl>
      <CustomButton onClick={onSearchBarSearch} style={{ height: '30px', width: '17%' }} variant="contained">
      <FontAwesomeIcon
        icon={faSearch}
        color='#FFFFFF'
        style={{
          height: '14px',
          width: '14px',
        }}
        />
        </CustomButton>
        </div>
      <div style={{ fontSize: '16px ', marginLeft: '30px', top: '4px', position: 'relative' }}>
        {/* Annonces */}
        </div>
      <div style={{ fontSize: '16px ', marginLeft: '30px', top: '4px', position: 'relative' }}>
        {/* Grossistes */}
        </div>
  </div>
  <div style={{ width: '9.7%' }}></div>
  {/* <BoostButton> Booster</BoostButton> */}
  </div>
  ;
};
export default SearchBox;