import React from 'react';
import './index.css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const EvaluationCard = (props: any) => {
  const { data } = props;
  const {
    user,
    note,
    comment,
    updatedAt,
  } = data;

  const { t } = useTranslation();

  const pratings = [
    { label:  t('Excellent'), value: 5, color: '#C122FA' },
    { label: t('Good'), value: 4, color: '#5ED5C8' },
    { label: t('Okay'), value: 3, color: '#545C84' },
    { label: t('Disappointing'), value: 2, color: '#F68C74' },
    { label: t('VeryDisappointing'), value: 1, color: '#F92077' },
  ];
  let rating = pratings.find((e: any) => e.value === note);
  if (!rating) {
    rating = pratings[0];
  }
  const date = moment(updatedAt).format('DD/MM/YYYY');
  return <div className='evaluation-card-container'>
      <div className='evaluation-card-header'>
      <div className='evaluation-card-username'>
          {`${user.firstName} ${user.lastName}` }
          </div>
          <div className='evaluation-card-date'>
          {date}
          </div>
          <div className='evaluation-card-rating' style={{ color: rating.color }}>
            {rating.label}
          </div>
          </div>
          {comment && 
          <div className='evaluation-card-comment'>
              {comment}
          </div>
            }
  </div>;
};
export default EvaluationCard;