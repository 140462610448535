import axios from 'axios';
export const fetchReviews = async (userId: string, type: string) => {
  const accessToken = localStorage.getItem('access_token');
  let query = '';
  if (type === 'left') {
    query = `?userId=${userId}`;
  } else if (type === 'received') {
    query = `?seller=${userId}`;
  }
  const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/notes${query}`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return data;
};
