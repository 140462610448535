import React, { useEffect, useState } from 'react';
import { getStoreByToken } from '../../api/shop';
import CreationForm from './CreationForm';
import UpdateForm from './UpdateForm';
import { CircularProgress } from '@mui/material';
import LoadingDialog from '../../components/loadingDialog';

const Store = () => {
  const [storeData, setStoreData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const sd = await getStoreByToken();
        setIsLoading(false);
        const dta = {
          ...sd,
          deletedCatalog: [],
          offers: sd.recommendedProdcuts ? sd.recommendedProdcuts.map((e: any) => e._id) : [],
        };
        setStoreData(dta);
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
      <React.Fragment>
        { storeData === null && !isLoading && <CreationForm setIsPosting={setIsPosting}/>}
        { storeData !== null && !isLoading && <UpdateForm setIsPosting={setIsPosting} initialValues={storeData} />}
        { isPosting && <LoadingDialog open={isPosting}/>}
        { isLoading && <CircularProgress style={{ margin: '40px auto', height: '80px', width: '80px' }}></CircularProgress>}
      </React.Fragment>
  );
};

export default Store;