import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { getClientSecret } from '../../api/payment';
import {
  Elements } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { getPlans } from '../../api/payment';
import { setPlans } from '../../store/Subscription/SubscriptionReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Grid, Typography, CircularProgress, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { setSubscripiton } from '../../store/Subscription/SubscriptionReducer';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const language = localStorage.getItem('i18nextLng');
const lg: any = language ? language.substring(0, 2) : 'fr';
const publishableKey : any = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(publishableKey,
  { locale: lg });
function Payment() {
  const location = useLocation();
  const { search } = location;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { subscription } = useAppSelector(state => state.subscrption);
  const { planid } = useParams();
  const [plan, setPlan] = React.useState<any>(null);
  const fetchPlans = async (plid: any) => {
    const plans : any = await getPlans();
    if (plans && plans.length > 0 && !plan) {
      const pl = plans.find((p: any) => p._id  === plid);
      setPlan(pl);
    }
    dispatch(setPlans(plans));
  };

  React.useEffect(() => {
    fetchPlans(planid);
  }, [planid]);

  const [options, setOptions] = React.useState<any>(null);
  React.useEffect(() => {
    const getClientS = async (pl: any, prmocode: string) => {
      const d = await getClientSecret(pl, prmocode);
      dispatch(setSubscripiton(d));
    };
    if (planid && subscription === null) {
      if (search && search.length > 0 ) {
        const st = search.substring(1);
        const qs:any = queryString.parse(st, { arrayFormat: 'bracket-separator' });
        if (qs && qs.code) {
          getClientS(planid, qs.code);
        }
      } else { getClientS(planid, ''); }
    }
  }, [planid, subscription, search]);

  React.useEffect(() => {
    if (subscription) {
      setOptions({
        clientSecret: subscription.clientSecret,
      });
    }
  }, [subscription]);
  const period = plan ? plan.interval == 'month' ? 'Monthly' : 'Yearly' : '';
  return (
      <div style={{ padding: '0 6.6%', marginTop: '30px', margin: 'auto', width: '75%' }}>
      <Grid container spacing={2}>
      <Grid item xs={2} />
      <Grid item xs={3} style={{ marginTop: '60px' }}>
      <Typography color='primary' style={{ textAlign: 'start', fontSize: '22px', marginBottom: '20px' }}>
      {t('Subscription')} {plan && ` ${t(plan.name.split('-')[0])} ${t(period)}`}
    </Typography>
    <Divider />
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', height: '50px' }}>
    <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    {`${t('Excludingtax')}`}
      </Typography>
    <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    &nbsp;{subscription?.subTotal}€
      </Typography>
    </div>
    <Divider />
    {subscription && subscription.discountAmount !== 0 && <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', height: '50px' }}>
    <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    {`${t('DiscountAmount')}`}
      </Typography>
      <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    &nbsp; -{subscription?.discountAmount}€
      </Typography>
      </div>
    }
    {subscription && subscription.discountAmount !== 0 &&  <Divider /> }
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', height: '50px' }}>
    <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    {`${t('VAT')}`}
      </Typography>
      <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    &nbsp;{subscription?.taxAmount}€
      </Typography>
      </div>
      <Divider />
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', height: '50px' }}>
    <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    {`${t('Includingtax')}`}
      </Typography>
    <Typography color='rgb(62, 60, 65)' style={{ textAlign: 'start', lineHeight: '50px', fontSize: '20px' }}>
    &nbsp;{subscription?.total}€ / {t(plan?.interval)}
      </Typography>
      </div>
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={5}>
          {options && 
    <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
    </Elements>
    }
    {!options && <CircularProgress style={{ marginTop: '100px' }}/>}
    </Grid>
    {/* <Grid item xs={2} /> */}
    </Grid>
    </div>
  );
}
export default Payment;
