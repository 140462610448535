import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '0px 0 0 0px',
      },
    },
  },
})(TextField);
  
export default CustomTextField;