import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface Analytics {
  views: number;
  clicks: number;
  offersCount: number;
  offersAnalytics: any;
  favoritesCount: number
}

// Define the initial state using that type
const initialState: Analytics = {
  views: 0,
  clicks: 0,
  offersCount: 0,
  favoritesCount: 0,
  offersAnalytics: [],
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setViews: (state, { payload }) => {
      state.views = payload;
    },
    setClicks: (state, { payload }) => {
      state.clicks = payload;
    },
    setFavorites: (state, { payload }) => {
      state.favoritesCount = payload;
    },
    setOffersCount: (state, { payload }) => {
      state.offersCount = payload;
    },
    setOffersData: (state, { payload }) => {
      state.offersAnalytics = payload;
    },
  },
});

export const { setViews, setClicks, setOffersCount, setOffersData, setFavorites } = analyticsSlice.actions;

export default analyticsSlice.reducer;
