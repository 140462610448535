import axios from 'axios';

export const fetchCategories = async () => {
  const accessToken = localStorage.getItem('access_token');
  const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/categories`,
    { headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    });
  return res;
};
