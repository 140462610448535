import * as React from 'react';
import TextField from '../../components/textField';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import FormikSelect from '../../components/FormikSelect';
// import { Typography } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import { CURRENCIES } from '../../constants';
import Checkbox from '../../components/CheckBox';
import InputAdornment from '@mui/material/InputAdornment';

export default function SimpleDialog(props: any) {
  const { setActiveStep, initialValues } = props;
  const {
    formData,
    setFormData,
  } = props;
  const { t } = useTranslation();
  const textFieldStyle = {
    marginBottom:'8pxpx',
    width:'96%',
  };
  const categoryTextFieldStyle = {
    marginBottom:'0px',
    width:'96%',
  };
  const textFieldInputProps = {
    style: {
      height: '30px',
      backgroundColor: '#fff',
      padding: '4px 0px 4px 8px',
      minWidth: '250px',
    },
  };
  const units : any = t('Units', { returnObjects: true });
  const unitsValues = Object.entries(units).map(([key, value]) => ({ label: value, value: key }));
  const currencies = CURRENCIES.map((e: any) =>({ label: `${e.code}-${e.symbol}`, value: e.code }));
  return (
      <Formik
    enableReinitialize
    initialValues={initialValues}
    validationSchema={Yup.object().shape({
      unit: Yup.string().max(255).required(t('mandatoryRow')),
      availableAmount: Yup.string().when('noLimitOfAmount', {
        is: false,
        then: Yup.string().required(t('mandatoryRow')),
      }),
      noLimitOfAmount: Yup.bool().required(t('mandatoryRow')),
      minOrder:  Yup.number().required(t('mandatoryRow')),
      price:  Yup.number().required(t('mandatoryRow')),
      currency:  Yup.string().required(t('mandatoryRow')),
    })}
    onSubmit={async ( values ) => {
      const price = String(values.price);
      const availableAmount = String(values.availableAmount);
      const minOrder = String(values.minOrder);
      if (availableAmount) {
        setFormData({ ...formData, ...values, price, availableAmount, minOrder });
      } else {
        let a = { ...formData };
        delete a.availableAmount;
        let b:any = { ...values };
        delete b.availableAmount;
        setFormData({ ...a, ...b, price, minOrder });
      }
      setActiveStep(2);
    }}
  >
    {({
      errors,
      touched,
      values,
      handleChange,
      handleSubmit,
      setFieldValue,
    }) => {
      let unitLabel = '';
      let currencyLabel = '';
      if (values.unit !== '') {
        const u = unitsValues.find( (e: any) => e.value === values.unit);
        unitLabel = String(u?.label);
      }
      if (values.currency !== '') {
        const u = currencies.find( (e: any) => e.value === values.currency);
        currencyLabel = String(u?.value);
      }
      return (
          <form onSubmit={handleSubmit}>
            <Grid container>
            <Grid item style={{ textAlign: 'start', padding:'30px' }} xs={6} md={6} lg={6}>
            <div style={{ display:'flex', justifyContent:'space-between' }}>
            <TextField 
              size="small"
              id="availableAmount"
              label={t('availableQuantity')}
              type="number"
              variant="outlined"
              error={Boolean(touched.availableAmount && errors.availableAmount)}
              autoFocus
              name="availableAmount"
              onChange={handleChange}
              disabled={values.noLimitOfAmount}
              value={values.availableAmount}  
              style={{ ...textFieldStyle, width:'47%' }}
              inputProps={textFieldInputProps}
            />
            <FormikSelect
              name="unit"
              value={values.unit}
              error={Boolean(touched.unit && errors.unit)}
              placeholder={t('Unit')}
              style={{ ...categoryTextFieldStyle, width:'47%' }}
              setFieldValue={setFieldValue}
              options={unitsValues}
              noLeftBorder
              maximum={3}
              >
            </FormikSelect>
            </div>
            <div>
            <FormControlLabel
                label={String(t('noQuantityLimit'))}
                sx={{ '& .MuiTypography-root': {
                  fontSize: '14px',
                },
                'MuiButtonBase-root-MuiCheckbox-root': {
                  padding: '6px',
                },
                '& .MuiFormControlLabel-root': {
                  marginLeft: '0px',
                  marginRight: '0px',
                },
                }}
                control={
                  <Checkbox
                    setFieldValue={setFieldValue}
                    fieldName="noLimitOfAmount"
                    value={values.noLimitOfAmount}
                  />    
                }
              />
              </div>
              <TextField 
              size="small"
              id="minOrder"
              label={t('minimalQuantity')}
              type="number"
              variant="outlined"
              error={Boolean(touched.minOrder && errors.minOrder)}
              autoFocus
              name="minOrder"
              onChange={handleChange}
              value={values.minOrder}  
              style={{ ...textFieldStyle, width:'100%', marginTop:'25px', backgroundColor: '#fff' }}
              inputProps={textFieldInputProps}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <div style={{ color:'#c122fa' }}>{unitLabel}</div></InputAdornment>,
              }}
            />
            </Grid>
            <Grid item style={{ textAlign: 'end', backgroundColor: '#E5F2F3', padding:'30px'  }} xs={6} md={6} lg={6}>
            <TextField 
              size="small"
              id="Price"
              label={t('Price')}
              type="number"
              variant="outlined"
              error={Boolean(touched.price && errors.price)}
              autoFocus
              name="price"
              onChange={handleChange}
              value={values.price}  
              style={{ ...textFieldStyle, marginBottom:'27px', backgroundColor: '#fff' }}
              inputProps={{
                style: {
                  height: '70px',
                  backgroundColor: '#fff',
                  padding: '4px 10px 4px 14px',
                  minWidth: '250px',
                },
              }}
              InputProps={{
                endAdornment: <InputAdornment style={{ height: '70px' }} position="end">
                   <div style={{ color:'#c122fa' }}>{currencyLabel}/{unitLabel}</div></InputAdornment>,
              }}
            />
            <FormikSelect
              name="currency"
              value={values.currency}
              placeholder={t('currency')}
              style={textFieldStyle}
              setFieldValue={setFieldValue}
              error={Boolean(touched.currency && errors.currency)}
              options={currencies}
              />
            </Grid>
          <div style={{ display: 'flex', width:'100%', justifyContent: 'center', paddingTop:'32px', paddingBottom:'27px', backgroundColor:'#fff' }}>
          <Button variant="contained" type="submit" style={{ width:'160px', height:'40px', fontSize:'16px' }}>
              {t('next')}
              </Button>
              </div>
              </Grid>
          </form>
      );
    }
}
</Formik>
  );
}
