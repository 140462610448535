import React from 'react';
import { useTranslation } from 'react-i18next';


const EmailRejected = () => {
  const { t } = useTranslation();
  console.log('t', t);
  return <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>

        <div style={{ color: '#5ED5C8', fontSize: '24px', fontWeight: 'bold', marginTop: '70px' }}>
        {t('error')}
            </div>
        </div>;
};
export default EmailRejected;