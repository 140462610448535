import React from 'react';
import { useAppSelector } from '../store/hooks';
import { setAccount } from '../store/Account/accountReducer';
import { logoutnew } from '../store/User/userReducer';
// import { resetAccount } from '../store/Account/accountReducer';
import { useAppDispatch } from '../store/hooks';
import { fetchAccount } from '../api/account';
import { fetchCategories } from '../api/common';
import { setCategories } from '../store/Common/CommonReducer';
import { useNavigate, useLocation } from 'react-router-dom';
import { refreshMyToken } from '../api/auth';
import jwt_decode from 'jwt-decode';

const AuthGuard = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAppSelector(state => state.user);
  React.useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const account = await fetchAccount();
        dispatch(setAccount(account.data));
        const accessToken = localStorage.getItem('access_token');
        const decodedToken:any = jwt_decode(accessToken || '');
        let role;
        if (decodedToken &&
                decodedToken.resource_access &&
                decodedToken.resource_access['iziplace-client']
                && decodedToken.resource_access['iziplace-client'].roles
                && decodedToken.resource_access['iziplace-client'].roles.length > 0
        ) {
          role = decodedToken.resource_access['iziplace-client'].roles[0];
        }
        if (account.data.role !== role) {
          await refreshMyToken();
        }
      } catch (err){
        console.error('error', err);
        dispatch(logoutnew());
        navigate('/');
      }
    };
    if (isAuthenticated) { fetchAccountData(); }
  }
  , [isAuthenticated, location]);
  React.useEffect(() => {
    const getCategories = async () => {
      try {
        const categories = await fetchCategories();
        console.log('setCategories(categories.data', categories.data);
        dispatch(setCategories(categories.data));
      } catch (err){
        console.error('error', err);
      }
    };
    getCategories();
  }
  , []);
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthGuard;