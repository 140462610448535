import React from 'react';

const IconBuy = (props: any) => {
  const { color } = props;
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
  <g id="icon_store" transform="translate(-1 -1)">
    <path id="Tracé_51" data-name="Tracé 51" d="M45.444,33.258H43.889v-12.1h-.024a4.067,4.067,0,0,0,1.58-3.226V14.519L40.481,4.226H39.222V1H12.778V4.226H11.519L6.556,14.519v3.416a4.067,4.067,0,0,0,1.58,3.226H8.111v12.1H6.556V49.387H5V51H47V49.387H45.444Zm-3.111,0H40.778V21.881a3.631,3.631,0,0,0,1.556.006Zm-28-17.742h4.674L19,17.935a2.335,2.335,0,1,1-4.667,0Zm6.082-9.677-1.3,8.065H14.634l2.592-8.065Zm14.358,0L37.366,13.9H32.872L31.584,5.839ZM20.556,15.516h4.667v2.419a2.335,2.335,0,1,1-4.667,0Zm6.222,0h4.667v2.419a2.335,2.335,0,1,1-4.667,0Zm6.222,0h4.667v2.419a2.335,2.335,0,1,1-4.667,0ZM30.008,5.839,31.3,13.9H26.778V5.839ZM25.222,13.9H20.7l1.3-8.065h3.23ZM13.556,20.352a3.8,3.8,0,0,0,6.222,0,3.8,3.8,0,0,0,6.222,0,3.8,3.8,0,0,0,6.222,0,3.8,3.8,0,0,0,6.222,0,3.968,3.968,0,0,0,.791.809h-.013v12.1H12.778v-12.1h-.013A3.968,3.968,0,0,0,13.556,20.352Zm28,0a2.38,2.38,0,0,1-2.333-2.419V15.516h4.667v2.419A2.38,2.38,0,0,1,41.556,20.355ZM43.408,13.9h-4.4L36.412,5.839h3.107ZM14.333,2.613H37.667V4.226H14.333ZM12.481,5.839h3.107L12.995,13.9h-4.4Zm-4.37,9.677h4.667v2.419a2.335,2.335,0,1,1-4.667,0Zm1.556,6.37a3.631,3.631,0,0,0,1.556-.006V33.258H9.667ZM8.111,34.871H43.889V49.387H42.333v-12.9H37.667V38.1h3.111v11.29H11.222V38.1h3.111V36.484H9.667v12.9H8.111Z" fill={color}/>
    <rect id="Rectangle_1" data-name="Rectangle 1" width="26" height="2" transform="translate(13 36.2)" fill={color}/>
    <rect id="Rectangle_2" data-name="Rectangle 2" width="2" height="2" transform="translate(49 49)" fill={color}/>
    <rect id="Rectangle_3" data-name="Rectangle 3" width="2" height="2" transform="translate(1 49)" fill={color}/>
  </g>
</svg>
  );
};
IconBuy.defaultProps = {
  color: '#fff',
};
export default IconBuy;