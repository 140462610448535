import React from 'react';
import './index.css';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import PicturesContainer from '../../components/PicturesContainer';
import Description from './Description';
import SellerCard from './SellerCard';
import './index.css';
import Tabs from './Tabs';
import OtherOffers from './otherOffers';
import { fetchProduct } from '../../api/product';
import OfferModel from '../../models/Offer';
import { fetchSellerOtherOffers } from '../../api/product';
import ErrorBoundary from '../Error';
import { set } from '../../store/Error/errorReducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getUserNote } from '../../api/favorite';
import { CircularProgress } from '@mui/material';

export default function () {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [ offerData, setOfferData ] = React.useState<Partial<OfferModel>>({ pictureUrls: [] });
  const [ otherOffersData, setOtherOffersData] = React.useState([]);
  const { id } = useParams();
  const { isAuthenticated } = useAppSelector(state => state.user);
  const account = useAppSelector(state => state.account);
  const [myRating, setMyRating] = React.useState(0);
  const [myComment, setMyComment] = React.useState('');
  const [ratingId, setRatingId] = React.useState(null);
  const getOffer = async (Id: any) => {
    try {
      setIsLoading(true);
      const res = await fetchProduct(Id, isAuthenticated);
      setIsLoading(false);
      setOfferData(res.data);
    } catch (err: any) {
      dispatch(set());
    }
  };
  React.useEffect(()=> {
    getOffer(id);
  }, [id]);
  React.useEffect(() => {
    const fetchRating = async () => {
      if (isAuthenticated && offerData && offerData._id && account && account._id) {
        const userNote = await getUserNote(account._id, offerData._id );
        if (userNote && userNote.data && userNote.data.length > 0 && userNote.data[0].note > 0) {
          setMyRating(userNote.data[0].note);
          setMyComment(userNote.data[0].comment);
          setRatingId(userNote.data[0]._id);
        }
      }
    };
    fetchRating();
  }, [isAuthenticated, offerData, account]);
  React.useEffect(() => {
    const getOtherOffers = async (sellerId: any) => {
      const res = await fetchSellerOtherOffers(sellerId, 1);
      if (res && res.data && res.data && offerData && offerData._id) {
        const data = res?.data?.filter( (e: any) => e._id !== offerData._id);
        setOtherOffersData(data);
      }
    };
    if (offerData && offerData.seller) {
      getOtherOffers(offerData.seller._id);
    }
  }, [offerData]);

  return <ErrorBoundary>
  { offerData && offerData._id && !isLoading && <div>
    <div>
  <Grid container  spacing={3} className='offer-container'>
        <Grid item xs={4}>
            <PicturesContainer pictures={offerData.pictureUrls} />
        </Grid>
        <Grid item xs={4}>
            <Description
            {...offerData}
            myRating={myRating}
            myComment={myComment}
            ratingId={ratingId}
            getOffer={() => getOffer(offerData._id)}
            />
        </Grid>
        <Grid item xs={4}>
          {offerData && offerData.seller &&
        <SellerCard account={account} {...offerData}/>
          }
        </Grid>
        </Grid>
        </div>
        <div>
        <Tabs {...offerData} />
          </div>
        <div>
          {otherOffersData && otherOffersData.length > 0 &&
        <OtherOffers otherOffersData={otherOffersData}></OtherOffers>
          }
          </div>
          <div style={{ height: '200px', width: '100%', 'backgroundColor': '#fff' }}>
          </div>
      </div>
    }
    {
      isLoading && <CircularProgress style={{
        height: '80px',
        width: '80px',
        margin: 'auto',
      }}></CircularProgress>
    }
      </ErrorBoundary>
  ;
}