import React from 'react';
import './index.css';
import MostVisiedLine from './MostVisitedLine';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAnalytics } from '../../api/analytics';

import { setViews, setClicks, setOffersData } from '../../store/Analytics/analyticsReducer';

const MostVisited = () => {
  const { t } = useTranslation();
  const { offersAnalytics } = useAppSelector(state => state.analytics);
  const dispatch = useAppDispatch();
  const fetchData = async () => {
    const data = await getAnalytics();
    if (data) {
      dispatch(setViews(data.views));
      dispatch(setClicks(data.clicks));
      dispatch(setOffersData(data.offersAnalytics));
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return <div className='messages-tablea'>
      <div style={{ textTransform: 'uppercase' }} className='messages-table-title'>
      {(offersAnalytics && offersAnalytics.length && offersAnalytics.length) || 0} {t('postedOffers')}
          </div>
          {offersAnalytics && offersAnalytics.length > 0 &&
      <div className='messages-table-head'>
          <div style={{ width: '30%', paddingTop:'8px', color:'#fff', fontSize: '16px', paddingLeft: '20px' }}>
              {t('FormTitle')}
            </div>
          <div style={{ width: '14%', paddingTop:'8px', color:'#fff', fontSize: '16px' }}>
              {t('LastUpdate')}
            </div>
          <div style={{ width: '7.5%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center' }}>
              {t('Views')}
            </div>
          <div style={{ width: '7.5%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('Clicks')}
            </div>
            <div style={{ width: '7.5%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
            <FontAwesomeIcon
                icon={faEnvelope}
                color='#fff'
                style={{ marginRight: '5px' }}
                />
              {t('received')}
            </div>
            <div style={{ width: '11%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('pending')}
            </div>
            <div style={{ width: '21%', paddingTop:'8px', color:'#fff', fontSize: '16px', textAlign: 'center'   }}>
              {t('Actions')}
            </div>


          </div>
          }
          {offersAnalytics.length > 0 && offersAnalytics.map((e: any, index: number) => <MostVisiedLine
          {...e}
          index={index}
          // offersData={offersData}
          // offersCount={offersCount}
          fetchData={fetchData}
          setOffersData={setOffersData}
          // setOffersCount={setOffersCount}
          />)}
    </div>;
};

export default MostVisited;