
const initialState = {
  filters: {
    categories: [],
    productCondition: [],
    sellerRating: -1,
    minQuantity: 0,
    minPrice: 0,
    maxPrice: 0,
    countries: [],
    searchBar: '',
    page: 1,
    limit: 12,
    sort: 'date',
  },
  totalOffers: 0,
  lastPage: 0,
  isLoading: false,
  results: [],
  didReadUrl: false,
};

export default initialState;