import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import queryString from 'query-string';
// Define a type for the slice state

// Define the initial state using that type
const generateQS = (filters: any) => {
  let realFilters = Object.entries(filters).reduce((acc: any, curr: any ) => {
    if (Array.isArray(curr[1])) {
      if (curr[1].length === 0) return acc;
      acc[curr[0]] = curr[1];
      return acc;
    } else if (!!curr[0] && curr[1] <= 0) return acc;
    acc[curr[0]] = curr[1];
    return acc;
  }, {});
  return queryString.stringify(realFilters, { arrayFormat: 'bracket' });
};
export const accountSlice = createSlice({
  name: 'search',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCategories: (state, payload: any) => {
      const filters = { ...state.filters, categories: payload.payload.values, page: 1 };
      const { differentPath } = payload.payload;
      let d = generateQS(filters);
      if (differentPath) {
        payload.payload.navigate({ 
          pathname: '/annonces',
          search: `?${d}` });
      } else {
        //payload.payload.navigate({ search: `?${d}` });
      }
      return {
        ...state,
        filters,
      };
    },
    setSort: (state, { payload }) => {
      const filters = { ...state.filters, sort: payload.value };
      let d = generateQS(filters);
      payload.navigate({ search: `?${d}` });

      return ({
        ...state,
        filters,
      });
    },
    setPage: (state, { payload }) => {
      const filters = { ...state.filters, page: payload.value };
      let d = generateQS(filters);
      console.log(d);
      //payload.navigate({ search: `?${d}` });

      return ({
        ...state,
        filters,
      });
    },
    setCountries: (state, payload: any) => {
      const filters = { ...state.filters, countries: payload.payload.values, page: 1 };
      let d = generateQS(filters);
      console.log(d);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    setSearchBar: (state, payload: any) => {
      const filters = { ...state.filters, searchBar: payload.payload.values, page: 1 };
      const { differentPath } = payload.payload;
      let d = generateQS(filters);
      if (differentPath) {
        payload.payload.navigate({ 
          pathname: '/annonces',
          search: `?${d}` });
      } else {
        payload.payload.navigate({ search: `?${d}` });
      }
      return {
        ...state,
        filters,
      };
    },
    setFilters: (state, payload: any) => {
      delete payload.payload.limit;
      const filters = { ...state.filters, ...payload.payload };
      return {
        ...state,
        filters,
      };
    },
    setSellerRating: (state, payload: any) => {
      const filters = { ...state.filters, sellerRating: payload.payload.values, page: 1 };
      let d = generateQS(filters);
      console.log(d);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    setProductCondition: (state, payload: any) => {
      const filters = { ...state.filters, productCondition: payload.payload.values, page: 1 };
      let d = generateQS(filters);
      console.log(d);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    setMinQuantity: (state, payload: any) => {
      const filters = { ...state.filters, minQuantity: payload.payload.values, page: 1 };
      let d = generateQS(filters);
      console.log(d);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    setMinPrice: (state, payload: any) => {
      const filters = { ...state.filters, minPrice: payload.payload.values, page: 1 };
      let d = generateQS(filters);
      console.log(d);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    setMaxPrice: (state, payload: any) => {
      const filters = { ...state.filters, maxPrice: payload.payload.values, page: 1 };
      let d = generateQS(filters);
      console.log(d);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    resetPrice: (state, payload: any) => {
      const minPrice = initialState.filters.minPrice;
      const maxPrice = initialState.filters.maxPrice;
      const filters = {
        ...state.filters,
        maxPrice,
        minPrice,
        page: 1,
      };
      let d = generateQS(filters);
      console.log(d);
      console.log(payload);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    resetMinQuantity: (state, payload: any) => {
      const minQuantity = initialState.filters.minQuantity;
      const filters = {
        ...state.filters,
        minQuantity,
        page: 1,
      };
      let d = generateQS(filters);
      console.log(d);
      console.log(payload);
      //payload.payload.navigate({ search: `?${d}` });
      return {
        ...state,
        filters,
      };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.totalOffers = initialState.totalOffers;
      state.lastPage = initialState.lastPage;
      state.isLoading = initialState.isLoading;
      state.results = initialState.results;
      state.didReadUrl = initialState.didReadUrl;
    },
    setOneCategory: (state, payload: any) => {
      state.totalOffers = initialState.totalOffers;
      state.lastPage = initialState.lastPage;
      state.isLoading = initialState.isLoading;
      state.results = initialState.results;
      let d = generateQS(state.filters);
      const filters = { ...initialState.filters, categories: payload.payload.values };
      payload.payload.navigate({
        pathname: '/annonces',
        search: `?${d}` });
      state.filters = filters;
    },
    setDidReadUrl: (state, { payload }) => ({ ...state, didReadUrl: payload }),
    setIsLoading: (state, { payload }) => ({ ...state, isLoading: payload }),
    setResults: (state, { payload }) => {
      let results: any = [];
      let totalOffers = 0;
      let lastPage = 0;
      if (payload && payload.offers && payload.topoffers) {
        results = [...payload.topoffers.data, ...payload.offers.data];
        totalOffers = payload.topoffers.total + payload.offers.total;
        lastPage = payload.offers.last_page;
      }
      return {
        ...state,
        results,
        totalOffers,
        lastPage,
      };
    },
  },
});

export const {
  setFilters,
  setResults,
  setPage,
  setCategories,
  setSearchBar,
  setCountries,
  setSellerRating,
  setProductCondition,
  setMinQuantity,
  setMinPrice,
  setMaxPrice,
  resetPrice,
  setDidReadUrl,
  resetMinQuantity,
  setIsLoading,
  setSort,
  resetFilters,
  setOneCategory,
} = accountSlice.actions;

export default accountSlice.reducer;
