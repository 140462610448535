import React from 'react';
import './index.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import flags from '../Home/ProductForm/Flags';
import { useNavigate } from 'react-router';

const SellerCard = (props: any) => {
  const { seller, account } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const CountriesTranslations: any = t('CountriesList', { returnObjects: true });
  const countryName = CountriesTranslations[seller.country];
  const countryFlag = flags.find(e => e.code === seller.country) ? flags.find(e => e.code === seller.country)?.flag : '';
  const dataLabels = [
    t('Street'),
    t('ZipCode'),
    t('City'),
    t('Country'),
    t('Responsable'),
    t('Languages'),
    t('PhoneNumber1'),
    t('PhoneNumber2'),
  ];
  const dataValues = [
    seller.streetNumber,
    seller.zipCode,
    seller.city,
    <div style={{ display: 'flex' }}>
      {countryName}
      <img height='20' width='20' style={{ marginLeft: '10px' }} src ={countryFlag}/>
    </div>,
    `${seller.firstName} ${seller.lastName}`,
    'French',
    seller.phone,
    seller.mobile,
  ];
  // const days = ['LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM', 'DIM'];
  // const [ selectedDay, setSelectedDay] = React.useState(0);
  return <div className='seller-card'>
      <div className='seller-data-title'>
          {seller.businessName}
          </div>
          <div className='seller-data-table'>
            <div className='section-seller-datas'>
            <table>
            {dataLabels.map((el: any, index: number) => {
              const value = dataValues[index];
              return <tr style={{ verticalAlign: 'top' }}>
                    <td className='d-label'>{el}</td>
                    <td className='d-value'>{value}</td>
                </tr>;
            })}
            </table>
            </div>
            </div>
            {/* <div className='hour'>
            Horaires d’ouverture
            </div>
            <div className='days-container'>
                {
                    days.map( (day: string, index: number) => (
                        <div
                        onClick={() => setSelectedDay(index)}
                        className={selectedDay === index ? 'day-cell-selected' : 'day-cell'}>{day}</div>
                    ))
                }
            </div>
            <div className='hour-helper'>
            Ouvert de 8:00 à 22:00
                </div> */}
            { account && account.role === 'visitor' && <div onClick={() => navigate('/subscription')} className='unlock-section'>
                <span style={{ position: 'relative', top: '8px', fontSize: '12px' }}>
           <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', marginRight:'8px'  }} color='#fff' icon={faAddressCard} />
            {t('UnlockContactDetails')}
            </span>
            </div>
            }
            { account && seller.storeName && (account.role === 'seller' || account.role === 'buyer') && <div
              className='unlock-section'
              onClick={() => navigate(`/store/${seller.storeName}`)}
              >
                <span style={{ position: 'relative', top: '8px', fontSize: '12px' }}>
           <FontAwesomeIcon style={{ position: 'relative', height: '16px', width: '16px', marginRight:'8px'  }} color='#fff' icon={faAddressCard} />
            {t('ViewSellerStore')}
            </span>
            </div>
            }
        </div>;
};

export default SellerCard;