import React from 'react';
import { useTranslation } from 'react-i18next';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { SvgIcon } from '@mui/material';

const Footer = () => {
  const { t } = useTranslation();
  return <footer
    style={{
      height: '230px',
      backgroundColor: '#15031D',
      padding: '30px 6.6% 0 6.6%',
      display: 'flex',
      color: '#fff',
      textAlign: 'start',
      marginTop: '20px',
    }}
    >
      <div style={{ width: '250px'  }}>
        <div style={{ fontSize: '18px', marginBottom: '30px' }}>
          IZI-PLACE
          </div>
          <div style={{ fontSize: '14px', fontStyle: 'italic' }}>
            {t('WhatWeOffer')}
          </div>
        </div>
        <div style={{ width: '200px' }}>
          </div>
      <div>
      <div>
      <div style={{ fontSize: '18px', marginBottom: '30px' }}>
          INFORMATIONS
          </div>
          <div
          onClick={() => window.location.assign('/subscription')}
          style={{ fontSize: '14px', marginBottom: '8px', cursor: 'pointer' }}>
            {t('Subscription')}
          </div>
          <div
          onClick={() => window.location.assign('/privacy-policy')}
          style={{ fontSize: '14px', marginBottom: '8px', cursor: 'pointer' }}>
            {t('privacyPolicy')}
          </div>
          <div
          onClick={() => window.location.assign('/legal-notice')}
          style={{ fontSize: '14px', marginBottom: '8px', cursor: 'pointer' }}>
            {t('legalNotice')}
          </div>
          <div
          onClick={() => window.location.assign('/terms-conditions')}
          style={{ fontSize: '14px', cursor: 'pointer' }}>
            {t('termsAndConditions')}
          </div>
        </div>
       </div>
       <div style={{ width: '200px' }}>
          </div>
        <div>
       <div style={{ fontSize: '18px', marginBottom: '30px' }}>
          CONTACT
          </div>
          <div
          style={{ fontSize: '14px', cursor: 'pointer', display: 'flex', marginBottom: '5px' }}>
            <SvgIcon fontSize="small">
                <PhoneIcon />
            </SvgIcon>
               : 09 84 38 32 97
          </div>
          <div
          style={{ fontSize: '14px', cursor: 'pointer', display: 'flex', marginBottom: '5px' }}>
            <SvgIcon fontSize="small">
                <AlternateEmailIcon />
            </SvgIcon>
             : <a href='mailto:contact@izi-place.com' style={{ fontSize: '14px', color: 'white', paddingLeft: '5px' }}>contact@izi-place.com</a>
          </div>
          <div
          style={{ fontSize: '14px', cursor: 'pointer', display: 'flex', marginBottom: '5px' }}>
            <SvgIcon fontSize="small">
                <LinkedInIcon />
            </SvgIcon>
             : <a href='https://www.linkedin.com/in/izi-place-0a1179250/' target={'_blank'} style={{ fontSize: '14px', color: 'white', paddingLeft: '5px' }}>Linkedin</a>
          </div>
          <div
          style={{ fontSize: '14px', cursor: 'pointer', display: 'flex' }}>
            <SvgIcon fontSize="small">
                <FacebookIcon />
            </SvgIcon>
             : <a href='https://www.facebook.com/profile.php?id=100087449037800' target={'_blank'} style={{ fontSize: '14px', color: 'white', paddingLeft: '5px' }}>Facebook</a>
          </div>
        </div>
        </footer>;
};

export default Footer;